import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import config from 'config';
import Section from 'components/Section';
import { Paper } from '@material-ui/core';

import './Dictionary.css';
import { useSelector } from 'react-redux';
import { getAllTerms } from 'utils/dictionary';

/* COMPONENT FOR Dictionary / sanakirja */

const Dictionary = ({ className, fields, idx }) => {
  // scroll to top when entering page view
  useEffect(() => window.scrollTo(0, 0), []);

  const lang = useSelector((store) => store.lang)

  const terms = getAllTerms(lang);

console.log(terms);
  return (
    <>
      <Helmet>
        <title>Lainvalmistelun sanakirja | {config.site.name}</title>
      </Helmet>
      <Section className="section--page">
        <Paper elevation={0}>
          <div className="page-wrapper">
            <h1 className="page-title">
                Lainvalmistelun sanakirja
            </h1>
            {terms.map( (term) => 
                <>
                    <h3 id={term[lang]['term']}>{term[lang]['term']}</h3>
                    <p>{term[lang]['explanation']}</p>
                    <p>
                        Lähde: {' '}
                            {term[lang]['source_text']}{' '}
                            {term[lang]['source_url'] &&
                                <a href={term[lang]['source_url']} target="_blank" rel="noopener noreferrer">
                                {term[lang]['source_url']}
                                </a>
                            }
                    </p>
                </>
            )
            }
          </div>
        </Paper>
      </Section>
    </>
  );
};

export default Dictionary;