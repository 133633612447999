import apiService from '../services/apiService';
import { SET_FACET_CONSTANTS } from './actionTypes';
import config from 'config';

export const getFacetConstants = (lang="fi") => async (dispatch) => {
  // initialize facet constants with enpty objects
  let constants = Object.keys(config.docGroups).reduce((prev, cur) => {
    prev[cur] = {}
    return prev
  }, {})

  try {
    constants = await apiService.fetchData('facets?lang=' + lang);
  } catch (error) {
    console.log(error)
  }
  dispatch({
    type: SET_FACET_CONSTANTS,
    payload: constants,
  });
};
