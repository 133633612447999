// Dictionary of human-readable counter parts for certain terms
import React from 'react';


const translations = {
  // UI: header
  site_name: {
    fi: 'Lakitutka',
    se: 'Lakitutka'
  },
  slogan: {
    fi: 'lainvalmistelu läpivalaistuna',
    se: 'genomsynlig lagberedning'
  },
  haku: {
    fi: 'Haku',
    se: 'Sök',
  },
  tietoja: {
    fi: 'Tietoja Lakitutkasta',
    se: 'Om Lakitutka',
  },
  ohjeet: {
    fi: 'Ohjeet',
    se: 'Instruktioner',
  },
  tutkijalle: {
    fi: 'Tutkijalle',
    se: 'För forskare',
  },
  intro1: {
    fi: 'Lakitutka kokoaa yhteen lainvalmistelun eri vaiheisiin liittyvät julkiset asiakirjat. Voit tutkia lakihankkeiden etenemistä hallituksen esitysten, annettujen lausuntojen, mietintöjen ja eduskuntakeskusteluiden kautta. Pääset tarkastelemaan yksittäisen hankkeen elinkaarta avaamalla minkä tahansa siihen liittyvän dokumentin.',
    se: 'Lakitutka samlar ihop offentliga dokument från lagberedningsprocessens varje skede. Du kan undersöka laginitiativens utveckling genom regeringspropositioner, givna utlåtanden, betänkanden och riksdagsdiskussioner. Du kan även följa med en enskild motions livscykel genom att öppna vilket som helst dokument i anslutning till ärendet.',
  },
  video_instruction: {
    fi: 'Ohjeet ja videoesimerkit Lakitutkan käyttöön',
    se: 'Instruktioner och videoexempel för användingen av Lakitutka'
  },
  intro2: {
    fi: 'Lakitutka on Turun yliopistossa kehitetty avoin palvelu. Lakitutkaa kehitetään jatkuvasti käytettävyyden ja materiaalin osalta.',
    se: 'Lakitutka är en öppen tjänst utvecklad av Åbo Universitet, som också fortsättningsvis utvecklas dynamiskt med avseende på användarvänlighet och genom tjänsten tillgängligt material.',
  },
  new_lakitutka_1: {
    fi: "Lakitutka uudistuu vuoden 2024 aikana",
    se: "Lakitutka uudistuu vuoden 2024 aikana"
  },
  new_lakitutka_2: {
    fi: "Nykyisen tutkijanäkymän vaihtoehdoksi tulee käyttäjää opastava uusi näkymä.",
    se: "Nykyisen tutkijanäkymän vaihtoehdoksi tulee käyttäjää opastava uusi näkymä.",
  },
  new_lakitutka_3: {
    fi: "Tutustu uusiin ominaisuuksiin jo nyt",
    se: "Tutustu uusiin ominaisuuksiin jo nyt:"
  },
  new_lakitutka_4: {
    fi: "blogistamme",
    se: "blogistamme"
  },
  palaute: {
    fi: 'Otamme mielellämme vastaan palautetta.',
    se: 'Vi tar gärna emot respons.'
  },
  mobile_document_group: {
    fi: 'Haettava asiakirjaryhmä',
    se: 'Kategori av dokument som sökningen avser'
  },
  missing_title : {
   fi: "- ei otsikkoa -",
   se: "- ingen rubrik -"
  },
  home: {
    fi: 'Etusivu',
    se: 'Framsida',
  },
  viittaaminen: {
    fi: "Viittaaminen",
    se: "Referens",
  },
  viittausohjeet: {
    fi: "Viittausohjeet",
    se: "Referensanvisningar",
  },
  yleinen: {
    fi: "YLEINEN",
    se: "ALLMÄN"
  },
  tutkija: {
    fi: "TUTKIJA",
    se: "FORSKARE"
  },
  hakutila: {
    fi: "Hakutila: ",
    se: "Sökläge: ",
  },

  // UI: general 
  hanke: {
    fi: "Hanke",
    se: "Projekt"
  },
  liittyy: {
    fi: "Liittyy",
    se: "Har samband med",
  },
  first_signee: {
    fi: "Ensimmäinen allekirjoittaja",
    se: "Första undertecknare",
  },
  valmisteltu: {
    fi: "Valmisteltu",
    se: "Beredd i ",
  },
  vastuussa: {
    fi: "Valmisteluvastuussa",
    se: "Beredningsansvarig",
  },
  edustajan: {
    fi: "Kansanedustajan",
    se: "Riksdagsledamotens",
  },
  taysistunto: {
    fi: "Eduskunnan täysistunto",
    se: "Riksdagens plenum",
  },
  Hakutulokset: {
    fi: "Hakutulokset",
    se: "Sökresultat",
  },
  Näytetään_tulokset_haulla:{
    fi: "Näytetään tulokset haulla: ",
    se: "Visar resultat med sökning: ",
  },
  Rajaa_hakua:{
    fi: "Rajaa hakua",
    se: "Begränsa sökning",
  },
  Lakitutka_intro_for_everyone: {
    fi: "Lakitutka kokoaa yhteen lainvalmistelun eri vaiheisiin liittyvät julkiset asiakirjat",
    se: "Lakitutka samlar ihop offentliga dokument från lagberedningsprocessens varje skede"
  },
  pikalinkit: {
    fi: "Pikalinkit",
    se: "Snabblänkar"
  },
  käynnissä_olevat_hankkeet:{
    fi: "Käynnissä olevat hankkeet",
    se: "Pågående projekten"
  },
  uusimmat: {
    fi: "Uusimmat hankkeet",
    se: "Nyaste" 
  },
  eduskunnassa: {
    fi: "Eduskunnassa",
    se: "På Riksdagen" 
  },
  osallistu: {
    fi: "Lausuntokierroksella",
    se: "Medverka"
  },
  oikopolut_hakuun:{
    fi: "Oikopolut hakuun",
    se: "Genvägar"
  },
  uusimmat_he:{
    fi: "Uusimmat hallituksen esitykset",
    se: "Nyaste regeringsproposition"
  },
  talousarviot:{
    fi: "Talousarviot",
    se: "Budgeterna"
  },
  eniten_eduskunnassa_keskustellut:{
    fi: "Eniten eduskunnassa keskustellut",
    se: "Mest diskuterade i Riksdagen"
  },
  opi_miten_lait_syntyvät: {
    fi: "Opi miten lait syntyvät",
    se: "Lära dig hur lag stiftas"
  },
  video_näin_lait_synytvät: {
    fi: "Video: Näin lait syntyvät",
    se: "Videon: Så här stiftar man en lag",
  },
  video_kuuleminen_lainvalmistelussa: {
    fi: "Video: Kuuleminen lainvalmistelussa",
    se: "Videon: hörande i lagberedningsprocessen",
  },
  lainvalmistelun_sanakirja:{
    fi: "Lainvalmistelun sanakirja",
    se: "Lagberednings ordbok",
  },
  hae_lakitutkasta: {
    fi: "Hae Lakitutkasta",
    se: "Sök från Lakitutka"
  },
  texts_tooltip_basic: {
    fi: "Perushaku: Hae hakusanalla. Katkaisumerkki on *, ja se toimii sanan alussa ja lopussa. Voit kirjoittaa hakukenttään useita hakusanoja AND, OR ja NOT - toimintoja käyttäen. Jos haet hallituksen esityksen numerolla, kirjoita se kenttään muodossa “HE 100/2017”.",
    se: "Grundsökning: Sök med sökord. Brytningstecknet är * och det fungerar i början och slutet av ordet. Du kan skriva flera sökord i sökfältet med hjälp av AND, OR och NOT kommandona. Om du söker med regeringspropositionens nummer, skriv det i fältet som 'RP 100/2017'. "
  },
  tarkastele_aihealueittain: {
    fi: "Tarkastele aihealueittain",
    se: "Sök enlig teman",
  },
  valitse_hakutapa:{
    fi: "Valitse hakutapa",
    se: "Välj sökmetod ",
  },
  "Hallituksen esitykset": {
    fi: "Hallituksen esitykset",
    se: "Regeringspropositioner",
  },
  "Valmisteluasiakirjat": {
    fi: "Valmisteluasiakirjat",
    se: "Beredningsdokument",
  } ,
  "Lakialoitteet": {
    fi: "Lakialoitteet",
    se: "Lagmotioner",
  },
  "Valiokunta-asiakirjat": { 
    fi: "Valiokunta-asiakirjat",
    se: "Utskottsdokument",
  },

  "Eduskuntakeskustelut":{
    fi: "Eduskuntakeskustelu",
    se: "Riksdagsdebatter",
  },
  "Kaikki asiakirjat": { 
    fi: "Kaikki asiakirjat",
    se: "Alla dokument",
  },
  "asiakirjatyyppi_tooltip": {
    fi: "Voit kohdentaa haun kaikkiin asiakirjoihin, tai vain valitsemaasi asiakirjatyyppiin. Esimerkiksi valinta 'Lakialoitteet' rajaa tekemäsi haun kohdistumaan vain kansanedustajien tekemiin lakialoitteisiin.",
    se: "Voit kohdentaa haun kaikkiin asiakirjoihin, tai vain valitsemaasi asiakirjatyyppiin. Esimerkiksi valinta 'Lakialoitteet' rajaa tekemäsi haun kohdistumaan vain kansanedustajien tekemiin lakialoitteisiin.",
  },
  "tooltip_prefilter": {
    fi:"Esirajaus-toiminnolla voit jo alkuvaiheessa rajata hakutuloksia. Käyttäessäsi esirajaus-toimintoa hakutuloksina ovat vain ne hakusanaosumat, jotka täyttävät myös esirajauksen kriteerit.  ",
    se: "Med förhandsbegränsingsfunktionen kan du begränsa sökresultaten redan från början. När du använder förhandsbegränsingsfunktionen kommer endast de sökord som också uppfyller kriterierna för färhandsbegränsing att inkluderas i sökresultaten. "
  },
  "valitse": {
    fi: "Valitse ",
    se: "Välj "
  },
  "valitse_vuosi": {
    fi: "Valitse vuosi",
    se: "Välj ett år",
  },
  "Suuri valiokunta":{
    fi: "Suuri valiokunta",
    se: "stora utskottet",
  },
  "Suurivaliokunta":{
    fi: "Suuri valiokunta",
    se: "stora utskottet",
  },
  "Perustuslakivaliokunta": {
    fi: "Perustuslakivaliokunta",
    se: "grundlagsutskottet",
  },
  "Ulkoasiainvaliokunta":{
    fi: "Perustuslakivaliokunta",
    se: "Utrikesutskottet",
  },
  "Valtiovarainvaliokunta":{
    fi: "Valtiovarainvaliokunta",
    se: "finansutskottet",
  },
  "Tarkastusvaliokunta": {
    fi: "Tarkastusvaliokunta",
    se:"Revisionsutskottet",
  },
  "Hallintovaliokunta" :{
    fi: "Hallintovaliokunta",
    se: "Förvaltningsutskottet",
  },
  "Lakivaliokunta":{
    fi: "Lakivaliokunta",
    se: "Agutskottet",
  },
  "Liikenne- ja viestintävaliokunta": {
    fi: "Liikenne- ja viestintävaliokunta",
    se: "Kommunikationsutskottet",
  },
  "Maa- ja metsätalousvaliokunta":{
    fi: "Maa- ja metsätalousvaliokunta",
    se: "Jord- och skogsbruksutskottet",
  },
  "Maa- ja metsätalousministeriö": {
    fi: "Maa- ja metsätalousministeriö",
    se: "Jord- och skogsbruksministeriet",
  },
  "Puolustusvaliokunta":{
    fi: "Puolustusvaliokunta",
    se: "Försvarsutskottet",
  },
  "Sivistysvaliokunta":{
    fi: "Sivistysvaliokunta",
    se: "Kulturutskottet",
  },
  "Sivitysvaliokunta":{
    fi: "Sivistysvaliokunta",
    se: "Kulturutskottet",
  },
  "Sosiaali- ja terveysvaliokunta":{
    fi: "Sosiaali- ja terveysvaliokunta",
    se: "Social- och hälsovårdsutskottet", 
  },
  "Talousvaliokunta": {
    fi: "Talousvaliokunta",
    se: "Ekonomiutskottet",
  },
  "Tiedusteluvalvontavaliokunta":{
    fi: "Tiedusteluvalvontavaliokunta",
    se: "Underrättelsetillsynsutskottet",
  },
  "Tulevaisuusvaliokunta": {
    fi: "Tulevaisuusvaliokunta",
    se: "Framtidsutskottet",
  }, 
  "Työelämä- ja tasa-arvovaliokunta":{
    fi: "Työelämä- ja tasa-arvovaliokunta",
    se: "Arbets- och jämställdhetsutskottet", 
  },
  "Ympäristövaliokunta":{
    fi: "Ympäristövaliokunta",
    se: "Miljöutskottet",
  },
  "Asiantuntijalausunto":{
    fi: "Asiantuntijalausunto",
    se: "Expertutlåtande",
  }, 
  "Valiokunnan mietintö": {
    fi: "Valiokunnan mietintö",
    se: "Utskotts betänkande",
  }, 
  "Valiokunnan lausunto": {
    fi: "Valiokunnan lausunto",
    se: "Utskotts yttrande",
  }, 
  "Tyhjennä valinnat": {
    fi: "Tyhjennä valinnat",
    se: "Radera alla",
  },
  asiakirja:{
    fi: "Asiakirja",
    se: "Dokumentera",
  },

  // UI: prefilters
  prefilter: {
    name: {
      fi: "Käytä esirajausta",
      se: "Filtrera"
    },
    button: {
      fi: "Muokkaa esirajausta",
      se: "Muokkaa esirajausta"
    },
    tooltip: {
      fi: 'Esirajaus-toiminnolla voit jo alkuvaiheessa rajata hakutuloksia. Käyttäessäsi esirajaus-toimintoa hakutuloksina ovat vain ne hakusanaosumat, jotka täyttävät myös esirajauksen kriteerit. ',
      se: 'Med förhandsbegränsingsfunktionen kan du begränsa sökresultaten redan från början. När du använder förhandsbegränsingsfunktionen kommer endast de sökord som också uppfyller kriterierna för färhandsbegränsing att inkluderas i sökresultaten.',
    },
    remove: {
      fi: "Tyhjennä esirajaus",
      se: "Radera förhandsbegränsning(ar)",
    },
    search_term: {
      fi: "Esirajauksen hakutermi",
      se: "Förhandsbegränsingens sökord",
    },
    diaarinumerot: {
      fi: "Diaarinumerot",
      se: "Bildnummer",
    },
    from_year: {
      fi: "Vuodesta",
      se: "Från år"
    },
    to_year: {
      fi: "Vuoteen",
      se: "Till år"
    },
    not_selected: {
      fi: "- ei valittu -",
      se: "- inte vald -"
    },
    no_filters: {
      fi: "Ei rajausta",
      se: "Ingen begränsning"
    },

    talousarviot: {
      name: {
        fi: "Talousarviot",
        se: "Budgeterna"
      },
      tooltip: {
        fi: 'Valtion eli talousarvio on vuosittainen valtion taloutta ja varainhoitoa koskeva suunnitelma, jossa päätetään budjettitalouden meno- ja tuloarvioista.',
        se: 'Statsbudgeten är den årliga planen för statens ekonomi och finansförvaltning, där man fattar beslut om budgetens utgifts- och inkomstberäkningar.',
      },
      without: {
        fi: "Ilman talousarvioita",
        se: "Utan budget"
      },
      strictly: {
        fi: "Pelkät talousarviot",
        se: "Enbart budget"
      }
    },
    kirjelmat: {
      name: {
        fi: "Edusunnan kirjelmä",
        se: "Riksdagens skrivelse"
      },
      tooltip: {
        fi: 'Eduskunnan kirjelmä on asiakirja, jolla eduskunta ilmoittaa hallitukselle tai valtioneuvostolle päätöksensä tai kannanottonsa muussa kuin hallituksen lakiesitystä koskevassa asiassa. Eduskunnan kirjelmällä ilmoitetaan päätöksestä tai kannanotosta, joka koskee esim. valtion talousarvioita, pääministerin valintaa tai valtiopäivien keskeyttämistä/päättämistä.',
        se: 'Riksdagens skrivelse är ett dokument där riksdagen informerar regeringen eller statsrådet om sitt beslut eller sin ställning i ett annat ärende än en regeringsproposition. Riksdagens skrivelse används för att meddela riksdagen om beslut eller ställningstagande i t.ex. frågor om statsbudgeten, val av statsminister eller riksdagens upphörande/ upplösning.',
      },
      without: {
        fi: "Ilman eduskunnan kirjelmiä",
        se: "Utan riksdagens skrivelser"
      },
      strictly: {
        fi: "Pelkät eduskunnan kirjelmät",
        se: "Enbart riksdagens skrivelser"
      }
    },
    asetukset: {
      name: {
        fi: "Asetukset",
        se: "Förordningar",
      },
      tooltip: {
        fi: 'Valtioneuvoston asetukset sekä tärkeimmät ministeriöiden antamat asetukset',
        se: 'Statsrådets förordningar och förordningar givna av ministerier',
      },
      without: {
        fi: "Ilman asetuksia",
        se: "Utan inställningar"
      },
      strictly: {
        fi: "Pelkät asetukset",
        se: "Endast inställningar"
      }
    }
  },

  // UI: Pagination
  pagination: {
    first: {
      fi: "ensimmäinen",
      se: "första",
    },
    next: {
      fi: "seuraava",
      se: "nästa",
    },
    previous: {
      fi: "edellinen",
      se: "föregånde",
    },
    last: {
      fi: "viimeinen",
      se: "sista",
    },
  },

  // UI: search form
  search: {
    all: {
      fi: "Hae kaikki",
      se: "Sök alla",
    },
    basic: {
      name: {
        fi: "Perushaku",
        se: "Enkel sökning",
      },
      tooltip: {
        fi: 'Perushaku: Hae hakusanalla. Katkaisumerkki on *, ja se toimii sanan alussa ja lopussa. Voit kirjoittaa hakukenttään useita hakusanoja AND, OR ja NOT - toimintoja käyttäen. Jos haet hallituksen esityksen numerolla, kirjoita se kenttään muodossa “HE 100/2017”.',
        se: 'Bassökning: Sök med sökord. Använd * för att kapa ett ord, antingen i början eller slutet. Du kan även skriva in flera sökord i sökfältet med användning av funktionerna AND, OR och NOT. Om du söker en regeringsproposition enligt dess nummer, skriv in det i sökfältet i formatet "RP 100/2017".',
      },
    },
    empty: {
      fi: "Tyhjennä",
      se: "Tömma",
    },
    pieces: {
      fi: "kpl",
      se: "st",
    },
    proximity: {
      name: {
        fi: "Proksimiteettihaku",
        se: "Proximitetssökning",
      },
      tooltip: {
        fi: 'Proksimiteettihaku: Jos haluat hakea kahdella yhdessä esiintyvällä hakusanalla, voit tehdä sen proksimiteettihaulla. Määritä hakusanat ja sanojen suurin mahdollinen etäisyys toisistaan. Muistathan katkaisumerkit *.',
        se: 'Proximitetssökning: Om du vill göra en sökning med två ord som förekommer tillsammans, kan du göra det genom en proximtetssökning. Definiera sökorden och det största möjliga mellanrummet mellan dem. Kom ihåg * för att kapa orden.',
      },
      distance: {
        fi: "Max sanaväli",
        se: "Max ordavstånd",
      },
      first_term: {
        fi: "1. termi",
        se: "1. term",
      },
      second_term: {
        fi: "2. termi",
        se: "2. term",
      },
      words: {
        fi: "sanaa",
        se: "ord"
      }
    },
    semantic: {
      name: {
        fi: "Semanttinen",
        se: "Semantisk",
      },
      tooltip: {
        fi: "Semanttinen haku",
        se: "Semantisk sökning",
      },
    },
    prefilter: {
      name: {
        fi: "Esirajaa haku",
        se: "Filtrera",
      },
      tooltip: {
        fi: 'Esirajaus-toiminnolla voit jo alkuvaiheessa rajata hakutuloksia. Käyttäessäsi esirajaus-toimintoa hakutuloksina ovat vain ne hakusanaosumat, jotka täyttävät myös esirajauksen kriteerit. ',
        se: 'Esirajaus-toiminnolla voit jo alkuvaiheessa rajata hakutuloksia. Käyttäessäsi esirajaus-toimintoa hakutuloksina ovat vain ne hakusanaosumat, jotka täyttävät myös esirajauksen kriteerit. ', // MISSING
      },
    },
  },
  // UI: document listing
  results: {
    term: {
      fi: "Hakutermi",
      se: "Sökord",
    },
    hits: {
      fi: "Hakutulokset",
      se: "Sökresultat", 
    },
    hit: {
      fi: "Hakuosuma",
      se: "Sökresultat",
    },
    asiasanat: {
      fi: "Asiasanat",
      se: "Ämnesord",
    },
    asiasanat_sv: {
      fi: "Asiasanat",
      se: "Ämnesord",
    },
    prefilters: {
      fi: "ESIRAJAUS",
      se: "FÖRHANDSBEGRÄNSNING",
    },
    download: {
      fi: "Lataa",
      se: "Ladda ner",
    },
    show_results: {
      fi: "Näytä hakutulokset",
      se: "Visa sökresultat",
    },
    show_visual: {
      fi: "Näytä visualisointi",
      se: "Visa visualisering",
    },
    remove_active_facets: {
      fi: "Poista kaikki",
      se: "Radera alla"
    },
    order: {
      fi: "Järjestys",
      se: "Ordning"
    },
    on_page: {
      fi: "Tuloksia sivulla",
      se: "Resultat på sidan"
    },
  },
  // UI: document view
  document: {
    search_hits: {
      fi: "Hakusanaosumat",
      se: "Sökordsresultat",
    },
    toc: {
      fi: "Sisällysluettelo",
      se: "Innehållsförteckning",
    },
    original: {
      fi: "Alkuperäinen dokumentti",
      se: "Originaldokument",
    },
    no_original: {
      fi: "alkuperäinen dokumentti ei saatavilla",
      se: "originaldokument ej tillgängligt",
    },
    no_content: {
      fi: "Sisältö ei saatavilla",
      se: "Innehållet ej tillgängligt",
    },
    state: {
      fi: "Tila",
      se: "Status",
    },
    started: {
      fi: "Hanke aloitettu",
      se: "Projektet påbörjat",
    },
    he_laadittu: {
      fi: "Hallituksen esitys laadittu",
      se: "Regeringspropositionen sammanställts",
    },
    la_laadittu: {
      fi: "Lakialoite laadittu",
      se: "Lagmotion sammanställd",
    },
    sent: {
      fi: "Lähetetty eduskuntaan",
      se: "Skickad till riksdagen",
    },
    valmistunut: {
      fi: "Valmistunut",
      se: "Slutförd",
    },
    duration: {
      fi: "Hanke kestänyt",
      se: "Projektet varat",
    },
    changes: {
      fi: "Lakimuutoksia",
      se: "Ändring av lagen",
    },
    allekirjoittaja: {
      fi: "Allekirjoittaja",
      se: "Undertecknare",
    },
    allekirjoittajat: {
      fi: "Muiden allekirjoittajien lkm",
      se: "Antalet andra undertecknare",
    },
    kanalaisaloite_url: {
      fi: "Kansalaisaloite.fi -palvelussa",
      se: "I kansalaisaloite.fi-webbtjänsten för medborgarinitativ",
    },
    feedback: {
      fi: "Onko dokumenttien sisällössä tai tiedoissa puutteita tai virheitä? Ilmoitathan niistä meille",
      se: "Finns det brister eller fel i dokumentens innehåll eller information? Anmäl dessa till oss",
    },
    feedback_link: {
      fi: "tästä",
      se: "härifrån"
    },
    tyyppi: {
      fi: "Tyyppi",
      se: "Typ",
    },
    laatija: {
      fi: "Laatija",
      se: "Beredare"
    }
  },
  // UI: download
  download: {
    title: {
      fi: "Lataa hakutuloksista",
      se: "Ladda ner ur sökresultaten",
    },
    formats: {
      fi: "Tiedostomuodot",
      se: "Filtyper",
    },
    format: {
      fi: "Tiedostomuoto",
      se: "Filtyp",
    },
    file_formats: {
      orig: {
        label: {
          fi: "Alkuperäiset tiedostot",
          se: "Originalfiler",
        },
        info: {
          fi: "Voivat olla esim. pdf-, doc- tai tiff-tiedostoja",
          se: "Kan t.ex. vara pdf-, doc- eller tiff-filer",
        },
      },
      text: {
        label: {
          fi: "Pelkkä teksti",
          se: "Enbart text",
        },
        info: {
          fi: "Osa tekstisisällöistä on koneluettua ja voi sisältää virheitä",
          se: "En del av textinnehållet är maskinläst och kan innehålla fel",
        },
      },
      xml: {
        label: {
          fi: "XML",
          se: "XML",
        },
        info: {
          fi: "Vain osa asiakirjoista on saatavissa XML-muodossa",
          se: "Bara en del av dokumenten är tillgängliga i XML-form",
        },
      },
    },
    tabs: {
      meta: {
        fi: "Metatiedot",
        se: "Metadata",
      },
      content: {
        fi: "Asiakirjojen sisältö",
        se: "Dokumentens innehåll",
      },
    },
    meta: {
      fi: "Lataa metatiedot",
      se: "Ladda ner metadatan",
    },
    content: {
      fi: "Lataa sisällöt",
      se: "Ladda ner innehållen",
    },
    prepare: {
      fi: "Valmistele lataaminen",
      se: "Förebered nerladdning",
    },
    gathering: {
      fi: "Koostetaan tiedostoja...",
      se: "Dokumentet sammaställs...",
    },
  },
  // UI: timeline
  timeline: {
    tooltip: {
      lausuntokierros: {
        fi: 'Valmistelevan ministeriön ilmoittama lakihankkeen aloitusajankohta. Tämä voi olla esimerkiksi työryhmän toimikauden tai virkatyövalmistelun aloituspäivämäärä.',
        se: 'Lagmotionens startdatum enligt det förberedande ministeriets meddelande. Detta kan t.ex. vara startdatumet för en arbetsgrupps mandatperiod eller startdatumet för beredningen av ett förvaltningsärende.',
      },
      valiokunta_asiakirjat: {
        fi: 'Valiokuntien lausunnot ja mietinnöt. Valiokunnan mietintö syntyy valiokunnan käsittelyn pohjalta, ja sen perusteella eduskunnan täysistunto päättää asiasta. Valiokunta voi pyytää valiokuntakäsittelyn vaiheessa lausuntoa toiselta valiokunnalta.',
        se: 'Utskottens yttranden och betänkanden. Ett utskottsbetänkande är resultatet av utskottets förhandlingar och riksdagens fattar beslut på basis av detta i plenum. Ett utskott kan begära ett yttrande från ett annat utskott i samband med utskottsbehandlingen.',
      },
      laws: {
        fi: 'Lakipäätös -kohdassa näet hankkeen ratkaisun. Siitä ilmenee, onko hallituksen tekemä lakiehdotus eduskunnassa esimerkiksi hyväksytty sellaisenaan, vai onko sitä muutettu ennen hyväksymistä. Esitys on voitu myös hylätä kokonaan tai osittain.',
        se: 'Under "lagbeslut" ser du projektets avgörande. Där framgår det om regeringens lagmotion t.ex. har godkänts som sådan, eller om det gjorts ändringar till den före dess godkännande. Propositionen kan också ha förkastats helt eller delvis.'
      },
      hallituksen_esitykset: {
        fi: 'Hallituksen esityksellä lainsäädäntöasia laitetaan vireille eduskunnassa. Hallituksen esitykset valmistellaan ministeriöissä, ja suurin osa lainsäädäntöasioista eduskunnassa on lähtöisin hallituksen esityksestä.',
        se: 'Regeringspropositionen används för att presentera lagstiftningen för riksdagen. Regeringspropositioner bereds av ministerierna och de flesta lagstiftningsärenden i riksdagen har sitt ursprung i en regeringsproposition.',
      },
      lausuntokierros_lausunnot: {
        fi: 'Hankkeen valmistelun jälkeen luonnoksesta hallituksen esitykseksi pyydetään kirjallisia lausuntoja valmistelevan ministeriön ulkopuolisilta tahoilta. Näitä voivat olla esimerkiksi muut ministeriöt, yhdistykset, järjestöt, viranomaiset ja kansalaiset.',
        se: 'När projektet har förberetts kommer skriftliga kommentarer om utkastet till regeringspropositionen att begäras från utomstående parter utanför det förberedande ministeriet. Dessa kan omfatta andra ministerier, föreningar, organisationer, offentliga myndigheter och medborgare.',
      },
      lausuntokierros_muut: {
        fi: 'Muut lausuntokierrokseen liittyvät asiakirjat voivat olla tyypiltään esimerkiksi lausuntopyyntöjä, kirjeitä, luonnoksia, liitteitä tai yhteenvetoja. Kirjauskäytännöt vaihtelevat, joten tyypit ovat vain suuntaa-antavia',
        se: 'Andra handlingar som berör samrådsprocessen kan vara begäran om yttrande, brev, utkast, bilagor eller sammanfattningar. Registreringsmetoderna varierar, så typerna är endast vägledande.',
      },
      he_asiantuntijalausunnot: {
        fi: 'Valiokunnissa kuultujen asiantuntijoiden lausunnot. Asiantuntijalausunnot annetaan valiokunnalle valiokunnan pyynnöstä, kun taas lausuntokierroksen lausunnot aikaisemmassa vaiheessa ministeriölle.',
        se: 'Yttranden från experter som konsulteras i utskotten. Expertutlåtanden lämnas till utskottet på utskottets begäran, medan yttranden från samrådsfasen lämnas till ministeriet i ett tidigare skede.',
      },
      hankkeen_asettaminen: {
        fi: 'Hankkeen aloitus Hankeikkunan tietojen mukaan.',
        se: 'Start av projektet enligt informationen i projektfönstret.',
      },
      eduskunnan_vastaukset: {
        fi: 'Eduskunta ilmoittaa valtioneuvostolle päätöksensä käsiteltävänä olevasta lainsäädäntöasiasta eduskunnan vastauksella.',
        se: 'Riksdagen meddelar statsrådet om sitt beslut i det aktuella lagstiftningsärendet genom ett riksdagens svar.',
      },
      poytakirja: {
        fi: 'Eduskuntakeskusteluihin on taltioitu kirjallisessa muodossa hallituksen esityksistä käydyt eduskunnan täysistunnon keskustelut.',
        se: 'Riksdagsdebatterna om regeringenspropositioner registreras i skriftlig form.',
      },
      piilotetut: {
        fi: 'Aikajanalla näkyvät vain ne asiakirjat, joille on saatavilla päivämäärä rajapintojen kautta. Päivämäärättömät asiakirjat löytyvät kuitenkin listanäkymästä, joka aukeaa oikean yläkulman NÄYTÄ LISTOINA -painikkeesta.',
        se: 'Endast dokument för vilka ett datum är tillgängliga via gränssnitten visas på tidsaxeln. Odaterade dokument kan dock hittas i listvyn, som öppnas i det övre högra hörnet av knappen VISA SOM LISTOR.',
      },
      footer: {
        fi: 'KLIKKAUS: näytä/piilota tämä ryhmä TUPLAKLIKKAUS: näytä/piilota kaikki muut',
        se: 'KLICKA: visa/ dölj denna grupp DUBBELKLICKA: visa/ dölj alla andra',
      },
    },
    project: {
      fi: "HANKE",
      se: "PROJEKT"
    },
    show_timeline: {
      fi: "Näytä aikajana",
      se: "Visa tidsaxel",
    },
    show_list: {
      fi: "Näytä listoina",
      se: "Visa som listor",
    },
    show_all: {
      fi: "Näytä kaikki",
      se: "Visa alla",
    },
    filter_documents: {
      fi: "Dokumentit",
      se: "Dokumenten",
    },
    filter: {
      fi: "Rajaa",
      se: "Begränsa",
    },
    download: {
      fi: "Lataa",
      se: "Ladda",
    },
    documents: {
      fi: "asiakirjaa",
      se: "dokument",
    },
    joista: {
      fi: "joista",
      se: "varav",
    },
    no_date: {
      fi: "päivämäärätön",
      se: "utan datum",
    },
    no_dates: {
      fi: "päivämäärätöntä",
      se: "utan datum",
    },
    hidden: {
      fi: 'Aikajanalla näkyvät vain ne asiakirjat, joille on saatavilla päivämäärä rajapintojen kautta. Päivämäärättömät asiakirjat löytyvät kuitenkin listanäkymästä, joka aukeaa oikean yläkulman NÄYTÄ LISTOINA -painikkeesta.',
      se: 'Endast dokument för vilka ett datum är tillgängliga via gränssnitten visas på tidsaxeln. Odaterade dokument kan dock hittas i listvyn, som öppnas i det övre högra hörnet av knappen VISA SOM LISTOR.',
    },
    zoom_in: {
      fi: "lähennä",
      se: "zooma in",
    },
    zoom_out: {
      fi: "loitonna",
      se: "zooma ut",
    }
  },
  // UI: visualizations
  visual: {
    asiasanat: {
      fi: 'Dokumentteihin liittyvät asiasanat eduskunnan tietokannan mukaan.',
      se: 'Ämnesord relaterade till dokumenten enligt riksdagens databas.',
    },
    year: {
      fi: 'Niiden dokumenttien vuosittainen määrä, joissa hakutermi esiintyy. Joka 4. vuoden piikki johtuu hallituksen esitysten kohdalla siitä, että hallituskauden viimeisenä vuonna annetaan usein enemmän esityksiä kuin muina vuosina.',
      se: 'Det årliga antalet dokument där sökordetförekommer. Toppnoteringen vart fjärde år för regeringspropositionerna beror på att det ofta finns fler propositioner under det sista året av en regeringsperiod än under andra år.',
    },
    compare_all: {
      fi: "Vertaa kaikkiin",
      se: "Jämför med alla",
    },
    compare_prefilter: {
      fi: "Vertaa esirajattuun",
      se: "Jämför med förhandsbegränsade",
    },
    compare_all_tooltip: {
      fi: 'Näytä hakutuloksesi suhteessa kaikkiin saman kategorian asiakirjoihin.',
      se: 'Visa dina sökresultat i förhållande till alla dokument i samma kategori.',
    },
    compare_prefilter_tooltip: {
      fi: 'Näytä hakutuloksesi suhteessa kaikkiin esirajattuihin asiakirjoihin.',
      se: 'Visa dina sökresultat i förhållande till alla förhandsbegränsade dokument.',
    }
  },

  // UI: facets
  facets: {
    show_all: {
      fi: "näytä kaikki",
      se: "visa alla"
    },
    show_more: {
      fi: "Näytä lisää",
      se: "Visa mer",
    },
    show_less: {
      fi: "näytä vähemmän",
      se: "visa mindre" 
    },
    tooltip: {
      "asiakirjat.tyyppi": {
        fi: 'Jos asiakirjatyyppiä ei ole valittu, niin näkymään listataan hankkeen metatiedot.',
        se: 'Om dokumenttypen inte är vald kommer metadatan för projektet att listas.',
      },
      asiasanat: {
        fi: 'Dokumentteihin liittyvät asiasanat eduskunnan tietokannan mukaan.',
        se: 'Ämnesord relaterade till dokumenten enligt riksdagens databas.',
      },
      hallitus_hk: {
        fi: 'Hallitus hallituskausien mukaan',
        se: 'Regering enligt regeringsperiod',
      },
      year: {
        fi: 'Vuosi diaarinumeron mukaan.',
        se: 'Årtal enligt diarienummer.',
      },
    },
  },
  // UI: footer
  footer: {
    ohjeet: {
      fi: "Ohjeet",
      se: "Instruktioner"
    },
    palaute: {
      fi: "Palaute",
      se: "Respons"
    },
    tietosuoja: {
      fi: "Tietosuoja",
      se: "Dataskydd"
    },
    saavutettavuus: {
      fi: "Saavutettavuus",
      se: "Tillgänglighet"
    },
    turun_yliopisto: {
      fi: "TURUN YLIOPISTO",
      se: "ÅBO UNIVERSITET",
    },
    oikeustieteellinen_tiedekunta: {
      fi: "Oikeustieteellinen tiedekunta",
      se: "Juridiska fakultet",
    },
    tutkimuksen_it:{
      fi: "Tutkimuksen IT",
      se: "Forsknings IT",
    },
  },

  // UI: tietoja
  siteinfo: {
    title: {
      fi: "Tietoja sivustosta",
      se: "Information om sidan",
    }
  },

  // UI: tutkijalle
  researcher: {
    viittaus: {
      fi: "Viittausohjeet",
      se: "Referensanvisningar"
    },
    first_paragraph: {
      fi: (
        <p>
          {' '}
          Lakitutkasta kerättyjen aineistojen käyttäjän
          on noudatettava Tutkimuseettisen neuvottelukunnan (TENK) ohjeita{' '}
          <a href="https://tenk.fi/fi/ohjeet-ja-aineistot/HTK-ohje-2012" target="_BLANK" rel="noopener noreferrer" >
            "Hyvä tieteellinen käytäntö ja sen loukkausepäilyjen käsitteleminen Suomessa"</a>.
        </p>
      ),
      se: (<p>
        {' '}
        Den som använder information som samlats
        in genom Lakitutka ska följa Forskningsetiska delegationens (TENK) riktlinjer{' '}
        <a href="https://tenk.fi/fi/ohjeet-ja-aineistot/HTK-ohje-2012" target="_BLANK" rel="noopener noreferrer" >
          "God vetenskaplig praxis"
        </a>.
      </p>)
    },
    second_paragraph: {
      fi: (
        <p>
          Jos haluat käyttää Lakitutkaa aineiston
          keräämiseen, muttet löytänyt etsimääsi tai et
          onnistunut keräämään aineistoa toivotulla
          tavalla, ole meihin yhteydessä{' '}
          <a href="mailto:lakitutka@utu.fi">
            lakitutka@utu.fi
          </a>
          .
        </p>
      ),
      se: (
        <p>
          Om du vill använda Lakitutka för att samla material men inte hittar det du letar efter eller inte lyckades hitta materialet på önskat sett, ta kontakt med oss:
          <a href="mailto:lakitutka@utu.fi">
            lakitutka@utu.fi
          </a>
          .
        </p>
      )
    },
    third_paragraph: {
      fi: (
        <p>
          Kun hyödynnät Lakitutkaa esimerkiksi
          tutkimuksessa, tulee julkaisun metodiosiossa
          mainita, että tutkimuksessa on käytetty Turun
          yliopiston Lakitutkaa. Aineistoluetteloon
          tulee sisällyttää yleinen maininta siitä, että
          Lakitutkaa on käytetty aineiston
          keräämiseen/rajaamiseen/muuhun vastaavaan.
          Jokaisen Lakitutkalla kerätyn asiakirjan
          kohdalla ei kuitenkaan tarvitse viitata
          erikseen Lakitutkaan. Viittaa Lakitutkaan
          aineistoluettelossa näin: Tutkimusaineiston
          keräämiseen/rajaamiseen/analysointiin/… on
          käytetty Turun yliopiston{' '}
          <a
            href="https://lakitutka.fi/"
            target="_BLANK"
            rel="noopener noreferrer"
          >
            Lakitutka-palvelua
          </a>
          .
        </p>
      ),
      se: (
        <p>
          Då du använder Lakitutka, t.ex. i en studie, måste du i metodavsnittet av publikationen nämna att studien utnyttjat Lakitutka från Åbo universitet. Listan över data bör innehålla ett allmänt omnämnande om att Lakitutka använts för datainsamling/ klassificering/ etc. För varje dokument som samlats in med Lakitutka behöver man dock inte göra en separat hänvisning till Lakitutka. Vänligen hänvisa till Lakitutka i listan över data på följande sätt: Åbo universitets
          {' '}
          <a
            href="https://lakitutka.fi/"
            target="_BLANK"
            rel="noopener noreferrer"
          >
            Lakitutka-tjänst
          </a>{' '}
          har använts för att samla in/ begränsa/ analysera/... forskningsdata.{' '}
        </p>
      )
    },
  },
  // UI: ohjeet
  instructions: {
    short_title: {
      fi: "Ohjeet",
      se: "Instruktioner"
    },
    long_title: {
      fi: "Ohjeet Lakitutkan käyttöön",
      se: "Instruktioner för användingen av Lakitutka"
    },
    download_instructions:  {
      fi: "Lataa tekstimuotoiset ohjeet (pdf)",
      se: "Ladda ner textintruktionerna på finska (pdf)",
    },
    note:  {
      fi: "Huom! Videoissa esiintyvä ’Lausuntokierrokset’ välilehti vastaa sisällöltään täysin nykyistä ’Valmisteluasiakirjat’ välilehteä. Välilehden nimi on muutettu, jotta se kuvaisi sisältöä paremmin.",
      se: "Obs! Fliken ’Remissbehandling’ i videorna är innehållsmässigt identisk med den nuvarande fliken ’Förberedelsedokument’. Namnet på fliken har ändrats för att bättre återspegla innehållet.",
    },
  },
  
  // UI: tietoja lakitutkasta
  about_lawradar: {
    title: {
      fi: "Tietoja Lakitutkasta",
      se: "Om Lakitutka"
    },
    about_paragraph_1: {
      fi: "Eduskunta hyväksyy joka vuosi satoja hallituksen esityksiä laiksi. Säädettyjen lakien taustalla on useita valmisteluvaiheita, joiden aikana tuotetaan valtava määrä asiakirjoja. Lakiehdotuksen taustaksi voidaan teettää raportteja, tutkimuksia tai selvityksiä. Lakitekstin ensimmäinen versio perusteluineen lähetetään lausuntokierrokselle. Eduskunnassa kansanedustajat keskustelevat lakiehdotuksista, ja nämä keskustelut dokumentoidaan täysistuntojen pöytäkirjoihin. Lainvalmistelun aikana syntyneet dokumentit ovat lähtökohtaisesti julkisia, ja niiden tulee olla kenen tahansa saatavilla. Aiemmin asiakirjat olivat hajallaan eri tietokannoissa, kaikki asiakirjat eivät olleet sähköisesti saatavilla, ja tietokantojen hakutoiminnot olivat hankalia käyttää. Asiakirjojen haku ja niiden käsittely tutkimustyössä vei todella paljon aikaa.",
      se: "Riksdagen godkänner årligen hundratals regeringspropositioner till lagstiftning. Bakom lagstiftningen finns flera stadier av beredning som sammantaget producerar en enorm mängd dokument. I bakgrunden till ett lagförslag finns oftast rapporter, undersökningar och utredningar. Då den första versionen av en ny lagtext är färdig cirkuleras den bland diverse intressenter och enheter för remissbehandling. Lagförslag diskuteras även av riksdagsledamöterna i plenum, vilket i sin tur dokumenteras i protokoll som utgör en ytterligare kategori av dokument. Dokumenten som föds under lagberedningen är i regel offentliga och ska finnas tillgängliga för vem som helst att läsa. Förr fanns dokumenten utspridda i olika databaser som ofta var svåra att navigera, vilket gjorde det både utmanande och tidskrävande att hitta och hantera dokumenten i till exempel forskningsarbeten.",
    },
    about_paragraph_2: {
      fi: "Turun yliopiston oikeustieteellisen tiedekunnan tutkijat, opiskelijat ja yliopiston it-suunnittelijat kehittivät Lakitutka-verkkosovelluksen ratkaisuksi ongelmaan. Lakitutka kokoaa yhteen hakupalveluun lainvalmistelun aikana syntyvät julkiset asiakirjat koko valmisteluprosessin ajalta. Lakitutka tarjoaa samalla kaikille aiempaa paremman mahdollisuuden päästä käsiksi omaan arkeen vaikuttavan lainsäädännön tausta-aineistoon.",
      se: "Forskare och studerande vid juridiska fakulteten vid Åbo Universitet, tillsammans med universitetets IT-planerare, utvecklade webbtjänsten Lakitutka som en lösning på problemet. Lakitutka samlar ihop alla dokument som tillhör lagberedningens olika skeden i en lättanvänd söktjänst. Dessutom erbjuder Lakitutka bättre möjligheter för alla att hitta och navigera ärenden och dokument kopplade till lagstiftningen som påverkar vår allas vardag.",
    },
    about_paragraph_3: {
      fi: "Lakitutkalla voit tarkastella lakihankkeiden etenemistä hallituksen esitysten, annettujen lausuntojen, valiokunta-asiakirjojen ja eduskuntakeskusteluiden kautta. Lakitutkalla voi selvittää esimerkiksi eri hallitusten toimintaa tiettyyn teemaan liittyen. Lakitutkan avulla on mahdollista tutkia, mitkä tahot valmistelevat, puolustavat ja vastustavat itselle tärkeitä lakihankkeita. Yksittäisen lakihankkeen kulkua voi tarkastella aikajanalla, jolla näkyvät kaikki siihen liittyvät valmisteluasiakirjat. Aikajanan kautta on mahdollista avata asiakirjat luettavaksi yksi kerrallaan. Lakitutkassa on visualisointiominaisuus, joka esittää hakutulokset nopeasti hahmotettavina diagrammeina.",
      se: "I Lakitutka kan du undersöka lagprojektens framskridning genom regeringspropositioner, givna utlåtanden, utskottens ärenden och riksdagsdiskussioner. Du kan till exempel ta reda på och jämföra de olika regeringarnas verksamhet inom olika teman och frågor. Enskilda lagprojekt kan undersökas på en tidsaxel som åskådliggör samtliga ärenden som rör projektet och deras kronologiska ordning. Lakitutka har även visualiseringsegenskaper som kan presentera sökresultat i lättförstådda diagram.",
    },
    about_paragraph_4: {
      fi: "Lakitutka helpottaa myös tutkimuksen tekemistä. Lakitutkasta on mahdollista ladata itse rajattu joukko asiakirjatiedostoja, kuten hallituksen esityksiä tai lausuntokierrosten lausuntoja. Lakitutkasta voi ladata myös matriiseja asiakirjojen metatiedoista, eikä asiakirjojen tunnistetietoja tarvitse enää kirjata käsityönä.",
      se: "Lakitutka underlättar även arbetet för forskare. Det är möjligt att hitta och ladda ner dokument enligt egna och noggranna begränsningar, och metadata om dokumenten finns tillgängligt för nedladdning i färdiga matriser. Lakitutka minskar därmed mängden manuellt arbete som forskaren tidigare varit tvungen att utföra själv.",
    },
    about_paragraph_5: {
      fi: "Lakitutkan tavoitteena on lisätä kiinnostusta lakien syntymekanismeihin ja tuoda lakien säätämiseen liittyvää tietoa yhä useamman saataville. Tarkoituksena on edistää lainsäädännön läpinäkyvyyttä, tuoda ilmi lainsäädäntöprosesseihin liittyvää vaikuttamista, tukea aktiivista kansalaisyhteiskuntaa ja parantaa jokaisen osallistumismahdollisuuksia, sekä helpottaa lainsäädäntöön liittyvän tutkimuksen tekemistä.",
      se: "Målet med Lakitutka är att öka intresset för de mekanismer genom vilka lagar skapas och att göra information relaterad till lagstiftningen tillgänglig för allt fler människor. Syftet är att främja insyn i lagstiftningen, att avslöja influenser och element som påverkar lagstiftningsprocessen, att stödja ett aktivt civilsamhälle och att förbättra allas möjlighet till delaktighet samt att underlätta forskning relaterad till lagstiftning.",
    },
    about_instructions: {
      fi: "Tutustu Lakitutkan ohjeisiin",
      se: "Bekanta dig med Lakitutkas instruktioner"
    },
    about_here: {
      fi: "täällä",
      se: "här"
    },
    interfaces:  {
      fi: "Käytetyt rajapinnat ja aineistoon liittyvät varaumat",
      se: "Använda gränssnitt och reservationer rörande materialet",
    },
    interfaces_paragraph_1_1: {
      fi: "Lakitutkan asiakirjamateriaalit haetaan",
      se: "Lakitutkas material hämtas från",
    },
    interfaces_paragraph_1_government: {
      fi: "valtioneuvoston Hankeikkunasta",
      se: "Statsrådets projektfönster",
    },
    interfaces_paragraph_1_and: {
      fi: "ja",
      se: "och",
    },
    interfaces_paragraph_1_parliament: {
      fi: "Eduskunnan avoimen datan verkkopalvelusta",
      se: "webbtjänsten för Riksdagens öppna data",
    },
    interfaces_paragraph_1_2: {
      fi: "Asiakirjojen saatavuus tietyistä vuosista alkaen liittyy siihen, mistä alkaen asiakirjoja on saatavilla avoimista rajapinnoista. Hallituksen esityksiä on kerätty hieman pidemmältä ajalta.",
      se: "Dokumentens tillgänglighet är beroende av deras tillgänglighet via de öppna gränssnitten, det vill säga att vissa historiska begränsningar finns.",
    },
    interfaces_paragraph_2: {
      fi: "Lainsäädäntöhankkeen tiedot ja hankkeisiin liittyvät asiakirjat, kuten lausuntokierrosten lausunnot, ovat saatavilla vuodesta 1995 alkaen. Hankkeet ja niihin liittyvät asiakirjat löytyvät ainoastaan osittain. Varsinkin vuosilta 1995-2000 puuttuu asiakirjoja, sillä niitä ei ole julkaistu sähköisessä muodossa. Samasta syystä asiakirjoja puuttuu myös myöhemmistä hankkeista. Jatkossa näitä on tarkoitus lisätä tietokantaan. Tiedot on haettu valtioneuvoston Hankeikkunasta.",
      se: "Information och dokument relaterade till lagstiftningsprojekt, såsom utlåtanden från remissbehandling, finns tillgängliga från år 1995 och framåt. Dock saknas vissa dokument, särskilt från åren 1995-2000, eftersom de inte publicerats digitalt. Det som saknas kommer att kompletteras manuellt i framtiden. Datat är hämtat från Statsrådets projektfönster.",
    },
    interfaces_paragraph_3: {
      fi: "Hallituksen esitykset ovat saatavilla vuodesta 1976 alkaen. Uusia hallituksen esityksiä ja niihin liittyviä dokumentteja päivitetään Lakitutkaan. Myös vanhempaa tietoa pyritään tulevaisuudessa lisäämään palveluun. Lähteenä Eduskunnan avoimen datan verkkopalvelu.",
      se: "Regeringspropositioner från och med år 1991 finns tillgängliga i Lakitutka, och uppdateras efter hand när det kommer nya regeringspropositioner eller relaterade dokument. Syftet är att i framtiden även utvidga materialet historiskt med äldre dokument. Som källa har webbtjänsten för Riksdagens öppna data använts.",
    },
    interfaces_paragraph_4: {
      fi: "Valiokunta-asiakirjat eli valiokuntien lausunnot ja mietinnöt ovat saatavilla vuodesta 1991 alkaen. Tämän välilehden alta löytyvät myös valiokuntien asiantuntijalausunnot, jotka ovat saatavilla vuodesta 2015 alkaen. Lähteenä Eduskunnan avoimen datan verkkopalvelu.",
      se: "Utskottens ärenden, alltså betänkanden och utlåtanden, finns tillgängliga från år 1991 framåt. Under kategorin för utskottens ärenden hittas även sakkunnigutlåtanden från år 2015 framåt. Webbtjänsten för riksdagens öppna data har använts som källa för dessa.",
    },
    interfaces_paragraph_5: {
      fi: "Eduskuntakeskustelujen pöytäkirjat ovat saatavilla vuodesta 2015 alkaen. Lakitutkassa on tällä hetkellä pöytäkirjat vain niistä keskusteluista, jotka liittyvät johonkin valmisteltavaan lakiin. Lähteenä Eduskunnan avoimen datan verkkopalvelu.",
      se: "Riksdagsdiskussioner från år 2015 framåt finns även tillgängliga via Lakitutka. För tillfället är riksdagsdiskussionerna i Lakitutka begränsade till de diskussioner som är relaterade till beredningen av någon lag. Som källa har webbtjänsten för Riksdagens öppna data använts.",
    },
    interfaces_swedish: {
      fi: "",
      se: "Materialet på svenska kan även vara begränsat enligt brister i tillgängligheten av dokument på svenska.",
    },
    workgroup:  {
      fi: "Työryhmä",
      se: "Arbetsgrupp",
    },
    contact_1: {
      fi: "Yhteydenotot osoitteeseen",
      se: "Kontaktförfrågningar kan göras via",
    },
    contact_2: {
      fi: "tai suoraan työryhmän jäseniin.",
      se: "eller direkt till arbetsgruppens medlemmar.",      
    },
    financing:  {
      fi: "Lakitutkan rahoittajat",
      se: "Lakitutkas finansiärer",
    },
    lakitutka_origins: {
      fi: "Lakitutka on kehitetty",
      se: "Lakitutka är utvecklat i",
    },
    utu: {
      fi: "Turun yliopistossa",
      se: "Åbo Universitet",
    },
    development_1: {
      fi: "Lakitutkaa kehitetään edelleen",
      se: "Lakitutka utvecklas som en del av",
    },
    development_sile: {
      fi: "SILE-hankkeen",
      se: "SILE-projektet",
    },
    development_2: {
      fi: "yhteydessä erityisesti lainvalmistelun saavutettavuuteen keskittyen sekä",
      se: "särskilt med fokus på tillgängligheten till lagberedningen, samt som en del av",
    },
    development_lawpol: {
      fi: "LAWPOL-hankkeessa",
      se: "LAWPOL-projektet",
    },
    development_3: {
      fi: "laajentaen palvelussa saatavilla olevaa asiakirja-aineistoa.",
      se: "för att utvidga volymen av material tillgängligt via tjänsten.",
    },
    sile:  {
      fi: "SILE-hanke",
      se: "SILE-projektet",
    },
    sile_numbers:  {
      fi: "SILE-hanketta rahoittaa Suomen Akatemian yhteydessä toimiva strategisen tutkimuksen neuvosto. Päätösnumerot 335442 ja 335654 (2020-2023) sekä 358263 ja 358264 (2023-2026)",
      se: "SILE-projektet finansieras av rådet för strategisk forskning vid Finlands Akademi. Beslutsnummer 335442 och 335654 (2020-2023) samt 358263 och 358264 (2023-2026).",
    },
    sile_link: {
      fi: "SILE-hankkeen verkkosivut",
      se: "SILE-projektets hemsida",
    },
    lawpol:  {
      fi: "LAWPOL-hanke",
      se: "LAWPOL-projektet",
    },
    lawpol_numbers: {
      fi: "LAWPOL-konsortio on saanut Suomen Akatemialta FIRI2022: Paikalliset tutkimusinfrastruktuurit -rahoituksen vuosille 2023-2025 päätösnumeroilla 352827, 353569 ja 352828. Rahoitus on osa EU:n elpymisvälinerahoitusta.",
      se: "LAWPOL-konsortiet har fått finansiering av Finlands Akademi FIRI2022: Lokala forskningsinfrastrukturer för åren 2025-2025 under beslutsnumren 352827, 353569 och 352828. Finansieringen är en del av EU:s återhämtningsfacilitet.",
    },
    lawpol_link: {
      fi: "LAWPOL-hankkeen verkkosivut",
      se: "LAWPOL-projektets hemsida",
    }
  },

  // UI: palaute
  feedback: {
    title: {
      fi: "Palautteesi",
      se: "Din feedback"
    },
    intro_1: {
      fi: "Jos toivot vastausta palautteeseesi tai haluat aloittaa keskustelun, niin ota meihin suoraan yhteyttä sähköpostilla",
      se: "Om du vill ha ett svar eller önskar att börja ett samtal, vänligen kontakta oss direkt via e-post",
    },
    intro_2: {
      fi: "Ethän kirjoita henkilökohtaisia tietojasi alla olevaan lomakkeeseen.",
      se: "Vänligen ange inte dina personuppgifter i formuläret nedan.",
    },
    succeeded: {
      fi: "Miten onnistuimme? Mitä mielestäsi tulisi kehittää?",
      se: "Hur lyckades vi? Vad ser du att vi ska utveckla?",
    },
    site_link: {
      fi: "Jos palautteesi koskee tiettyä sivua, voit kopioida osoitteen tähän", 
      se: "Om din feedback handlar om en spesifik sida, kopiera adressen här"
    },
    site_link_example: {
      fi: "esim. https://lakitutka.fi/asiakirjat/hallituksen-esitykset/he39-2018",
      se: "t.ex. https://lakitutka.fi/asiakirjat/hallituksen-esitykset/he39-2018",
    },
    email: {
      fi: "Sähköpostiyhteys (halutessasi vastauksen palautteeseesi)", 
      se: "Email adressen (om du vill ha svar)"
    },
    send: {
      fi: "Lähetä",
      se: "Skicka"
    }
  },

  // UI: saavutettavuus
  accessability: {
    short_title: {
      fi: "Saavutettavuusseloste",
      se: "Tillgänglighetsutlåtande"
    },
    long_title: {
      fi: "LAKITUTKA.FI – Saavutettavuusseloste",
      se: "LAKITUTKA.FI - Tillgänglighetsutlåtande"
    },
    section_1_title: {
      fi: "Teknistä tietoa verkkosivuston saavutettavuudesta",
      se: "Teknisk information om webbsidans tillgänglighet"
    },
    section_1_texta: {
      fi: "Turun yliopisto pyrkii takaamaan verkkosivustonsa saavutettavuuden Euroopan parlamentin ja neuvoston saavutettavuusdirektiivin mukaisesti. Suomessa saavutettavuusdirektiivin toteuttaa laki digitaalisten palveluiden tarjoamisesta.",
      se: "Åbo universitet strävar efter att säkerställa tillgängligheten av sin webbsida i enlighet med Europaparlamentets och Europeiska rådets tillgänglighetsdirektiv. I Finland har tillgänglighetsdirektivet verkställts genom lagen om tillhandahållande av digitala tjänster."
    },
    section_1_textb: {
      fi: "Tämä saavutettavuusseloste koskee lakitutka.fi-verkkosivustoa.",
      se: "Detta tillgänglighetsutlåtande gäller lakitutka.fi-webbsidan."
    },
    section_1_1_title: {
      fi: "Verkkosivuston saavutettavuuden tila",
      se: "Status av webbsidans tillgänglighet"
    },
    section_1_1_texta: {
      fi: "Tämä verkkosivusto täyttää",
      se: "Denna webbplats uppfyller delvis tillgänglighetskraven i"
    },
    section_1_1_textb: {
      fi: " -standardin AA-tason saavutettavuusvaatimukset osittain.",
      se: ", nivå AA."
    },
    section_1_2_title: {
      fi: "Ei-saavutettava sisältö",
      se: "Ej-tillgängligt innehåll"
    },
    section_1_2_text: {
      fi: "Lakitutka.fi-verkkosivustolla oleva 'näytä visualisointi'-ominaisuus ei ole saavutettava, mutta sama tieto on saatavilla haun tuloksissa ilman visualisointia.",
      se: "Funktionen 'visa visualisering' på lakitutka.fi är inte tillgänglig, men samma information finns tillgänglig i sökresultaten utan visualisering."
    },
    section_2_title: {
      fi: "Miten tämä saavutettavuusseloste on laadittu",
      se: "Hur detta tillgänglighetsutlåtande har sammanställts"
    },
    section_2_texta: {
      fi: "Seloste on laadittu 09.09.2021. Sivuston saavutettavuuden on arvioinut Turun Yliopisto, Tutkimuksen IT.",
      se: "Rapporten utarbetades 09.09.2021. Webbplatsens tillgänglighet har bedömts av Åbo universitet, Forsknings-IT."
    },
    section_2_textb: {
      fi: "Selostetta tarkistettiin viimeksi 09.09.2021.",
      se: "Beskrivningen granskades senast den 09.09.2021."
    },
    section_2_textc: {
      fi: "Saavutettavuustestaus suoritettiin Google Chrome, Mozilla Firefox sekä Microsoft Edge selaimilla.",
      se: "Tillgänglighetstestningen utfördes med webbläsarna Google Chrome, Mozilla Firefox och Microsoft Edge."
    },
    section_2_textd: {
      fi: "Saavutettavuustestauksessa hyödynnettiin WAVE ja aXe nimisiä selain laajennuksia. Laajennuksien lisäksi käytettiin NVDA-ruudunlukijaa sekä W3C:n HTML validaattoria.",
      se: "Webbläsartilläggen WAVE och aXe användes i tillgänglighetstestet. Utöver dessa tillägg användes skärmläsaren NVDA och HTML-validatorn W3C."
    },
    section_2_texte: {
      fi: "Sisällön testaus suoritettiin pääsääntöisesti käsin.",
      se: "I regel utfördes innehållstestningen manuellt."
    },
    section_3_title: {
      fi: "Palaute ja yhteystiedot",
      se: "Feeback och kontaktuppgifter"
    },
    section_3_texta: {
      fi: "Jos huomaat sivustolla saavutettavuusongelmia, anna ensin palautetta meille eli sivuston ylläpitäjälle osoitteeseen kirjaamo@utu.fi. Vastauksessa voi mennä 14 päivää.",
      se: "Om du upptäcker några tillgänglighetsproblem på webbsidan ber vi dig att först skicka feedback till oss, webbsidans administratör: kirjaamo@utu.fi. Det kan ta upp till 14 dagar innan du får svar."
    },
    section_3_textb: {
      fi: "Jos et ole tyytyväinen saamaasi vastaukseen tai et saa vastausta lainkaan kahden viikon aikana, voit tehdä ilmoituksen",
      se: "Om du inte är nöjd med det svar du får, eller om du inte får något svar alls inom två veckor, kan du anmäla ärendet till"
    },
    section_3_textc: {
      fi: "Etelä-Suomen aluehallintoviraston sivulla kerrotaan tarkasti, miten ilmoituksen voi tehdä ja miten asia käsitellään.",
      se: "På Regionförvaltningsverket i Södra Finlands sida förklaras exakt hur du anmäler ärendet och hur det kommer att hanteras."
    },
    section_3_link: {
      fi: "https://www.saavutettavuusvaatimukset.fi",
      se: "https://www.tillganglighetskrav.fi/dina-rattigheter/"
    },
    section_3_linktext: {
      fi: "Etelä-Suomen aluehallintovirastoon",
      se: "Regionförvaltningsverket i Södra Finland"
    },
    section_3_1_title: {
      fi: "Valvontaviranomaisen yhteystiedot",
      se: "Tillsynsmyndigehetens kontaktuppgifter"
    },
    section_3_1_texta: {
      fi: "Etelä-Suomen aluehallintovirasto",
      se: "Regionförvaltningsverket i Södra Finland"
    },
    section_3_1_textb: {
      fi: "Saavutettavuuden valvonnan yksikkö",
      se: "Enheten för tillgänghetstillsyn"
    },
    section_3_1_link: {
      fi: "https://www.saavutettavuusvaatimukset.fi",
      se: "https://www.tillganglighetskrav.fi"
    },
    section_3_1_linktext: {
      fi: "www.saavutettavuusvaatimukset.fi",
      se: "www.tillganglighetskrav.fi"
    },
    section_3_1_email: {
      fi: "saavutettavuus@avi.fi",
      se: "webbtillganglighet@rfv.fi"
    },
    section_4_title: {
      fi: "Teemme jatkuvasti työtä saavutettavuuden parantamiseksi",
      se: "Vi arbetar kontinuerligt för att förbättra tillgängligheten"
    },
    accessibility_in_utu: {
      fi: "Saavutettavuus Turun yliopistossa",
      se: "Tillgängligheten vid Åbo universitet"
    }
  },

  // dokumenttityypit (välilehdet)
  doc_groups: {
    intro: {
      fi: 'Lakitutkasta löytyvät dokumentit',
      se: 'Dokumenten i Lakitutka',
    },
    lausuntokierros: {
      name: {
        fi: 'Valmisteluasiakirjat',
        se: 'Beredningsdokument'
      },
      optionalName: {
        fi: 'Hankkeen aloitus',
        se: 'Projektets startpunkt'
      },
      singular: {
        fi: 'Lainsäädäntöhanke',
        se: 'Lagstiftningsprojekt',
      },
      search: {
        fi: 'Hae valmisteluasiakirjoista',
        se: 'Sök bland beredningsdokument(en)'
      },
      text: {
        fi: (
          <>
            <span className="info-emphasis">
              Lainsäädäntöhankkeen tiedot ja hankkeisiin
              liittyvät valmisteluasiakirjat
            </span>
            , kuten hallituksen esityksen luonnosversiot,
            lausuntokierroksen lausuntopyynnöt ja lausunnot sekä
            mahdolliset selvitykset, löytyvät vuodesta 1995
            lähtien. Hankkeet ja niihin liittyvät asiakirjat
            löytyvät ainoastaan siltä osin, kuin ne on julkaistu
            Valtioneuvoston Hankeikkunassa. Varsinkin vuosilta
            1995-2000 asiakirjoja löytyy harvakseltaan.
          </>
        ),
        se: (
          <>
            <span className="info-emphasis">
              INFORMATION OM LAGSTIFTNINGSPROJEKT OCH FÖR DEM RELEVANTA BEREDNINGSDOKUMENT
            </span>
            , som utkast till regeringspropositioner, utlåtanden och begäran om utlåtanden, samt eventuella utredningar, finns tillgängliga fr.o.m. år 1995. Projekt och för dem relevanta dokument finns endast tillgängliga i den utsträckning som publicerats i Statsrådets projektfönster. Särskilt dokument från åren 1995-2000 finns endast i fåtal.
          </>
        )
      },
      source: {
        fi: 'Lähteenä valtioneuvoston Hankeikkuna.',
        se: 'Statsrådets projektfönster har använts som källa.'
      },
    },
    lausuntokierros_asiakirjat: {
      name: {
        fi: 'Lausuntokierroksen asiakirjat',
        se: 'Remissdebattens dokument'
      },
      singular: {
        fi: 'Lausuntokierroksen asiakirja',
        se: 'Remissdebattens dokument',
      },
    },
    lausuntokierros_lausunnot: {
      name: {
        fi: 'Lausuntokierroksen lausunnot',
        se: 'Remissdebattens utlåtanden'
      },
      singular: {
        fi: 'Lausunto',
        se: 'Uttalande',
      },
    },
    lausuntokierros_muut: {
      name: {
        fi: 'Lausuntokierroksen muut asiakirjat',
        se: 'Remissdebattens övriga dokument'
      },
      singular: {
        fi: 'Lausuntokierroksen asiakirja',
        se: 'Remissdebattens dokument',
      },
    },
    hallituksen_esitykset: {
      name: {
        fi: 'Hallituksen esitykset',
        se: 'Regeringspropositioner'
      },
      singular: {
        fi: 'Hallituksen esitys',
        se: 'Regeringsproposition',
      },
      search: {
        fi: 'Hae hallituksen esityksistä',
        se: 'Sök i regeringspropositioner'
      },
      text: {
        fi: (
          <>
            <span className="info-emphasis">
              Hallituksen esitykset
            </span>{' '}
            löytyvät vuodesta 1976 alkaen. Uudet hallituksen
            esitykset ja niihin linkittyvät dokumentit
            päivitetään Lakitutkaan sitä mukaa kun ne tulevat
            saataville. Myös vanhempaa sisältöä tullaan
            lisäämään palveluun tulevaisuudessa.
          </>
        ),
        se:
          (
            <>
              <span className="info-emphasis">
                Regeringspropositioner
              </span>{' '}
              från och med år 1991 finns tillgängliga via Lakitutka. Nya regeringspropositioner och tillhörande dokument uppdateras till Lakitutka i takt med att de blir tillgängliga. Även äldre material kommer att göras tillgängligt via tjänsten i framtiden.
            </>
          )
      },
      source: {
        fi: 'Lähteenä Eduskunnan avoimen datan verkkopalvelu.',
        se: 'Nättjänsten för Riksdagens öppna data har använts som källa.',
      },
      update_policy: {
        fi: 'Uusimmat dokumentit pyritään lisäämään hakukoneeseen päivittäin.',
        se: 'Senaste dokumenten strävas att tillsätta dagligen.',
      }
    },
    valiokunta_asiakirjat: {
      name: {
        fi: 'Valiokunta-asiakirjat',
        se: 'Utskottens ärenden'
      },
      singular: {
        fi: 'Valiokunta-asiakirjat',
        se: 'Utskottsdokument',
      },
      search: {
        fi: 'Hae valiokunta-asiakirjoista',
        se: 'Sök ur utskottsdokument'
      },
      text: {
        fi: (
          <>
            <span className="info-emphasis">
              Valiokunta-asiakirjat
            </span>{' '}
            eli valiokuntien lasunnot ja mietinnöt löytyvät
            vuodesta 1991 alkaen. Tämän välilehden alta löytyvät
            myös valiokuntien asiantuntijalausunnot, jotka ovat
            saatavissa vuodesta 2015 alkaen.
          </>
        ),
        se:
          (
            <>
              <span className="info-emphasis">
                Utskottens dokument
              </span>{' '}
              , alltså utskottens utlåtanden och betänkanden, finns tillgängliga från år 1991 framåt. På den här sidan finns även utskottens sakkunnigutlåtanden från och med år 2015 tillgängliga.
            </>
          )
      },
      source: {
        fi: 'Lähteenä Eduskunnan avoimen datan verkkopalvelu.',
        se: 'Nättjänsten för Riksdagens öppna data har använts som källa.',
      },
      update_policy: {
        fi: 'Uusimmat dokumentit pyritään lisäämään hakukoneeseen päivittäin.',
        se: 'Senaste dokumenten strävas att tillsätta dagligen.',
      }
    },
    he_asiantuntijalausunnot: {
      name: {
        fi: 'Asiantuntijalausunnot',
        se: 'Expertutlåtanden'
      },
      singular: {
        fi: 'Asiantuntijalausunto',
        se: 'Expertutlåtande'
      },
    },
    he_asiantuntijalausunto: {
      name: {
        fi: 'Asiantuntijalausunnot',
        se: 'Expertutlåtanden'
      },
      singular: {
        fi: 'Asiantuntijalausunto',
        se: 'Expertutlåtanden'
      },
    },
    poytakirja: {
      name: {
        fi: 'Eduskuntakeskustelut',
        se: 'Riksdagsdiskussioner'
      },
      singular: {
        fi: 'Eduskuntakeskustelu',
        se: 'Riksdagsdebatt',
      },
      search: {
        fi: 'Hae eduskuntakeskusteluista',
        se: 'Sök ur riksdagsdebatter'
      },
      text: {
        fi: (
          <>
            <span className="info-emphasis">
              Eduskuntakeskustelujen pöytäkirjat
            </span>{' '}
            löytyvät vuodesta 2015 alkaen. Lakitutkassa on
            ainoastaan pöytäkirjat keskusteluista, jotka
            liittyvät valmisteltavaan lakiin.
          </>
        ),
        se:
          (
            <>
              <span className="info-emphasis">
                Riksdagsdiskussionernas protokoll
              </span>{' '}
              från år 2015 framåt. På Lakitutka är endast protokoll från diskussioner rörande lagberedning tillgängliga.
            </>
          )
      },
      source: {
        fi: 'Lähteenä Eduskunnan avoimen datan verkkopalvelu.',
        se: 'Nättjänsten för Riksdagens öppna data har använts som källa.',
      },
      update_policy: {
        fi: 'Uusimmat dokumentit pyritään lisäämään hakukoneeseen päivittäin.',
        se: 'Senaste dokumenten strävas att tillsätta dagligen.',
      }
    },
    kansalaisaloite: {
      name: {
        fi: 'Kansalaisaloitteet',
        se: 'Medborgarinitiativ'
      },
      singular: {
        fi: 'Kansalaisaloite',
        se: 'Medborgarinitiativ',
      }
    },
    lakialoite: {
      name: {
        fi: 'Lakialoitteet',
        se: 'Lagmotioner'
      },
      singular: {
        fi: 'Lakialoite',
        se: 'Lagmotion',
      },
      search: {
        fi: 'Hae lakialoitteista',
        se: 'Sök ur lagmotionerna'
      },
      text: {
        fi: (
          <>
            <span className="info-emphasis">
              Kansanedustajien lakialoitteet
            </span>{' '}
            löytyvät vuodesta 2015 alkaen.
            Lisäksi Lakitutkasta löytyvät kaikki eduskunnalle luovutetut <span className="info-emphasis">
              kansalaisaloitteet
            </span>.{' '}
          </>
        ),
        se:
          (
            <>
              <span className="info-emphasis">
                Riksdagsledamöternas lagmotioner
              </span>{' '}
              från och med år 2015 finns tillgängliga. Även samtliga till riksdagen överlämnade MEDBORGARINITIATIV finns tillgängliga via Lakitutka.
            </>
          )
      },
      source: {
        fi: 'Lähteenä Eduskunnan avoimen datan verkkopalvelu.',
        se: 'Nättjänsten för Riksdagens öppna data har använts som källa.',
      },
      update_policy: {
        fi: 'Uusimmat dokumentit pyritään lisäämään hakukoneeseen päivittäin.',
        se: 'Senaste dokumenten strävas att tillsätta dagligen.',
      }
    },
    all: {
      name: {
        fi: 'Kaikki asiakirjat',
        se: 'Alla dokument'
      },
      singular: {
        fi: 'Asiakirja',
        se: 'Dokument',
      },
      search: {
        fi: 'Hae kaikista asiakirjoista',
        se: 'Sök ur alla dokument'
      },
      text: {
        fi: (
          <>
            <span className="info-emphasis">
              Kaikki asiakirjat
            </span>{' '}
          </>
        ),
        se: (
          <>
            <span className="info-emphasis">
              Alla dokument
            </span>{' '}
          </>
        )
      },
      source: {
        fi: 'Lähteenä Eduskunnan avoimen datan verkkopalvelu sekä valtioneuvoston hankeikkuna.',
        se: 'Nättjänsten för Riksdagens öppna data, samt Statsrådets projektfönster har använts som källor.'
      }
    },
    eduskunnan_vastaukset: {
      name: {
        fi: 'Eduskunnan vastaukset',
        se: 'Riksdagens svar',
      },
      singular: {
        fi: 'Eduskunnan vastaus',
        se: 'Riksdagens svar',
      }
    },
    laws: {
      name: {
        fi: 'Lakipäätökset',
        se: 'Lagbeslut'
      },
      singular: {
        fi: 'Lakipäätös',
        se: 'Lagbeslut',
      },
    }
  }
}

const keywords = {
  laki_he: {
    fi: 'Hallituksen esitykset',
    se: 'Regeringspropositiest'
  },
  laki_la: {
    fi: 'Lakialoite',
    se: 'Lagmotioner'
  },
  laki_ka: {
    fi: 'Kansalaisaloite',
    se: 'Medborgarinitiativ'
  },
  laki_vk: {
    fi: 'Valiokunta-asiakirjat',
    se: 'Utskottens ärenden'
  },
  laki_puheenvuoro: {
    fi: 'Eduskuntakeskustelut',
    se: 'Riksdagsdiskussioner'
  },
  laki_lausuntokierros: {
    fi: 'Valmisteluasiakirjat',
    se: 'Beredningsdokument'
  },
  he_asiantuntijalausunnot: {
    fi: 'Asiantuntijalausunnot',
    se: 'Expertutlåtanden'
  },
  laki_lausuntokierros_asiakirjat: {
    fi: 'Lausuntokierroksen asiakirjat',
    se: 'Remissdebattens dokument'
  },

  /* TODO? */
  // valiokunta: 'Valiokunta',
  // hallituksen_esitykset: 'Hallituksen esitys',
  // valiokunta_asiakirjat: 'Valiokunta-asiakirja',
  // lausuntokierros: 'Lausuntokierros',
  // lausuntokierros_asiakirjat: 'Lausuntokierros',
  // poytakirja: 'Pöytäkirjat',
  // talousarviot: 'Talousarviot',
  // eduskunnan_kirjelma: 'Eduskunnan kirjelmä',

  // sorting
  score: {
    fi: 'relevanssi',
    se: 'relevans'
  },
  date_asc: {
    fi: 'vanhin ensin',
    se: 'äldsta först'
  },
  date_desc: {
    fi: 'uusin ensin',
    se: 'nyaste först'
  },
  alpha_asc: {
    fi: 'tunnus (nouseva)',
    se: 'identifikation (stigande)'
  },
  alpha_desc: {
    fi: 'tunnus (laskeva)',
    se: 'identifikation (fallande)'
  },

  // hallituksen_esitykset, asian_tila 
  Hylätty: {
    fi: "Hylätty",
    se: "Nedlagd",

  },
  "Jätetty lepäämään": {
    fi: "Jätetty lepäämään",
    se: "Vilande",

  },
  "Käsitelty": {
    fi: "Käsitelty",
    se: "Behandlat",

  },
  "Hyväksytty muutettuna": {
    fi: "Hyväksytty muutettuna",
    se: "Godkänd med ändringar",

  },
  Peruutettu: {
    fi: "Peruutettu",
    se: "Upphört",
  },
  "Peruutettu ":{
    fi: "Peruutettu",
    se: "Upphört",
  },
  Käsittelyssä: {
    fi: "Käsittelyssä",
    se: "Under behandling",
  },
  Hyväksytty: {
    fi: "Hyväksytty",
    se: "Godkänd",
  },
  "Hyväksytty kiireellisenä": {
    fi: "Hyväksytty kiireellisenä",
    se: "Godkänd som brådskande",
  },
  "Käsittely päättynyt": {
    fi: "Käsittely päättynyt",
    se: "Förhandlingen avslutad",
  },
  Rauennut: {
    fi: "Rauennut",
    se: "Förfallet",
  },

  // lausuntokierros tila
  Päättynyt: {
    fi: 'Päättynyt',
    se: 'Avslutad'
  },
  Käynnissä: {
    fi: 'Käynnissä',
    se: 'Pågående'
  },
  Suunnitteilla: {
    fi: 'Suunnitteilla',
    se: 'Planerad'
  },

  // Ministries
  
  "Valtioneuvoston kanslia": {
    fi: 'Valtioneuvoston kanslia',
    se: 'Statsrådets kansli',
  },
  "Ulkoministeriö": {
    fi: 'Ulkoministeriö',
    se: 'Utrikesministeriet',
  },
  "Ulkoasiainministeriö": {
    fi: 'Ulkoasiainministeriö',
    se: 'Utrikesministeriet',
  },
  "Oikeusministeriö": {
    fi: 'Oikeusministeriö',
    se: 'Justitieministeriet',
  },
  "Sisäministeriö": {
    fi: 'Sisäministeriö',
    se: 'Inrikesministeriet',
  },
  "Puolustusministeriö": {
    fi: 'Puolustusministeriö',
    se: 'Försvarsministeriet',
  },
  "Valtiovarainministeriö": {
    fi: 'Valtiovarainministeriö',
    se: 'Finansministeriet',
  },
  "Opetus- ja kulttuuriministeriö": {
    fi: 'Opetus- ja kulttuuriministeriö',
    se: 'Undervisnings- och kulturministeriet',
  },
  "Opetusministeriö": {
    fi: 'Opetusministeriö',
    se: 'Undervisningsministeriet',
  },
  "Maa- ja metsätalousministeriö": {
    fi: 'Maa- ja metsätalousministeriö',
    se: 'Jord- och skogsbruksministeriet',
  },
  "Liikenne- ja viestintäministeriö": {
    fi: 'Liikenne- ja viestintäministeriö',
    se: 'Kommunikationsministeriet',
  },
  "Liikenneministeriö":  {
    fi: 'Liikenneministeriö',
    se: 'Trafikministeriet',
  },
  "Kauppa- ja teollisuusministeriö": {
    fi: 'Kauppa- ja teollisuusministeriö',
    se: 'Handels- och industriministeriet',
  },
  "Työ- ja elinkeinoministeriö": {
    fi: 'Työ- ja elinkeinoministeriö',
    se: 'Arbets- och näringsministeriet',
  },
  "Työministeriö": {
    fi: 'Työministeriö',
    se: 'Arbetsministeriet',
  },
  "Sosiaali- ja terveysministeriö": {
    fi: 'Sosiaali- ja terveysministeriö',
    se: 'Social- och hälsovårdsministeriet',
  },
  "Ympäristöministeriö": {
    fi: 'Ympäristöministeriö',
    se: 'Miljöministeriet',
  },
  "Sisäasiainministeriö": {
    fi: "Sisäasiainministeriö",
    se: "Inrikesministeriet",
  },

  // facetsm, prefilters, etc...
  year: {
    fi: "Vuosi",
    se: "År"
  },
  asiasanat: {
    fi: 'Asiasanat',
    se: 'Ämnesord'
  },
  asiasanat_sv: {
    fi: 'Asiasanat',
    se: 'Ämnesord'
  },
  hallitus_hk: {
    fi: 'Hallitus',
    se: 'Regering'
  },
  ministerio: {
    fi: 'Valmisteleva ministeriö',
    se: 'Beredande ministerium'
  },
  asian_tila: {
    fi: 'Asian tila',
    se: 'Ärendets status',
  },
  valiokunta: {
    fi: 'Valiokunta',
    se: 'Utskott',
  },
  type: {
    fi: 'Asiakirjatyyppi',
    se: 'Dokumenttyp',
  },
  allekirjoittaja: {
    fi: 'Allekirjoittaja',
    se: 'Undertecknare',
  },
  asettaja: {
    fi: 'Asettaja',
    se: 'Uppsättare Arrangör',
  },
  tila: {
    fi: 'Tila',
    se: 'Status',
  },
  laatija: {
    fi: 'Laatija',
    se: 'Beredare',
  },
  "asiakirjat.tyyppi": {
    fi: "Asiakirjan tyyppi",
    se: "Typ av dokument",
  },
  _index: {
    fi: "Dokumenttityypit",
    se: "Dokumenttyper",
  },
  tunnus: {
    fi: 'Diaarinumero',
    se: 'Bildnummer',
  },

  aloitus_paiva: {
    fi: 'Aloituspäivä',
    se: 'Startdatum'
  },
  laatimispaiva: {
    fi: 'Laatimispäivä',
    se: 'Upprättningsdatum'
  },
  laadittu: {
    fi: 'Laadittu',
    se: 'Upprättad'
  },
  toimija: {
    fi: 'Toimija',
    se: 'Aktör'
  },

  // dates
  asettamis_paiva: {
    fi: 'Asettamispäivä',
    se: 'Datum för tillsättande',
  },
  valmistumis_paiva: {
    fi: 'Valmistumispäivä',
    se: 'Slutdatum',
  },
  annettu_eduskunnalle: {
    fi: 'Annettu eduskunnalle',
    se: 'Given till riksdagen',
  },
  paatos: {
    fi: 'Päätös',
    se: 'Beslut'
  },
  saapunut: {
    fi: 'Saapunut',
    se: 'Anlänt',
  },
  vahvistettu: {
    fi: 'Vahvistettu',
    se: 'Bekräftad',
  },
  voimaantulo: {
    fi: 'Voimaantulo',
    se: 'Ikraftträdande',
  },
  title: {
    fi: 'Otsikko',
    se: 'Rubrik',
  },
  date: {
    fi: 'Päivämäärä',
    se: 'Datum',
  },
  paiva: {
    fi: 'päivä',
    se: 'dag',
  },

  // lausuntokierros doc types are indexed in es in uppercase and without umlauts
  LAUSUNTOPYYNTO: {
    fi: 'Lausuntopyyntö',
    se: 'Remiss',
  },
  KANNANOTTO: {
    fi: 'Kannanotto',
    se: 'Ställningstagande',
  },
  PYYNTO: {
    fi: 'Pyyntö',
    se: 'Begäran',
  },
  PUHE: {
    fi: 'Puhe',
    se: 'Tal',
  },
  KUTSU: {
    fi: 'Kutsu',
    se: 'Inbjudan',
  },
  ASETTAMISPAATOS: {
    fi: 'Asettamispäätös',
    se: 'Beslut om författning',
  },
  SOPIMUS: {
    fi: 'Sopimus',
    se: 'Avtal',
  },
  RAPORTTI: {
    fi: 'Raportti',
    se: 'Rapport',
  },
  YHTEENVETO: {
    fi: 'Yhteenveto',
    se: 'Sammanfattning',
  },
  MUISTIO: {
    fi: 'Muistio',
    se: 'Promemoria',
  },
  LIITE: {
    fi: 'Liite',
    se: 'Bilaga',
  },
  MIETINTO: {
    fi: 'Mietintö',
    se: 'Betänkande',
  },
  LUPA: {
    fi: 'Lupa',
    se: 'Tillstånd',
  },
  KIRJE: {
    fi: 'Kirje',
    se: 'Brev',
  },
  SUUNNITELMA: {
    fi: 'Suunnitelma',
    se: 'Plan',
  },
  JULKAISU: {
    fi: 'Julkaisu',
    se: 'Publikation',
  },
  TIEDOTE: {
    fi: 'Tiedote',
    se: 'Pressmeddelande',
  },
  ESITYS: {
    fi: 'Esitys',
    se: 'Presentation',
  },
  PAATOS: {
    fi: 'Päätös',
    se: 'Beslut',
  },
  POYTAKIRJA: {
    fi: 'Pöytäkirja',
    se: 'Protokoll',
  },
  LAUSUNTO: {
    fi: 'Lausunto',
    se: 'Uttalande',
  },
}

const ministries = {
  "valtioneuvoston kanslia": {
    se: 'Statsrådets kansli',
  },
  "ulkoministeriö": {
    se: 'Utrikesministeriet',
  },
  "ulkoasiainministeriö": {
    se: 'Utrikesministeriet',
  },
  "Ulkoasiainministeriö": {
    se: 'Utrikesministeriet',
  },
  "oikeusministeriö": {
    se: 'Justitieministeriet',
  },
  "sisäministeriö": {
    se: 'Inrikesministeriet',
  },
  "puolustusministeriö": {
    se: 'Försvarsministeriet',
  },
  "valtiovarainministeriö": {
    se: 'Finansministeriet',
  },
  "opetus- ja kulttuuriministeriö": {
    se: 'Undervisnings- och kulturministeriet',
  },
  "opetusministeriö": {
    se: 'Undervisningsministeriet',
  },
  "maa- ja metsätalousministeriö": {
    se: 'Jord- och skogsbruksministeriet',
  },
  "liikenne- ja viestintäministeriö": {
    se: 'Kommunikationsministeriet',
  },
  "liikenneministeriö":  {
    se: 'Trafikministeriet',
  },
  "kauppa- ja teollisuusministeriö": {
    se: 'Handels- och industriministeriet',
  },
  "työ- ja elinkeinoministeriö": {
    se: 'Arbets- och näringsministeriet',
  },
  "työministeriö": {
    se: 'Arbetsministeriet',
  },
  "sosiaali- ja terveysministeriö": {
    se: 'Social- och hälsovårdsministeriet',
  },
  "ympäristöministeriö": {
    se: 'Miljöministeriet',
  },
}

export const translation = (...args) => {
  try {
    return args.reduce((acc, cur) => acc[cur], translations)
  } catch (e) {
    //console.log(e)
    return args[0]
  }
};

export const translateMinistry = (...args) => {
  /* translate ministry names */
  try {
    if (args.length > 0) {
      if (args[1] === 'se') {
        return ministries[(args[0].toLowerCase())][args[1]]
      }
      else
      {
        return args[0]
      }
    }
  } catch (e) {
    //console.log(e)
    return args.join(' ')
  }
}

export const translateKeyword = (...args) => {
  /* translate keywords which are used as keys and url parameters */
  try {
    if (args.length > 0) {
      const root = keywords[args[0]]

      // return first argument if there are no translations
      if (!root) {
        return args[0]
      }

      // these keywords 
      if (typeof root === 'string') {
        return root
      }
      return root[args[1]]

    } else {
      return args
    }
  } catch (e) {
    console.log(e)
    return args.join('-')
  }
}

// const info = {
//   machineread: 'Teksti voi olla koneellisesti luettu.',
//   machineread_older_text: 'Teksti on koneellisesti luettu paperimuotoisesta asiakirjasta skannatusta sähköisestä kopiosta. Teksti sisältää siksi käsittelyprosessissa syntyneitä virheitä.',
//   lausunnot_no_content:
//     'Lausuntovaiheen asiakirjat tulevat Lakitutkaan valtioneuvoston Hankeikkunan rajapinnan kautta. Tästä asiakirjasta löytyy toistaiseksi ainoastaan metatiedot, sillä dokumenttia ei ole lisätty valtioneuvoston Hankeikkunaan.',
//   feedback: (
//     <>
//       Jos toivot vastausta palautteeseesi tai haluat
//       aloittaa keskustelun, niin ota meihin suoraan yhteyttä
//       sähköpostilla{' '}
//       <a href="mailto:lakitutka@utu.fi">lakitutka@utu.fi</a>
//       . Ethän kirjoita henkilökohtaisia tietojasi alla
//       olevaan lomakkeeseen.
//     </>
//   ),
// };
//
// const tooltip = {
//   /* Timeline */
//   timeline: {
//     lausuntokierros:
//       'Valmistelevan ministeriön ilmoittama lakihankkeen aloitusajankohta. Tämä voi olla esimerkiksi työryhmän toimikauden tai virkatyövalmistelun aloituspäivämäärä.',
//     valiokunta_asiakirjat:
//       'Valiokuntien lausunnot ja mietinnöt. Valiokunnan mietintö syntyy valiokunnan käsittelyn pohjalta, ja sen perusteella eduskunnan täysistunto päättää asiasta. Valiokunta voi pyytää valiokuntakäsittelyn vaiheessa lausuntoa toiselta valiokunnalta.',
//     laws: 'Lakipäätös -kohdassa näet hankkeen ratkaisun. Siitä ilmenee, onko hallituksen tekemä lakiehdotus eduskunnassa esimerkiksi hyväksytty sellaisenaan, vai onko sitä muutettu ennen hyväksymistä. Esitys on voitu myös hylätä kokonaan tai osittain.',
//     hallituksen_esitykset:
//       'Hallituksen esityksellä lainsäädäntöasia laitetaan vireille eduskunnassa. Hallituksen esitykset valmistellaan ministeriöissä, ja suurin osa lainsäädäntöasioista eduskunnassa on lähtöisin hallituksen esityksestä.',
//     lausuntokierros_lausunnot:
//       'Hankkeen valmistelun jälkeen luonnoksesta hallituksen esitykseksi pyydetään kirjallisia lausuntoja valmistelevan ministeriön ulkopuolisilta tahoilta. Näitä voivat olla esimerkiksi muut ministeriöt, yhdistykset, järjestöt, viranomaiset ja kansalaiset.',
//     lausuntokierros_muut:
//       'Muut lausuntokierrokseen liittyvät asiakirjat voivat olla tyypiltään esimerkiksi lausuntopyyntöjä, kirjeitä, luonnoksia, liitteitä tai yhteenvetoja. Kirjauskäytännöt vaihtelevat, joten tyypit ovat vain suuntaa-antavia',
//     he_asiantuntijalausunnot:
//       'Valiokunnissa kuultujen asiantuntijoiden lausunnot. Asiantuntijalausunnot annetaan valiokunnalle valiokunnan pyynnöstä, kun taas lausuntokierroksen lausunnot aikaisemmassa vaiheessa ministeriölle.',
//     hankkeen_asettaminen:
//       'Hankkeen aloitus Hankeikkunan tietojen mukaan.',
//     eduskunnan_vastaukset:
//       'Eduskunta ilmoittaa valtioneuvostolle päätöksensä käsiteltävänä olevasta lainsäädäntöasiasta eduskunnan vastauksella.',
//     poytakirja:
//       'Eduskuntakeskusteluihin on taltioitu kirjallisessa muodossa hallituksen esityksistä käydyt eduskunnan täysistunnon keskustelut.',
//     piilotetut:
//       'Aikajanalla näkyvät vain ne asiakirjat, joille on saatavilla päivämäärä rajapintojen kautta. Päivämäärättömät asiakirjat löytyvät kuitenkin listanäkymästä, joka aukeaa oikean yläkulman NÄYTÄ LISTOINA -painikkeesta.',
//   },
// };

