import config from '../config';

const { apiUrl } = config;

const fetchData = async (search, format) => {
  const response = await fetch(`${apiUrl}${search}`);
  if (!response.ok) {
    if (
      response.headers.get('content-type') ===
      'application/json'
    ) {
      const err = await response.json();
      throw Error(err.description, {
        cause: response.statusText + ': ' + err.title,
      });
    }

    throw Error(
      response.statusText ||
        'Error while fetching data from backend',
    );
  } else {
    let result;
    switch (format) {
      case 'text':
        result = await response.text();
        break;

      case 'json':
      default:
        result = await response.json();
    }
    return result;
  }
};

const postData = async (path, data, format) => {
  const response = await fetch(`${apiUrl}${path}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    if (
      response.headers.get('content-type') ===
      'application/json'
    ) {
      const err = await response.json();
      throw Error(err.description, {
        cause: response.statusText + ': ' + err.title,
      });
    }

    throw Error(
      response.statusText ||
        'Error while fetching data from backend',
    );
  } else {
    let result;
    switch (format) {
      case 'text':
        result = await response.text();
        break;

      case 'json':
      default:
        result = await response.json();
    }
    return result;
  }
};

const sendFeedback = async (formData) => {
  const response = await fetch(`${apiUrl}feedback`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formData),
  });
  if (!response.ok) {
    throw Error(
      response.statusText || 'Error while sending feedback',
    );
  } else {
    const result = await response.json();
    return result;
  }
};

const checkFileStatus = async (filename) => {
  const response = await fetch(
    `${apiUrl}download/zip/${filename}/status`,
  );

  if (response.ok || response.status === 503) {
    // server returns customized errors with 503
    const isJson = response.headers
      .get('content-type')
      ?.includes('application/json');

    const data = isJson
      ? await response.json()
      : await response.text();

    return {
      status: data,
    };
  } else {
    throw Error(
      response.statusText || 'Error while fetching files',
    );
  }
};

const getZippedFile = async (filename) => {
  const response = await fetch(
    `${apiUrl}download/zip/${filename}`,
  );

  if (!response.ok) {
    throw Error(
      response.statusText || 'Error while downloading file',
    );
  } else {
    const result = await response.json();
    return result;
  }
};

//Lakitutkta for everyone links:
const getLatests = async () => {
  const response = await fetch(
    `${apiUrl}lausuntokierros/latest`,
  )
  if (!response.ok) {
    throw Error(
      response.statusText || 'Error while downloading file',
    );
  } else {
    const result = await response.json();
    return result;
  }
}
const getKeywords = async (lang) => {
  if (lang === "fi"){
    const response = await fetch(
      `${apiUrl}hallituksen_esitykset/keywords`,
    )
    if (!response.ok) {
      throw Error(
        response.statusText || 'Error while downloading file',
      );
    } else {
      const result = await response.json();
      return result;
    }
  }
  if (lang === "se"){
    const response = await fetch(
      `${apiUrl}hallituksen_esitykset/keywords?lang=se`,
    )
    if (!response.ok) {
      throw Error(
        response.statusText || 'Error while downloading file',
      );
    } else {
      const result = await response.json();
      return result;
    }
  }
}
const getLatestsHallituksenEsitykset = async () => {
  const response = await fetch(
    `${apiUrl}hallituksen_esitykset/latest`,
  )
  if (!response.ok) {
    throw Error(
      response.statusText || 'Error while downloading file',
    );
  } else {
    const result = await response.json();
    return result;
  }
}
const getOngoing = async () => {
  const response = await fetch(
    `${apiUrl}ongoing`,
  )
  if (!response.ok) {
    throw Error(
      response.statusText || 'Error while downloading file',
    );
  } else {
    const result = await response.json();
    return result;
  }
}
const getStatements = async () => {
  const response = await fetch(
    `${apiUrl}statements`,
  )
  if (!response.ok) {
    throw Error(
      response.statusText || 'Error while downloading file',
    );
  } else {
    const result = await response.json();
    return result;
  }
}

export default {
  fetchData,
  postData,
  sendFeedback,
  checkFileStatus,
  getZippedFile,
  getLatests,
  getKeywords,
  getLatestsHallituksenEsitykset,
  getOngoing,
  getStatements,
};
