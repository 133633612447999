import {
  CHANGE_LANG
} from '../actions/actionTypes';

export default (state = 'fi', action) => {
  switch (action.type) {
    case CHANGE_LANG:
      return action.lang;

    default:
      return state;
  }
};
