import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Paper,
  Grid,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import Tip from 'components/Tip';
import {
  initializeFilters,
  initializeFiltersWithDefaultValue,
} from 'utils/commonTools';
import { translateKeyword, translation } from 'utils/translate';
import config from 'config';
import './PreFilters.css';
import texts from 'texts';
import { withStyles } from '@material-ui/core/styles';
import SearchForm from 'components/SearchForm';
import { setSearchFormFullScreen } from 'actions/searchFormFullScreenActions';
import queryString from 'query-string';


const StyledButton = withStyles({
  root: {
    backgroundColor: 'var(--primary)',
    borderRadius: 5,
    border: 0,
    height: 48,
    '&:hover': {
      backgroundColor: 'var(--primary-dark)',
    },
  },
  label: {
    textTransform: 'none',
    color: 'white',
    fontSize: '16px',
  },
})(Button);
const StyledInverseButton =  withStyles({
  root: {
    backgroundColor: 'white',
    borderRadius: 5,
    border: "3px solid var(--primary)",
    height: 48,
    '&:hover': {
      backgroundColor: 'var(--grey-dark)',
    },
  },
  
  label: {
    textTransform: 'none',
    color: 'var(--primary)',
    fontSize: '16px',
  },
})(Button);
const LinkLookButton = withStyles({
  root: {
    backgroundColor: 'white',
    borderRadius: 0,
    border: "white",
    height: 48,
    color: 'var(--primary)',
    textDecoration: "underline",
    textDecorationThickness: 2,
    '&:hover': {
      backgroundColor: 'white',
      textDecoration: "underline",
      textDecorationThickness: 1,
    },
  },
  
  label: {
    textTransform: 'none',
    color: 'var(--primary)',
    fontSize: '16px',
  },
})(Button);


/* PREFILTERS COMPONENT */

const PreFilters = ({
  docGroup,
  handlePreFiltersSubmit,
  handlePreFiltersClear,
  isPrefilterOpen,
  handlePrefilterDockGroupChange,
}) => {
  const facetConstants = useSelector(
    (store) => store.facetConstants,
  );

  const lang = useSelector((store) => store.lang);
  const [selectedDocGroup, setSelectedDocGroup] = useState(docGroup)

  // initialize docGroup-specific constants
  const constants = useMemo(
    () => facetConstants[selectedDocGroup] || {},
    [selectedDocGroup, facetConstants],
  );
  const ymin = constants.min_year || 1970;
  const ymax = constants.max_year || 1970;
  // Generate array of years from ymin to ymax
  const years = [...Array(ymax - ymin + 1)].map(
    (_, i) => i + ymin,
  );
  const [multiSelectFields, setMultiSelectFields] = useState(docGroup?config.docGroups[docGroup].multiSelectFields :config.docGroup["all"].multiSelectFields);
  const [radioSelectFields, setRadioSelectFields] = useState(docGroup?config.docGroups[docGroup].radioSelectFields :config.docGroup["all"].radioSelectFields);

  const [preFiltersInput, setPreFiltersInput] = useState();

  // Generate initial empty values/parsed values from url object state for preFilters.
 useMemo(() => {
    if (window.location.search.includes("prefilters")) {
      const values = queryString.parse(window.location.search);
      const init = {
        ...initializeFilters(multiSelectFields),
        ...initializeFiltersWithDefaultValue(
          radioSelectFields.map((field) => field.id),
          '',
        ),
        term: '',
        year_start: '',
        year_end: '',
        tunnus: '',
      }
      if (values.prefilters) {
        try {        
          const parsedPreFilters = JSON.parse(
            values.prefilters
          );
          const prefiledInit = {...init, ...parsedPreFilters}
          setPreFiltersInput(prefiledInit)
          return prefiledInit
        }
        catch (error) {
          // eslint-disable-next-line no-console
          console.warn(error);
        }
      }
    }
    else{
        const init = {
        ...initializeFilters(multiSelectFields),
        ...initializeFiltersWithDefaultValue(
          radioSelectFields.map((field) => field.id),
          '',
        ),
        term: '',
        year_start: '',
        year_end: '',
        tunnus: '',
      }
      setPreFiltersInput(init)
      return init
    }
    },[multiSelectFields, radioSelectFields,]
  )
  const [isOpen, setIsOpen] = useState(isPrefilterOpen);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handlePreFiltersChange = (event) => {
    setPreFiltersInput({
      ...preFiltersInput,
      ...{ [event.target.name]: event.target.value },
    });
  };

  const handleClear = () => {
    handlePreFiltersClear();
    const init = {
      ...initializeFilters(multiSelectFields),
      ...initializeFiltersWithDefaultValue(
        radioSelectFields.map((field) => field.id),
        '',
      ),
      term: '',
      year_start: '',
      year_end: '',
      tunnus: '',
    }
    setPreFiltersInput(init)
    return init
  };

  // const removeChipFromPreFilters = (val, field) => {
  //   setPreFiltersInput({
  //     ...preFiltersInput,
  //     ...{
  //       [field]: preFiltersInput[field].filter(
  //         (item) => item !== val,
  //       ),
  //     },
  //   });
  // };
  const handleDocGroupSelectOnClick =  async (event) => {
    docGroup = event.target.value
    setSelectedDocGroup(event.target.value)
    setMultiSelectFields(config.docGroups[docGroup].multiSelectFields)
    setRadioSelectFields(config.docGroups[docGroup].radioSelectFields)
    handlePrefilterDockGroupChange(event)
  }

  const handlePreFiltersSubmits = (event, preFiltersInput) => {
      handlePreFiltersSubmit(event, preFiltersInput, selectedDocGroup)
      setSearchFormFullScreen(true)
      setIsOpen(false)
    }

  return (
    <div className="prefilters-wrapper">
      {isOpen &&
        <div className="prefilters-form-container prefilters-form-container--open">
          <Paper elevation={0} className="prefilters-form">
            <Grid container spacing={4}>

              {/* PREFILTER MULTISELECTS */}
              <Grid item xs={12} sm={8} md={6}>
                  <h4 className='doctype-label'>
                    {translateKeyword("type", lang)}
                    <Tip title={translation("asiakirjatyyppi_tooltip", lang)}>
                      <span alt={translation("asiakirjatyyppi_tooltip", lang)} className="tooltip-help tippable"></span>
                    </Tip>
                  </h4>
                  <Select
                    className="docgroup-select"
                    value={selectedDocGroup}
                    onChange={handleDocGroupSelectOnClick}
                  >
                    {Object.entries(config.docGroups).map(
                      ([key, value]) =>
                        value.searchable && (
                          <MenuItem value={key} key={key}>
                            {translation(value.name, lang)}
                          </MenuItem>
                        ),
                    )}
                    </Select>
                    {selectedDocGroup !== "all" &&
                      <div className='docgroup-info-div'>
                        <div>
                          {texts.contentDescription[selectedDocGroup].text[lang]}
                        </div>
                        <div className='docgroup-info-source'>
                          {texts.contentDescription[selectedDocGroup].source[lang]}
                        </div>
                      </div>
                    }
              {preFiltersInput &&
              <>
              <div className='multiselect-div'>
                {multiSelectFields.map((field) => (
                  <>
                  <h4  className="search-term-title">
                    {translateKeyword(field, lang)}
                  </h4>
                  <FormControl
                    variant="outlined"
                    className="prefilters__form-control"
                    key={field}
                  >
                    <InputLabel labelid = {"preFilter-" + field}>
                      {translation("valitse", lang) + (translateKeyword(field, lang)).toLowerCase()}
                    </InputLabel>
                    <Select
                      multiple
                      labelid={"preFilter-" + field}
                      label={translation("valitse", lang) + translateKeyword(field, lang).toLowerCase()}
                      value={preFiltersInput[field]}
                      name={field}
                      onChange={handlePreFiltersChange}
                    >
                      {Object.keys(constants[field]).map(
                        (val) => (
                          <MenuItem key={val} value={val}>
                            {/* {val} */}
                            {val !== ''? (translation(val, lang)?translation(val, lang): val): null} 
                          </MenuItem>
                        ),
                      )}
                    </Select>
                  </FormControl>
                  </>
                ))}
              </div>
              {/* PREFILTER RADIOBUTTON */}
              {radioSelectFields.map((radioField) => (
                <div>
                  <FormControl
                    className="prefilters__form-control"
                    key={radioField.id}
                  >
                      <h4  className="search-term-title">
                        {translation("prefilter", radioField.name, "name", lang)}
                        <Tip title={translation("prefilter", radioField.name, "tooltip", lang)}>
                          <span alt={texts.info.help} className="tooltip-help tippable"></span>
                        </Tip>
                      </h4>
                    
                    <RadioGroup
                      aria-label={radioField.name}
                      value={preFiltersInput[radioField.id]}
                      name={radioField.id}
                      onChange={handlePreFiltersChange}
                    >
                      <FormControlLabel
                        value=""
                        control={<Radio />}
                        label={translation("prefilter", "no_filters", lang)}
                        key="Ei rajausta"
                      />
                      {radioField.fields.map((field) => (
                        <FormControlLabel
                          value={field}
                          control={<Radio />}
                          label={translation("prefilter", radioField.name, field, lang)}
                          key={field + radioField.name}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </div>
              ))}
              

              {/* <Grid item xs={12} sm={10} md={8}> */}
                {/* PREFILTER START YEAR */}
                <div className='years-div'>
                  <div className="start-year-div">
                    <h4 className="year-title">
                        {translation("prefilter", "from_year", lang)}
                    </h4>
                    <FormControl 
                      className="prefilters__form-control--50"
                      variant="outlined"
                    >
                      <InputLabel
                        // htmlFor="year_start"
                        // shrink={!!preFiltersInput?.year_start}
                        id = "valitse-vuosi-start"
                      >
                        {translation("valitse_vuosi", lang)}
                      </InputLabel>
                      <Select
                        labelid="valitse-vuosi-start"
                        label="Valitse vuosi"
                        value={preFiltersInput?.year_start}
                        // className="prefilters-form__input prefilters-form__rangeselect"
                        onChange={handlePreFiltersChange}
                        inputProps={{
                          name: 'year_start',
                          id: 'year_start',
                        }}
                      >
                        <MenuItem value="">
                          <i>{translation("prefilter", "not_selected", lang)}</i>
                        </MenuItem>
                        {years
                          .filter(
                            (val) =>
                              val <= preFiltersInput?.year_end ||
                              !preFiltersInput?.year_end,
                          )
                          .map((val) => (
                            <MenuItem key={val} value={val}>
                              {val}
                            </MenuItem>
                          ))}
                        {years
                          .filter(
                            (val) =>
                              preFiltersInput?.year_end &&
                              val > preFiltersInput?.year_end,
                          )
                          .map((val) => (
                            <MenuItem
                              key={val}
                              value={val}
                              disabled
                            >
                              {val}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    </div>

                {/* PREFILTER END YEAR */}
                <div className="start-year-div">
                  <h4 className = "year-title">
                      {translation("prefilter", "to_year", lang)}
                  </h4>
                  <FormControl 
                    className="prefilters__form-control--50"
                    variant="outlined"
                  >
                    <InputLabel
                      // htmlFor="year_end"
                      // shrink={!!preFiltersInput?.year_end}
                      id="valitse-vuosi-end"
                    >
                      {translation("valitse_vuosi", lang)}
                    </InputLabel>
                    <Select
                      labelid="valitse-vuosi-end"
                      label="Valitse vuosi"
                      value={preFiltersInput?.year_end}
                      className="prefilters-form__input prefilters-form__rangeselect"
                      onChange={handlePreFiltersChange}
                      inputProps={{
                        name: 'year_end',
                        id: 'year_end',
                      }}
                    >
                      <MenuItem value="">
                        <i>{translation("prefilter", "not_selected", lang)}</i>
                      </MenuItem>
                      {years
                        .filter(
                          (val) =>
                            preFiltersInput?.year_start &&
                            val < preFiltersInput?.year_start,
                        )
                        .map((val) => (
                          <MenuItem
                            key={val}
                            value={val}
                            disabled
                          >
                            {val}
                          </MenuItem>
                        ))}
                      {years
                        .filter(
                          (val) =>
                            val >=
                            preFiltersInput?.year_start ||
                            !preFiltersInput?.year_start,
                        )
                        .map((val) => (
                          <MenuItem key={val} value={val}>
                            {val}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  </div>
                </div>

                {/* PREFILTER SEARCH TERM */}
                <h4 className="search-term-title">
                  {translation("prefilter", "search_term", lang)}
                </h4>
                <FormControl 
                  className="prefilters__form-control"
                  variant="outlined"
                >
                  <TextField
                    id="prefilters-term"
                    label={translation("prefilter", "search_term", lang)}
                    className="prefilters-form__input prefilters-form__searchterm"
                    name="term"
                    value={preFiltersInput?.term}
                    onChange={handlePreFiltersChange}
                    variant='outlined'
                  />
                </FormControl>
             

              {/* PREFILTER-TUNNUS */}
                <h4 className="search-term-title">
                  {translation("prefilter", "diaarinumerot", lang)}
                </h4>
                <FormControl className="prefilters__form-control">
                  <TextField
                    id="prefilters-tunnus"
                    label={translation("prefilter", "diaarinumerot", lang)}
                    name="tunnus"
                    multiline
                    maxrows="5"
                    className="prefilters-form__input prefilters-form__tunnus"
                    value={preFiltersInput?.tunnus}
                    onChange={handlePreFiltersChange}
                    variant='outlined'
                  />
                </FormControl>
              

              {/* PREFILTER SUBMIT/RESET */}
              <div className = "buttons-div">
                <StyledButton
                  variant="contained"
                  className="prefilters-button"
                  onClick={(e) =>
                    handlePreFiltersSubmits(
                      e,
                      preFiltersInput,
                    )
                  }
                >
                  {translation("prefilter", "name", lang)}
                </StyledButton>
                <StyledInverseButton
                  className="reset-prefilters"
                  onClick={handleClear}
                >
                  {translation("prefilter", "remove", lang)}
                </StyledInverseButton>
                </div>
              </>}
              </Grid>
            </Grid>
          </Paper>
        </div>
      // )
      }
      {!isOpen &&
      <>
        <SearchForm docGroup={selectedDocGroup} />
        <div className="prefilters-button-container">
          <Tip title={translation("prefilter", "tooltip", lang)}>
            <LinkLookButton
              id="prefilters-toggler"
              className="prefilters-toggler"
              disableTouchRipple
              onClick={() => toggleOpen()}
            >
              <EditIcon className="button-icon" />
              {translation("prefilter", "button", lang)}
            </LinkLookButton>
          </Tip>
        </div>
        </>
      }
    </div>
  );
};

PreFilters.propTypes = {
  docGroup: PropTypes.string.isRequired,
  handlePreFiltersSubmit: PropTypes.func.isRequired,
  handlePreFiltersClear: PropTypes.func.isRequired,
};

export default PreFilters;
