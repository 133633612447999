import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { translateKeyword } from 'utils/translate';
import Tip from 'components/Tip';
import './TimeLineList.css';
import {
  cropIfLonger,
  formatDate,
  getDocumentUrl,
  getLawUrl,
  isTimestamp,
} from 'utils/commonTools';
import texts from 'texts';
import { useSelector } from 'react-redux';
import { translation } from 'utils/translate';

const TimeLineList = ({
  isOpen,
  toggle,
  onItemClick,
  data,
  docType,
  idField,
  labelField,
  listFields,
}) => {

  const lang = useSelector((store) => store.lang)

  return (
    <>
      <Tip title={texts.tooltip.timeline[docType]}>
        <button
          type="button"
          className={
            data.length
              ? 'timeline__section-title'
              : 'timeline__section-title timeline__section-title--empty'
          }
          onClick={toggle}
        >
          {isOpen ? (
            <ArrowDownIcon className="timeline__arrow-icon" />
          ) : (
            <ArrowRightIcon className="timeline__arrow-icon" />
          )}

          <>
            {docType === 'lausuntokierros' ? translation("doc_groups", docType, "optionalName", lang) :
                  translation("doc_groups", docType, "name", lang)}
            <span className="timeline__doc-count">
              {` (${data.length})`}
            </span>
          </>
        </button>
      </Tip>

      {isOpen && (
        <div className="timeline__list-wrapper">
          {data.map((item) => (
            <Fragment key={item[idField]}>
              <div className="timeline__listitem--header">
                {docType === 'laws' ? (
                  <a
                    href={getLawUrl(item[labelField])}
                    target="_BLANK"
                    rel="noopener noreferrer"
                  >
                    {item[labelField]}
                  </a>
                ) : (
                  <Link
                    to={getDocumentUrl(
                      docType,
                      item[idField],
                    )}
                    onClick={onItemClick}
                  >
                    {item[labelField]}
                  </Link>
                )}
              </div>

              {listFields
                .filter((field) => item[field])
                .map((field) => (
                  <div
                    className="timeline__field"
                    key={field}
                  >
                    {field !== 'title' && (
                      <div className="timeline__field-label">
                        {translateKeyword(field, lang)}
                      </div>
                    )}
                    {isTimestamp(item[field])
                      ? formatDate(item[field])
                      : cropIfLonger(
                        translateKeyword([item[field]], lang),
                      )}
                  </div>
                ))}
            </Fragment>
          ))}
        </div>
      )}
    </>
  );
};

TimeLineList.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onItemClick: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  docType: PropTypes.string.isRequired,
  idField: PropTypes.string.isRequired,
  labelField: PropTypes.string.isRequired,
  listFields: PropTypes.arrayOf(PropTypes.string)
    .isRequired,
};

export default TimeLineList;
