import theme from 'theme';

// tools for hsl(a) color value generation
const [hue, sat, lig] = theme.palette.primary.light
  .match(/\d+/g)
  .map((item) => parseInt(item, 10));

const opa = 40;
const hueShift = -35;
//const hueShift = -32;

export const hsl = (h, s, l) => `hsl(${h}, ${s}%, ${l}%)`;
export const hsla = (h, s, l, a) =>
  `hsla(${h}, ${s}%, ${l}%, ${a}%)`;

const createColorVariant = ({ num, transparent }) => {
  const customHue = num ? hue + hueShift * num : hue;
  return transparent
    ? hsla(customHue, sat, lig, opa)
    : hsl(customHue, sat, lig);
};

// Generate hsl color hue variants
// colorVariant(0) is primary color light
// and colorVariant(n) is n:th variant
// To be used in sequence ie. 0, 1, 2...
export const colorVariant = (num) =>
  createColorVariant({ num });

// Generate transparent hsl color hue variants
// as above but with 40% opacity
export const colorVariantTp = (num) =>
  createColorVariant({ num, transparent: true });
