import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      light: 'hsl(202, 67%, 44%)',
      main: 'hsl(202, 67%, 36%)',
      dark: 'hsl(202, 67%, 28%)',
      contrastText: '#fff',
    },
    secondary: {
      light: 'hsl(0, 100%, 0%)', //'hsl(122, 67%, 44%)',
      main: 'hsl(0, 100%, 0%)',
      dark: 'hsl(0, 100%, 0%)',
      contrastText: '#fff',
    },
    background: {
      paper: '#fff',
      default: 'white',
    },
  },
});

export default theme;
