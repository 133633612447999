import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Paper, Button } from '@material-ui/core';
import Block from 'components/Block';
import ShowMore from 'components/ShowMore';
import IconButton from '@material-ui/core/IconButton';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import {
  sortByKey,
  capitalizeFirstLetter,
} from 'utils/commonTools';
import './ListBlock.css';
import { translation } from 'utils/translate';
import { useSelector } from 'react-redux';

/* LIST BLOCK COMPONENT */

const ListBlock = ({ title, data, anchored, nth }) => {
  const defDirections = { word: 'asc', count: 'desc' };
  const topRef = useRef();

  const [sortField, setSortField] = useState('count');
  const [sortDirection, setSortDirection] =
    useState('desc');
  const [isOpen, setIsOpen] = useState(false);
  const [anchor, setAnchor] = useState(false);
  const [searchWordIndex, setSearchWordIndex] = useState(
    {},
  );

  const lang = useSelector((store) => store.lang)

  // scroll to top of the facet block
  const scrollToTop = () => {
    window.scrollTo({
      top: topRef.current.offsetTop - 20,
      behavior: 'smooth',
    });
  };

  // toggle 'show more/less'
  const toggleOpen = () => {
    setIsOpen(!isOpen);
    scrollToTop();
  };

  const handleSortingChange = (newType) => {
    if (sortField !== newType) {
      setSortField(newType);
      setSortDirection(defDirections[newType]);
    } else {
      setSortDirection(
        sortDirection === 'asc' ? 'desc' : 'asc',
      );
    }
  };

  const renderList = anchor
    ? sortByKey([...data], sortField, sortDirection).slice(
        0,
        20, // Show max 20 values on anchored element (because it aint scrollable)
      )
    : isOpen
    ? sortByKey([...data], sortField, sortDirection)
    : sortByKey([...data], sortField, sortDirection).slice(
        0,
        5,
      );

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (
        window.innerWidth > 959 &&
        window.scrollY > 2000
      ) {
        setAnchor(true);
      } else {
        setAnchor(false);
      }
    });
  }, [anchor, setAnchor, isOpen]);

  const jumpToNextWord = (el, inverse) => {
    let nextHit;
    let word;

    if (typeof el !== 'string') {
      word = el.target.innerText;
    } else {
      word = el;
    }
    if (word) {
      word = word.toLowerCase();
    } else {
      return;
    }

    const currentCount = searchWordIndex[word] || 0;

    let ems = document.querySelectorAll(
      '#document em.highlight',
    );
    if (ems) {
      ems = Array.from(ems).filter(
        (w) => w.innerText.toLowerCase() === word,
      );

      if (inverse) {
        nextHit = ems[(currentCount - 2) % ems.length];
      } else {
        nextHit = ems[currentCount % ems.length];
      }
      if (nextHit) {
        nextHit.scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'center',
        });
      }
    }

    if (inverse) {
      setSearchWordIndex({
        ...searchWordIndex,
        [word]: currentCount - 1,
      });
    } else {
      setSearchWordIndex({
        ...searchWordIndex,
        [word]: currentCount + 1,
      });
    }
  };

  return (
    <Paper
      elevation={0}
      className={
        anchored && anchor
          ? `anchored anchored--${nth}`
          : null
      }
    >
      <Block className="block--facet listblock--bordered" ref={topRef}>
        <div className="facet-header-wrapper">
          <button
            type="button"
            className="facet-header facet-header--primary"
            onClick={() => handleSortingChange('word')}
          >
            <h4>{title}</h4>
          </button>
          <button
            type="button"
            className="facet-header facet-header--secondary"
            onClick={() => handleSortingChange('count')}
          >
            kpl
          </button>
        </div>

        <div className="listblock--items">
          {renderList.map(({ word, count }) => (
            <div
              key={word}
              className="listblock--item-container"
            >
              <Button
                variant="text"
                size="small"
                className="listblock--item-key"
                onClick={(el) => jumpToNextWord(el, false)}
              >
                {capitalizeFirstLetter(word)}
              </Button>
              {word in searchWordIndex &&
              searchWordIndex[word] > 1 ? (
                <IconButton
                  className="listblock--item-key"
                  onClick={() => jumpToNextWord(word, true)}
                >
                  <ArrowUpward className="listblock--backward-search-icon" />
                </IconButton>
              ) : null}
              <div className="listblock--item-value">
                {count}
              </div>
            </div>
          ))}
        </div>

        {data.length > 5 && !anchor && (
          <ShowMore
            isOpen={isOpen}
            toggleFunction={toggleOpen}
            moreLabel={`${translation("facets", "show_all", lang)} (${data.length})`}
            lessLabel={translation("facets", "show_less", lang)}
          />
        )}
      </Block>
    </Paper>
  );
};

ListBlock.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  title: PropTypes.string.isRequired,
};

export default ListBlock;
