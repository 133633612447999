import React, {useState, useRef, useEffect} from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { changeLanguage } from 'actions/langActions';
import { setSearchFormFullScreen } from 'actions/searchFormFullScreenActions';
import {
  Button,
  Grid
} from '@material-ui/core';

import config from 'config';
import './SiteHeader.css';
import { Typography, makeStyles } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { changeSearchState } from 'actions/searchStateActions';
import { translation } from 'utils/translate';
import {
  Hidden,
  FormControl,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import {setSearchSubmited} from 'actions/searchSubmitedActions';
//import searchFormFullScreenReducer from 'reducers/searchFormFullScreenReducer';

/* MAIN PAGE HEADER COMPONENT */

const useStyles = makeStyles((theme) => ({
  siteNameArea:{
    margin: 0,
    textAlign: 'left',
    display: 'flex',
    maxWidth: 1480,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  siteNameLeft: {
    flex: 1,
    textAlign: 'left',
    marginLeft: '1rem',
  },
  mobileMenuRight:{
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent:"flex-end",
    marginLeft: 50,
    maxWidth:100
  },
  menuIcon:{
    fontSize: 50,
  },
  siteNameRight: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent:"right",
    textTransform: 'none',
  },
  siteName: {
    fontSize: '2.8125rem',
    display: 'inlineBlock',
    textTransform: 'uppercase',
    letterSpacing: '0.05em',
    fontWeight: 200,
    marginBottom: '-8px',
  },
  siteSlogan: {
    fontSize: '1rem',
    marginTop: '0.6rem',
    fontWeight: 'normal',
    color: '#313d46',
  },
  linkToFront:{
    textDecoration: 'none',
    color: '#313d46',
  },
  siteNameSeperator: {
    height: '100%',
    display: 'inlineBlock',
    margin: '0 1em 0 2em',
    borderLeft: 'solid #1e6c99',
  },
  navigationBar:{
    borderTop: '1.5px solid var(--gray-verylight)',
    borderBottom: '1.5px solid var(--gray-verylight)',
    marginTop: 16,
    marginBottom: 16,
    justifyContent: "center",
  },
  siteNameNavigator: {
    verticalAlign: 'top',
    display: 'flex',
    justifyContent: 'center',
    top: 0,
    fontSize: '1rem',
  },
  links:{
    color: 'black', 
    marginLeft: 30,
  },
  mobileLinks:{
    color: 'white',
    marginLeft:20,
  },
  lastLink:{
    color: 'black', 
    marginLeft: 30,
    marginRight: 30,
  },
  language:{
    color: '#2564ec', 
    marginRight: 20,
    fontWeight: 'bold',
    textTransform: 'none',
  },
  selectedLanguage:{
    color: '#2564ec', 
    marginRight: 20,
    textDecoration: 'underline',
    textDecorationThickness: 3,
    fontWeight: 'bold',
    textTransform: 'none',
  },
  mobileLanguage:{
    color: '#2564ec', 
    marginLeft: 20,
    fontWeight: 'bold',
    textTransform: 'none',
    fontSize:22,
  },
  mobileSelectedLanguage:{
    color: '#2564ec', 
    marginLeft: 20,
    textDecoration: 'underline',
    textDecorationThickness: 3,
    fontWeight: 'bold',
    textTransform: 'none',
    fontSize:22,
  },
  modeBoxGrid:{
    fontSize: '14px',
    display: "flex",
    justifyContent: "right",
    marginTop: "1%",
    marginBottom: "1%",
    marginRight: '1rem',
  },
  modeBoxGridInner:{
    maxWidth: 340,
  },
  searchModeBox:{
    border: '1.5px solid gray',
    background: 'rgba(211,211,211,0.3)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '4px',
  },
  mobileSearchModeBox:{
    border: '1.5px solid black',
    background: 'var(--text-color-light)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '4px',
    marginLeft: 20,
    marginRight: 20,
  },
  searchMode:{
    paddingLeft: 5,
    margin: 10,
    fontWeight: 'bold',
  },
  mobileSearchMode:{
    paddingLeft:20,
    fontWeight: 'bold',
    fontSize: 20,
  },
  mobileSearchModeLabel:{
    fontWeight: 'bold',
    fontSize: 18,
  },
  searchModeControl: {
    paddingLeft: 8,
  },
  mobileSearchModeControl: {
    paddingLeft: 20,
  },
  searchModeLabel:{
    fontWeight: 'bold',
    fontSize: '14px',
  }
}))

const SiteHeader = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const searchState = useSelector((store) => store.searchState)

  const history = useHistory()

  const lang = useSelector((store) => store.lang);

  const menuRef = useRef(null);

  const triggerChangeLanguage = (langCode) => {
    toggleMenu()
    const url = new URL(window.location);
    url.searchParams.set("lang", langCode);
    history.push(url.search);
    dispatch(changeLanguage(langCode))
  }


  const handleChange = (event) => {
    dispatch(changeSearchState(event.target.value))
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const setScreen = () => {
    dispatch(setSearchFormFullScreen(false))
  }
  const resetSearchSubmited = () => {
    dispatch(setSearchSubmited(false))
  }

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
     <>
      <Grid container spacing={1} className={classes.siteNameArea}>
        <Grid item xs={6} sm={6} md={4} lg={4} className={classes.siteNameLeft}>
          <div className={classes.siteName}>
            <Link 
              to="/#basic-search-term" 
              onClick={(setScreen, resetSearchSubmited)}
              className={classes.linkToFront}>
              {config.site.name}
            </Link>
          </div>
          <div className={classes.siteSlogan}>
            {translation("slogan", lang)}
          </div> 
        </Grid>
        {/*Mobile menu*/}
        <Hidden mdUp>
          <Grid item xs={5} sm={5} className={classes.mobileMenuRight}>
            <button className="toggler-button" onClick={toggleMenu}>
              <MenuIcon className={classes.menuIcon}/>
            </button>
            {isOpen &&
              <nav ref={menuRef} className={`menu-nav ${isOpen ? 'open' : ''}`}>
                <ul>
                  <Link
                          to="/#basic-search-term"
                          onClick={(toggleMenu, setScreen, resetSearchSubmited)}
                        >
                          <p className = {classes.mobileLinks}>{translation("home", lang)}</p>
                  </Link>
                  <Link
                          to="/tietoja"
                          onClick={toggleMenu}
                        >
                          <p className = {classes.mobileLinks}>{translation("tietoja", lang)}</p>
                  </Link>
                  <Link
                        to="/ohjeet"
                        onClick={toggleMenu}
                      >
                        <p className = {classes.mobileLinks}>{translation("ohjeet", lang)}</p>
                  </Link>
                  <Link
                        to="/tutkijalle"
                        onClick={toggleMenu}
                      >
                        <p className = {classes.mobileLinks} >{translation("tutkijalle", lang)}</p>
                  </Link>
                  <Link
                        to="/viittaaminen"
                        onClick={toggleMenu}
                      >
                        <p className = {classes.mobileLinks} >{translation("viittaaminen", lang)}</p>
                  </Link>
                  <div>
                    <Button onClick={() => triggerChangeLanguage('fi')} className={lang === 'fi' ? classes.mobileSelectedLanguage:classes.mobileLanguage}>Suomi</Button>
                  </div>
                  <div>
                    <Button onClick={() => triggerChangeLanguage('se')} className={lang === 'se' ? classes.mobileSelectedLanguage:classes.mobileLanguage}>Svenska</Button>
                  </div>
                  <p className={classes.mobileSearchMode}>{translation("hakutila",lang)} </p>
                  <div className = {classes.mobileSearchModeBox}>
                    <FormControl component="fieldset">
                      <RadioGroup aria-label="searchMode" name="searchMode1" value={searchState} onClick={toggleMenu} onChange={(event) => handleChange(event)}>
                        <FormControlLabel 
                          value="yleinen" 
                          control={<Radio color = 'secondary' size="small" className={classes.mobileSearchModeControl}/>} 
                          label = {<Typography className={classes.mobileSearchModeLabel}>{translation("yleinen", lang)}</Typography> }
                        />
                        <FormControlLabel
                          value="tutkija" 
                          control={<Radio color = "secondary" size="small" className={classes.mobileSearchModeControl}/>} 
                          label={<Typography className={classes.mobileSearchModeLabel}>{translation("tutkija", lang)}</Typography> }
                        />
                      
                      </RadioGroup>
                    </FormControl>
                  </div>
                </ul>
              </nav>
            }
          </Grid>
        </Hidden>

        <Hidden smDown>
        <Grid item md={4} lg={4} className={classes.siteNameRight}>
          <Button onClick={() => triggerChangeLanguage('fi')} className={lang === 'fi' ? classes.selectedLanguage:classes.language}>Suomi</Button>
          <Button onClick={() => triggerChangeLanguage('se')} className={lang === 'se' ? classes.selectedLanguage:classes.language}>Svenska</Button>
        </Grid>
        <Grid item md={4} lg={4} className={classes.modeBoxGrid}>
          <Grid container className={classes.modeBoxGridInner}>
            <Grid item xs={12} sm={12} md={12} lg={12} className={classes.searchModeBox}>
              <p className={classes.searchMode}>{translation("hakutila",lang)} </p>
              <FormControl component="fieldset">
                <RadioGroup row aria-label="searchMode" name="searchMode1" value={searchState} onChange={(event) => handleChange(event)}>
                  <FormControlLabel 
                    value="yleinen" 
                    control={<Radio color = 'primary' size="small" className={classes.searchModeControl}/>} 
                    label = {<Typography className={classes.searchModeLabel}>{translation("yleinen", lang)}</Typography> }
                  />
                  <FormControlLabel
                    value="tutkija" 
                    control={<Radio color = "primary" size="small" className={classes.searchModeControl}/>} 
                    label={<Typography className={classes.searchModeLabel}>{translation("tutkija", lang)}</Typography> }
                  />
                
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid> 
          </Grid>
        </Hidden>
      </Grid>


      
      <Hidden smDown>
        <Grid container className={classes.navigationBar}>
          <Grid item md={12} lg={12} className={classes.siteNameNavigator}>
            <Link
              to="/#basic-search-term"
              className={
                location.pathname === '/' ? 'selected' : ''
              }
              onClick={(setScreen, resetSearchSubmited)}
            >
              <p className = {classes.links}>{translation("home", lang)}</p>
            </Link>
            <Link
              to="/tietoja"
              className={
                location.pathname === '/tietoja'
                  ? 'selected'
                  : ''
              }
            >
              <p className = {classes.links}>{translation("tietoja", lang)}</p>
            </Link>
          
            <Link
              to="/ohjeet"
              className={
                location.pathname === '/ohjeet'
                  ? 'selected'
                  : ''
              }
            >
              <p className = {classes.links}>{translation("ohjeet", lang)}</p>
            </Link>
            <Link
              to="/tutkijalle"
              className={
                location.pathname === '/tutkijalle'
                  ? 'selected'
                  : ''
              }
            >
              <p className = {classes.links} >{translation("tutkijalle", lang)}</p>
            </Link>
              <Link
                to="/viittaaminen"
                className={
                  location.pathname === '/viittaaminen'
                    ? 'selected'
                    : ''
                }
              >
                <p className = {classes.lastLink} >{translation("viittaaminen", lang)} </p>
              </Link>
          </Grid>
        </Grid>
      </Hidden>
      
          {/* <Section
            className="section--select-area section--select-area--mobile"
            role="navigation"
          >
            <h3
              className="document-type-mobile-select-label"
              id="mobiselect-label"
            >
              {translation("mobile_document_group", lang)}
            </h3>
            <FormControl
              variant="filled"
              fullWidth
              className="document-type-mobile-select"
            >
              <Select
                labelId="mobiselect-label"
                fullWidth
                value={mobileMenu}
                onChange={(event) =>
                  selectMenu(event.target.value)
                }
              >
                {Object.entries(mobileMenu).map(
                  ([key, value]) =>
                   (
                      <MenuItem value={key} key={key}>
                        {translation(key, lang)}
                      </MenuItem>
                    ),
                )}
              </Select>
            </FormControl>
          </Section> */}
        
    </>
  );
};

export default SiteHeader;
