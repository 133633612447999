import { SET_SEARCH_STATE } from "../actions/actionTypes";

export default (state = 'yleinen', action) => {
    switch (action.type) {
      case SET_SEARCH_STATE:
        return action.state;
  
      default:
        return state;
    }
  };