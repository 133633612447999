import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IconButton, Snackbar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import { clearError } from 'actions/errorActions';
import './ErrorBar.css';

const ErrorBar = () => {
  const dispatch = useDispatch();
  const error = useSelector((store) => store.error);

  const [errorText, setErrorText] = useState(null)
  const [errorDescription, setErrorDescription] = useState(null)

  useEffect(() => {
    if (error) {
      if (typeof error === 'string') {
        switch (error) {
          case 'background_job_timeout':
            setErrorText('Tausta-ajo on aikakatkaistu liian pitkän keston vuoksi. Kokeile koostaa ladattavat tiedostot pienemmissä erissä.')
            break;
          case 'service_unavailable':
            setErrorText('Palvelukatkos. Yritä myöhemmin uudelleen.')
            break;
          default:
            setErrorText('Tietokantaan ei saada yhteyttä. Ylläpitoa on tiedotettu häiriöstä. Korjaamme vian mahdollisimman nopeasti. Yritä myöhemmin uudelleen.')
        }
      }

      if (error && typeof error === 'object') {
        switch (error.message) {
          case 'elasticsearch_down':
            setErrorText('Hakumoottoriin ei saada yhteyttä. Ylläpitoa on tiedotettu häiriöstä. Korjaamme vian mahdollisimman nopeasti. Yritä myöhemmin uudelleen.')
            break;
          default:

            if (error.cause) {
              setErrorText(error.message)
              setErrorDescription(error.cause)
            } else {
              setErrorDescription("Internal server error (palvelukatkos, pahoittelut häiriöstä)")
            }
        }

      }
    }

  }, [error]);

  const onClose = () => dispatch(clearError());

  return (
    <Snackbar open={!!error}>
      <div className="errorbar">
        <ErrorIcon />
        <div className="errorbar--text" aria-live="polite">
          {errorText}
          <br />
          {errorDescription}
        </div>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </div>
    </Snackbar>
  );
};

export default ErrorBar;
