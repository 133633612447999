import React from 'react';
import PropTypes from 'prop-types';
import './Block.css';

const Block = React.forwardRef(
  ({ title, className, children }, ref) => (
    <div
      className={className ? `block ${className}` : 'block'}
      ref={ref}
    >
      {title && <h3 className="block--title">{title}</h3>}
      {children}
    </div>
  ),
);

Block.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Block.defaultProps = {
  className: null,
  title: null,
};

export default Block;
