import React from 'react';
import PropTypes from 'prop-types';
import './InfoBox.css';

const InfoBox = ({ className, children }) => (
  <div
    className={
      className ? `infobox ${className}` : 'infobox'
    }
  >
    {children}
  </div>
);

InfoBox.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

InfoBox.defaultProps = {
  className: null,
};

export default InfoBox;
