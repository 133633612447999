import React, { useState, useEffect } from 'react';
import {
  Route,
  Switch,
  BrowserRouter,
  Redirect
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import Home from 'components/Home';
import Document from 'components/Document';
import Instructions from 'components/Instructions';
import FeedbackForm from 'components/FeedbackForm';
import ErrorBar from 'components/ErrorBar';
import Section from 'components/Section';
import SiteHeader from 'components/SiteHeader';
import Footer from 'components/Footer';
import PrivacyPolicy from 'components/PrivacyPolicy';
import Accessibility from 'components/Accessibility';
import SiteInfo from 'components/SiteInfo';
import ForResearcher from 'components/ForResearcher';
import Referencing from 'components/Referencing';
import Dictionary from 'components/Dictionary';
import { getFacetConstants } from 'actions/facetConstantsActions';
import { reportError } from 'actions/errorActions';
import { changeLanguage } from 'actions/langActions';

const App = () => {
  const dispatch = useDispatch();

  const [constantsLoaded, setConstantsLoaded] = useState(
    false,
  );

  useEffect(() => {

    // Matomo Tag manager:
    var _mtm = window._mtm = window._mtm || [];
    _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; 
    g.src='https://mtm.utu.fi/js/container_IEjyxHoY.js'; 
    if (s){
      s.parentNode.insertBefore(g,s);
    }
    
    // functions to help prevent focus ring when using mouse
    document.body.addEventListener('mousedown', () => {
      document.body.classList.add('using-mouse');
    });
    document.body.addEventListener('keydown', () => {
      document.body.classList.remove('using-mouse');
    });

    const params = new URLSearchParams(
      window.location.search,
    );
    const lang = params.get(' lang ')
    dispatch(changeLanguage(lang ? lang : 'fi'));

    // fetch facetConstants to Redux
    const fetchConstants = async () => {
      try {
        await dispatch(getFacetConstants());
        setConstantsLoaded(true);
      } catch (err) {
        dispatch(reportError(err));
        setConstantsLoaded(true);
      }
    };
    fetchConstants();
  }, [dispatch]);

  return (
    <div className="App" data-testid="app">
      <CssBaseline />

      {constantsLoaded && (
        <BrowserRouter>
          <Section
            className="section--header"
            role="banner"
          >

          </Section>
          <SiteHeader />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route
              path="/haku/:docGroup"
              component={Home}
            />
            {/* Redirect for legacy link support */}
            <Redirect
              from="/asiakirjat/lausuntokierros-lausunnot/:doc_id"
              to="/asiakirjat/lausuntokierros-asiakirjat/:doc_id"
            />
            <Redirect
              from="/asiakirjat/lausuntokierros-muut/:doc_id"
              to="/asiakirjat/lausuntokierros-asiakirjat/:doc_id"
            />
            <Route
              path="/asiakirjat/:doc_group/:doc_id"
              component={Document}
            />
            <Route
              path="/ohjeet"
              component={Instructions}
            />
            <Route
              path="/palaute"
              component={FeedbackForm}
            />
            <Route
              path="/tietosuoja"
              component={PrivacyPolicy}
            />
            <Route
              path="/saavutettavuus"
              component={Accessibility}
            />
            <Route path="/tietoja" component={SiteInfo} />
            <Route
              path="/tutkijalle"
              component={ForResearcher}
            />
            <Route
              path="/viittaaminen"
              component={Referencing}
            />
            <Route
              path="/dictionary"
              component={Dictionary}
            />
          </Switch>
          <Footer />
        </BrowserRouter>
      )}

      <ErrorBar />
    </div>
  );
};

export default App;
