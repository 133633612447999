import { SET_FACET_CONSTANTS } from '../actions/actionTypes';

export default (state = [], action) => {
  switch (action.type) {
    case SET_FACET_CONSTANTS:
      return action.payload;

    default:
      return state;
  }
};
