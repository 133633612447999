import React from 'react';
import PropTypes from 'prop-types';
import { translateKeyword } from 'utils/translate';
import { isTimestamp, formatDate } from 'utils/commonTools';
import './DocInfo.css';
import { useSelector } from 'react-redux';

/* COMPONENT FOR DOCUMENT INFO FIELDS */

const DocInfo = ({ className, fields, idx }) => {

  const lang = useSelector((store) => store.lang)

  return (
    <div
      key={"doc-info--1"}
      className={
        className ? `doc-info ${className}` : 'doc-info'
      }
    >
      {fields.map(
        (field) =>
          !!(field[0] && field[1]) && (
            <React.Fragment key={field[0] + field[1] + idx}>
              <span
                className="info-field--key"
                key={field[0] + idx}
              >
                {translateKeyword(field[0], lang)}:&nbsp;
              </span>
              <span
                className="info-field info-field--value"
                key={field[1] + idx}
              >
                {isTimestamp(field[1])
                  ? formatDate(field[1])
                  : field[1]}
              </span>
            </React.Fragment>
          ),
      )}
    </div>
  )
};

DocInfo.propTypes = {
  className: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.any),
};

DocInfo.defaultProps = {
  className: null,
};

export default DocInfo;
