/* The string to array buffer method 
Ref: https://github.com/SheetJS/js-xlsx/blob/master/README.md) */
const stringToarrayBuffer = (str) => {
  const buffer = new ArrayBuffer(str.length);
  const view = new Uint8Array(buffer);
  for (let i = 0; i < str.length; ++i) {
    view[i] = str.charCodeAt(i) & 0xff;
  }
  return buffer;
};

// Create a file-like Blob object for streaming
const createBlob = (data, format) => {
  let blob;
  if (format === 'csv') {
    blob = new Blob([data], {
      type: 'octet/stream',
    });
  } else if (format === 'xlsx') {
    // Excel data file needs to be converted to an ArrayBuffer
    const byteArray = stringToarrayBuffer(atob(data));

    blob = new Blob([byteArray], {
      type:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
  }
  return blob;
};

// eslint-disable-next-line import/prefer-default-export
export const streamFileToUser = ({
  data,
  format,
  fileName,
}) => {
  const blob = createBlob(data, format);
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style = 'display: none';
  a.href = url;
  a.download = `${fileName}.${format}`;
  a.click();
  window.URL.revokeObjectURL(url);
};
