import React from 'react';
import PropTypes from 'prop-types';
import './Section.css';

/* SECTION COMPONENT */

const Section = ({ className, children }) => (
  <div
    className={
      className ? `section ${className}` : 'section'
    }
  >
    <div
      className={
        className
          ? `section__inner ${className
              .split(' ')
              .map((c) => ` ${c}__inner`)}`
          : 'section__inner'
      }
    >
      {children}
    </div>
  </div>
);

Section.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

Section.defaultProps = {
  className: null,
  children: null,
};

export default Section;
