import { useState } from 'react';

function useToggler(initialState = false) {
  const [state, setState] = useState(initialState);

  const toggle = () => setState(!state);
  const on = () => setState(true);
  const off = () => setState(false);

  return {
    state,
    toggle,
    on,
    off,
  };
}

export default useToggler;
