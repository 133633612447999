import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, makeStyles } from '@material-ui/core';
import './Footer.css';
import { translation } from 'utils/translate';
import { useSelector } from 'react-redux';


const useStyles = makeStyles((theme) => ({
  sectionFooter:{
    marginTop: "10rem",
    padding: "2rem",
    fontWeight: "bold",
    backgroundColor: "#F4F5F5",
  },
  footerContainer: {
    lineHeight: "1.8rem",
    // marginTop: "10rem",
    // padding: "2rem",
    // fontWeight: "bold",
    // backgroundColor: "#F4F5F5",
    // display:"flex",
    //justifyContent:"center",
    margin:"auto",
    maxWidth: "1480px",
    //border: "2px solid red"

    // a: {
    //   textDecoration: "underline",
    //   color: "black",
    // }
  },
  turunYliopisto:{
    //marginLeft: "auto",
    marginRight: 10,
    //border:"2px solid green"
  },
  info:{
    //border: "2px solid red"
  },
  footerLink: {
    display: "block",
    color: "black",
    textDecoration: "underline",
    //border:"2px solid blue"
  },
  //twitterLink,
  // blogLink: {
  //   padding: "0.5em",
  // },
  twitterGrid:{
   // border: "2px solid yellow"
  },
  twitterLogo:{
    width: "2em",
    marginRight: "1.5em" 
  },
  blogLogo: {
    width: "2em",
  }
}))

const Footer = () => {
  const lang = useSelector(
    (store) => store.lang,
  );
  const classes = useStyles();

  return (
    <div className={classes.sectionFooter}>
      <Grid container className={classes.footerContainer}>
        <Grid item xs={12} sm={12} md={3} lg={3} className={classes.turunYliopisto}>
          <a
            href="https://www.utu.fi"
            target="_BLANK"
            rel="noopener noreferrer"
          >
            <img
              alt="Turun yliopiston logo"
              src="/utu-logo.png"
            />
          </a>
        </Grid>
        <Grid item xs={12} sm={4} md={3} lg={3} className={classes.info}>
          {translation("footer","turun_yliopisto", lang)}
          <br />
          {translation("footer","oikeustieteellinen_tiedekunta", lang)}
          <br />
          {translation("footer","tutkimuksen_it", lang)}
        </Grid>
        <Grid item xs={12} sm={4} md={3} lg={3}>
          <div className="footer__link-wrapper">
            <Link to="/ohjeet" className={classes.footerLink}>
              {translation("footer", "ohjeet", lang)}
            </Link>
            <Link to="/palaute" className={classes.footerLink}>
              {translation("footer", "palaute", lang)}
            </Link>
            <Link to="/tietosuoja" className={classes.footerLink}>
              {translation("footer", "tietosuoja", lang)}
            </Link>
            <Link
              to="/saavutettavuus"
              className={classes.footerLink}
            >
              {translation("footer", "saavutettavuus", lang)}
            </Link>
          </div>
        </Grid>
        <Grid item xs={12} sm={4} md={2} lg={2} className={classes.twitterGrid}>
         
          <a
            href="https://www.twitter.com/lakitutka"
            target="_BLANK"
            rel="noopener noreferrer"
            className={classes.twitterLink}
          >
            <img
              className={classes.twitterLogo}
              alt="Twitter"
              src="/Group_65.svg"
            />
          </a>
          <a
            href="https://blogit.utu.fi/lakitutka/"
            target="_BLANK"
            rel="noopener noreferrer"
            className={classes.blogLogo}
          >
            <img
              className={classes.blogLogo}
              alt="Blog"
              src="/blog-solid.svg"
            />
          </a>
        </Grid>
      </Grid>
   </div>
  )
};

export default Footer;
