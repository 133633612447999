import React from 'react';
import PropTypes from 'prop-types';

export const Dot = ({color}) => {
 const spanStyle = {
    height: "15px",
    width: "15px",
    backgroundColor: color,
    border: "2px solid black",
    borderRadius: "50%",
    display: "inline-block",
    marginRight: "5px",
 }
  return (
    <span style={spanStyle}
    />
  );
};

Dot.propTypes = {
  color: PropTypes.string.isRequired,
};

Dot.defaultProps = {
  color: '#064EEA',
};

export default Dot;