import {
  REPORT_ERROR,
  CLEAR_ERROR,
} from '../actions/actionTypes';

export default (state = null, action) => {
  switch (action.type) {
    case REPORT_ERROR:
      return action.error;

    case CLEAR_ERROR:
      return null;

    default:
      return state;
  }
};
