import React from 'react';
import PropTypes from 'prop-types';
import './Pagination.css';
import { useSelector } from 'react-redux';
import { translation } from 'utils/translate';

const Pagination = ({ page, pagesTotal, gotoPage }) => {
    const lang = useSelector((store) => store.lang)

  // calculate the start page of pagination
  const startPage =
    pagesTotal > 9 && page >= 5
      ? pagesTotal >= page + 5
        ? page - 4
        : pagesTotal - 8
      : 1;

  // generate link array
  const pagiLinks = [];
  if (page !== 1) {
    pagiLinks.push(
      <button
        type="button"
        className="pagination-link pagination-link--first"
        key="pl-first"
        onClick={() => gotoPage(1)}
        aria-label="ensimmäinen sivu"
      >
        {translation("pagination", "first", lang)}
      </button>,
    );
    pagiLinks.push(
      <button
        type="button"
        className="pagination-link pagination-link--prev"
        key="pl-prev"
        onClick={() => gotoPage(page - 1)}
        aria-label="edellinen sivu"
      >
        {translation("pagination", "previous", lang)}
      </button>,
    );
  }
  for (
    let i = startPage;
    i <= pagesTotal && i < startPage + 9;
    i += 1
  ) {
    if (i === page) {
      pagiLinks.push(
        <div
          className="pagination-link pagination-link--active"
          key="pl-active"
        >
          {i}
        </div>,
      );
    } else
      pagiLinks.push(
        <button
          type="button"
          className="pagination-link"
          key={`pl-${i}`}
          onClick={() => gotoPage(i)}
          aria-label={`sivu ${i}`}
        >
          {i}
        </button>,
      );
  }
  if (page < pagesTotal) {
    pagiLinks.push(
      <button
        type="button"
        className="pagination-link pagination-link--next"
        key="pl-next"
        onClick={() => gotoPage(page + 1)}
        aria-label="seuraava sivu"
      >
        {translation("pagination", "next", lang)}
      </button>,
    );
    pagiLinks.push(
      <button
        type="button"
        className="pagination-link pagination-link--last"
        key="pl-last"
        onClick={() => gotoPage(pagesTotal)}
        aria-label="viimeinen sivu"
      >
        {translation("pagination", "last", lang)}
      </button>,
    );
  }

  return <div className="pagination">{pagiLinks}</div>;
};

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  pagesTotal: PropTypes.number.isRequired,
  gotoPage: PropTypes.func.isRequired,
};

export default Pagination;
