// Dictionary of legislative terms and explanations


const legislative_terms = [
    {
      fi: {
        term: 'Asetus',
        explanation: 'Säädös, jonka antaa tasavallan presidentti, valtioneuvosto tai ministeriö laissa säädetyn valtuuden nojalla',
        source_text: 'Valtioneuvoston termipankki: Asetus',
        source_url: 'https://valter.sanakirja.fi/all/all/asetus',
      }
    },
    {
      fi: {
        term: 'Asetus (EU)',
        explanation: 'Euroopan unionin antama säädös, jota on sovellettava suoraan ja yhdenmukaisesti kaikissa EU-maissa heti, kun asetus tulee voimaan. Eikä se vaadi toimenpiteitä tullakseen osaksi kansallista lainsäädäntöä.',
        source_text: 'Euroopan komissio: EU-lainsäädännön tyypit',
        source_url: 'https://commission.europa.eu/law/law-making-process/types-eu-law_fi',
      }
    },
    {
      fi: {
        term: 'Asiantuntijalausunto',
        explanation: 'Jonkin tahon antama, asiantuntemukseen perustuva selvitys tai kannanotto käsiteltävään asiaan. Yleensä kirjallinen.',
        source_text: 'Valtioneuvoston termipankki: Asiantuntijalausunto',
        source_url: 'https://valter.sanakirja.fi/all/all/Asiantuntijalausunto',
      }
    },
    {
      fi: {
        term: 'Direktiivi',
        explanation: 'Euroopan unionin antama säädös, jolla säädetään velvoittavista tavoitteista. Direktiivit määrittelevät tietyn lopputuloksen, jonka jäsenvaltioiden on saavutettava, mutta ne jättävät kansallisille viranomaisille ja lainsäätäjille vapauden valita parhaiten soveltuvat keinot tämän tavoitteen saavuttamiseksi.',
        source_text: 'Euroopan unioni: Säädöstyypit',
        source_url: 'https://european-union.europa.eu/institutions-law-budget/law/types-legislation_fi',
      }
    },
    {
      fi: {
        term: 'Eduskunnan täysistunto',
        explanation: 'Eduskunnan virallinen istunto, johon kansanedustajat kokoontuvat käymään eduskuntakeskusteluja, eli käsittelemään eri asioita. Eduskunnan täysistunto on lainsäädäntötyön keskeinen tapahtumapaikka.',
        source_text: 'Eduskunta: Täysistunnot eduskunnassa',
        source_url: 'https://www.eduskunta.fi/taysistunto',
      }
    },
    {
      fi: {
        term: 'Eduskuntakeskustelu',
        explanation: 'Eduskunnan istunto, jossa käsitellään tiettyä asiaa tai aihepiiriä esimerkiksi hallituksen esitystä. Istunto, jossa kansanedustajat ilmaisevat näkemyksiään ja käyvät keskustelua käsiteltävästä aiheesta. ',
        source_text: '',
        source_url: '',
      }
    },
    {
      fi: {
        term: 'Hallituksen esitys',
        explanation: 'Valtioneuvoston antama esitys eduskunnalle, jossa on yksi tai useampi lakiehdotus.',
        source_text: 'Tieteen termipankki: Hallituksen esitys',
        source_url: 'https://tieteentermipankki.fi/wiki/Oikeustiede:hallituksen_esitys',
      }
    },
    {
      fi: {
        term: 'Hylkääminen (lakialoitteen hylkääminen)',
        explanation: 'Eduskunta päättää, ettei ehdotettua lakia saateta voimaan, eli hylkää sen, lakiehdotuksen toisessa käsittelyssä.',
        source_text: 'Eduskunta: Lakien säätäminen eduskunnassa',
        source_url: 'https://www.eduskunta.fi/FI/naineduskuntatoimii/eduskunnan_tehtavat/lakiensaataminen/Sivut/default.aspx',
      }
    },
    {
      fi: {
        term: 'Itsehallinto',
        explanation: 'Kunnan tai maakunnan oikeus hoitaa itse määrittämänsä tai lailla osoitetut tehtävät. Itsehallintoon kuuluu oikeus päättää myös hallinnosta ja taloudesta.',
        source_text: 'Valtioneuvoston termipankki: Itsehallinto',
        source_url: 'https://valter.sanakirja.fi/all/all/itsehallinto',
      }
    },
    {
      fi: {
        term: 'Kansalaisaloite',
        explanation: 'Kansalaisten oikeus/mahdollisuus tehdä eduskunnalle aloite lain säätämiseksi taikka voimassa olevan lain muuttamiseksi tai kumoamiseksi. Aloite vaatii vähintään 50 000 Suomen kansalaisen kannatusilmoituksen.',
        source_text: 'Eduskunta: Kansalaisaloitteet',
        source_url: 'https://www.eduskunta.fi/FI/valtiopaivaasiat/Sivut/Kansalaisaloitteet.aspx',
      }
    },
    {
      fi: {
        term: 'Kokeilulainsäädäntö',
        explanation: 'Lainsäädäntö, joka on kokeilumielessä voimassa ajallisesti tai alueellisesti rajatun ajan. Tarkoituksena on usein selvittää toimien vaikutuksia.',
        source_text: 'Finlex: Kokeiluopas',
        source_url: 'https://kokeiluohje.finlex.fi/ohje/kokeilulla-tulee-olla-hyvaksyttava-tavoite/',
      }
    },
    {
      fi: {
        term: 'Kumoamissäädös',
        explanation: 'Säädös, jolla kumotaan alkuperäinen säädös tai lainkohta.',
        source_text: 'Finlex: Lainkirjoittajan opas',
        source_url: 'http://lainkirjoittaja.finlex.fi/15-uusi-saados-muutossaados-vai-kumoamissaados/15-1/',
      }
    },
    {
      fi: {
        term: 'Lainkohta',
        explanation: 'Viittaa tiettyyn osaan säädöstä. Säädös koostuu lainkohdista esimerkiksi pykälistä, momenteista sekä kohdista ja alakohdista. ',
        source_text: 'Tieteen termipankki: Lainkohta',
        source_url: 'https://tieteentermipankki.fi/wiki/Oikeustiede:lainkohta',
      }
    },
    {
      fi: {
        term: 'Lainsäädäntösuunnitelma',
        explanation: 'Lainsäädäntösuunnitelma sisältää alustavat tiedot niistä lainvalmisteluhankkeista, joista hallitus suunnittelee antavansa esityksen eduskunnan istuntokaudella.',
        source_text: 'Valtioneuvosto: Hallituksen lainsäädäntöohjelma ja -suunnitelma',
        source_url: 'https://valtioneuvosto.fi/lainsaadantosuunnitelma',
      }
    },
    {
      fi: {
        term: 'Lainsäädäntövalta',
        explanation: 'Lainsäädäntövalta, eli toimivalta säätää lakeja, on eduskunnalla.',
        source_text: 'Suomen perustuslaki 731/1999',
        source_url: '',
      }
    },
    {
      fi: {
        term: 'Lainsäätämisjärjestys',
        explanation: 'Menettelytapa, jolla eduskunnassa päätetään lakiehdotuksen hyväksymisestä.',
        source_text: 'Valtioneuvoston termipankki: Lainsäätämisjärjestys',
        source_url: 'https://valter.sanakirja.fi/all/all/Lains%C3%A4%C3%A4t%C3%A4misj%C3%A4rjestys',
      }
    },
    {
      fi: {
        term: 'Laintarkastus',
        explanation: 'Laintarkastuksessa tarkastetaan hallituksen esityksen luonnos suomeksi ja ruotsiksi. Luonnos, jonka kieliasut eivät vastaa toisiaa, tai joka ei noudata ohjeita, voidaan palauttaa takaisin lainvalmisteluun.',
        source_text: 'Finlex: Lainvalmistelun prosessiopas',
        source_url: 'https://lainvalmistelu.finlex.fi/4-jatkovalmistelu/4-9-laintarkastus/',
      }
    },
    {
      fi: {
        term: 'Lakialoite',
        explanation: 'Kansanedustaja voi tehdä lakialoitteen, jolla hän ehdottaa uutta lakia tai olemassaolevan lain muuttamista tai kumoamista.',
        source_text: 'Valtioneuvoston termipankki',
        source_url: 'https://valter.sanakirja.fi/all/all/lakialoite',
      }
    },
    {
      fi: {
        term: 'Lakiehdotus',
        explanation: 'Ehdotus uuden lain säätämisestä tai voimassa olevan lain muuttamisesta tai kumoamisesta. Ehdotus voi olla osa hallituksen lakiesitystä, kansanedustajan lakialoitetta taikka valiokunnan mietintöä.',
        source_text: 'Valtioneuvoston kanslia, Lainsäädäntösanasto 2023. s. 16',
        source_url: '', 
      }
    },
    {
      fi: {
        term: 'Lakiesitys',
        explanation: 'Viittaa hallituksen esitykseen, joka sisältää lakiehdotuksen ja sen perustelut.',
        source_text: 'Valtioneuvoston kanslia, Lainsäädäntösanasto 2023. s. 17',
        source_url: '', 
      }
    },
    {
      fi: {
        term: 'Lausuntokierros',
        explanation: 'Lainvalmistelun kuluessa käytävä kuulemismenettely, johon kenellä tahansa on mahdollisuus osallistua. Lausuntokierroksella pyydetään kannanottoja lakiehdotukseen.',
        source_text: 'Finlex: Lainvalmistelun prosessiopas',
        source_url: 'https://lainvalmistelu.finlex.fi/3-lausuntomenettely/#esittely',
      }
    },
    {
      fi: {
        term: 'Lausuntopyyntö',
        explanation: 'Lainvalmistelussa pyydetään kirjallisia lausuntoja sidosryhmiltä lausuntopyynnöllä. Lausuntopyynnöt julkaistaan esimerkiksi lausuntopalvelu.fi-palvelussa.',
        source_text: 'Finlex: Lainvalmistelun prosessiopas',
        source_url: 'https://lainvalmistelu.finlex.fi/3-lausuntomenettely/',
      }
    },
    {
      fi: {
        term: 'Ministeriö',
        explanation: 'Ministeriö on valtioneuvoston osasto, jonka johdossa on ministeri. Ministeriöt huolehtivat asioiden valmistelusta ja hallinnosta omalla toimialallaan.',
        source_text: 'Valtioneuvosto: Valtioneuvoston toiminta',
        source_url: 'https://valtioneuvosto.fi/tietoa/valtioneuvoston-toiminta',
      }
    },
    {
      fi: {
        term: 'Ministeriön asetus',
        explanation: 'Säädös, jonka antaa ministeriö laissa säädetyn valtuuden nojalla.',
        source_text: 'Valtioneuvoston termipankki: Ministerin asetus',
        source_url: 'https://valter.sanakirja.fi/all/all/ministeri%C3%B6n%20asetus',
      }
    },
    {
      fi: {
        term: 'Momentti',
        explanation: 'Lainsäädännössä olevan pykälän osa. Säädös jakautuu pykäliin ja pykälät momentteihin. ',
        source_text: 'Tieteen termipankki: Momentti',
        source_url: 'https://tieteentermipankki.fi/wiki/Oikeustiede:momentti',
      }
    },
    {
      fi: {
        term: 'Muutossäädös',
        explanation: 'Säädös, jolla alkuperäiseen säädökseen tehdään muutos.',
        source_text: 'Finlex: Lainkirjoittajan opas',
        source_url: 'http://lainkirjoittaja.finlex.fi/15-uusi-saados-muutossaados-vai-kumoamissaados/15-1/',
      }
    },
    {
      fi: {
        term: 'Pykälä',
        explanation: 'Lait ja muut säädökset jaetaan pykäliin. Pykäliin viitataan numerolla tai numero-kirjainyhdistelmällä sekä §-merkillä.',
        source_text: 'Valtioneuvoston termipankki: Pykälä',
        source_url: 'https://valter.sanakirja.fi/all/all/pyk%C3%A4l%C3%A4',
      }
    },
    {
      fi: {
        term: 'Päätös (EU) ',
        explanation: 'Päätös osoitetaan nimetyille tahoille, ja se on näiden nimettyjen tahojen osalta oikeudellisesti velvoittava.',
        source_text: 'Euroopan komissio: EU-lainsäädännön tyypit',
        source_url: 'https://commission.europa.eu/law/law-making-process/types-eu-law_fi',
      }
    },
    {
      fi: {
        term: 'Raukeaminen (lakiehdotuksen raukeaminen)',
        explanation: 'Lakiehdotus, jota ei käsitellä eduskunnassa, raukeaa vaalikauden vaihtuessa.',
        source_text: '',
        source_url: '',
      }
    },
    {
      fi: {
        term: 'Siirtymäsäännös',
        explanation: 'Säännös, jota sovelletaan kun siirrytään vanhan lain soveltamisesta uuden lain soveltamiseen. ',
        source_text: 'Finlex: Lainkirjoittajan opas',
        source_url: 'http://lainkirjoittaja.finlex.fi/21-siirtymasaannokset/21-1/',
      }
    },
    {
      fi: {
        term: 'Suositus (EU)',
        explanation: 'Suosituksessa EU:n toimielimet kertovat näkemyksiään ja ehdottavat toimenpiteitä. Suositukset eivät ole oikeudellisesti sitovia.',
        source_text: 'Euroopan komissio: EU-lainsäädännön tyypit',
        source_url: 'https://commission.europa.eu/law/law-making-process/types-eu-law_fi',
      }
    },
    {
      fi: {
        term: 'Säädös ',
        explanation: 'Viittaa lakeihin, asetuksiin sekä muihin alempien viranomaisten antamiin oikeussääntöihin. ',
        source_text: 'Oikeusministeriö, Lainlaatijan opas osa 2. Lakitekniikka 2010',
        source_url: 'https://julkaisut.valtioneuvosto.fi/bitstream/handle/10024/76237/43_2010_lainlaatijan_opas.pdf?sequence=1',
      }
    },
    {
      fi: {
        term: 'Säädöskokoelma',
        explanation: 'Suomen säädöskokoelmassa julkaistaan Suomessa säädetyt lait, asetukset ja määräykset, valtiosopimukset sekä tietyt eduskunnan toimintaan liittyvät säädökset.',
        source_text: 'Laki Suomen säädöskokoelmasta (188/2000)',
        source_url: '',
      }
    },
    {
      fi: {
        term: 'Säädösvalmistelu',
        explanation: 'Oikeudellisen sääntelyn valmistelua, jonka tarkoituksena on laatia sääntelyehdotus.',
        source_text: 'Tieteen termipankki: Lainvalmistelu',
        source_url: 'https://tieteentermipankki.fi/wiki/Oikeustiede:lainvalmistelu',
      }
    },
    {
      fi: {
        term: 'Säännös',
        explanation: 'Pykälään sisältyvä oikeusnormi eli oikeudellinen toimintaohje (esim. käsky tai kielto)',
        source_text: 'Tieteen termipankki: Oikeussäännös',
        source_url: 'https://tieteentermipankki.fi/wiki/Oikeustiede:oikeuss%C3%A4%C3%A4nn%C3%B6s',
      }
    },
    {
      fi: {
        term: 'Tasavallan presidentin asetus',
        explanation: 'Tasavallan presidentin antama, lakia alemmanasteinen säädös',
        source_text: 'Valtioneuvoston kanslia, Lainsäädäntösanasto 2023. s. 4',
        source_url: '',
      }
    },
    {
      fi: {
        term: 'Työryhmä',
        explanation: 'Lainvalmistelussa erikseen koottu ryhmä lainvalmistelijoita, viranomaisia ja muita sidosryhmiä edustavia henkilöitä. Ministeriö asettaa työryhmän määräajaksi lainvalmisteluun liittyvään tehtävään.',
        source_text: 'Finlex: Lainvalmistelun prosessiopas',
        source_url: 'https://lainvalmistelu.finlex.fi/2b-perusvalmistelu-valmisteluelin/#esittely',
      }
    },
    {
      fi: {
        term: 'Täytäntöönpano',
        explanation: 'Täytäntöönpanolla tarkoitetaan erilaisia viranomaisten toimenpiteitä, joilla hyväksytty laki tuodaan käytäntöön.',
        source_text: 'Finlex: Prosessiopas',
        source_url: 'https://lainvalmistelu.finlex.fi/taytantoonpano/#esittely',
      }
    },
    {
      fi: {
        term: 'Vahvistaa laki',
        explanation: 'Eduskunnan hyväksymän lain vahvistamisesta päättää tasavallan presidentti. Mikäli presidentti ei vahvista lakia, se voidaan kuitenkin saattaa voimaan eduskunnan päätöksellä.',
        source_text: 'Eduskunta: Lakien säätäminen eduskunnassa',
        source_url: 'https://www.eduskunta.fi/FI/naineduskuntatoimii/eduskunnan_tehtavat/lakiensaataminen/Sivut/default.aspx',
      }
    },
    {
      fi: {
        term: 'Valiokunnan lausunto',
        explanation: 'Valiokuntaa voidaan pyytää antamaan lausunto toiselle valiokunnalle jostakin sen valmisteltavana olevasta asiasta. Esimerkiksi perustuslakivaliokunta voi antaa lausuntoja lakiehdotusten perustuslainmukaisuudesta.',
        source_text: 'Eduskunta: Valiokuntien lausunnot',
        source_url: 'https://www.eduskunta.fi/FI/valtiopaivaasiat/Sivut/Valiokuntien-lausunnot.aspx',
      }
    },
    {
      fi: {
        term: 'Valiokunnan mietintö',
        explanation: 'Valiokunnan mietintö on valiokunnan valmistelun lopputuloksena syntyvä asiakirja, jonka pohjalta eduskunta päättää lakiehdotuksen hyväksymisestä tai hylkäämisestä.',
        source_text: 'Eduskunta: Valiokuntien mietinnöt',
        source_url: 'https://www.eduskunta.fi/FI/valtiopaivaasiat/Sivut/Valiokuntien-mietinnot.aspx',
      }
    },
    {
      fi: {
        term: 'Valiokunta',
        explanation: 'Valiokunta on eduskunnan toimielin, joka valmistelee päätöksiä eduskunnan päätöksentekoon. Lakiehdotukset käsitellään yhdessä tai useammassa valiokunnassa ennen kuin eduskunta päättää täysistunnossa niiden hyväksymisestä tai hylkäämisestä.',
        source_text: 'Eduskunta: Valiokunnat',
        source_url: 'https://www.eduskunta.fi/FI/valiokunnat/Sivut/default.aspx',
      }
    },
    {
      fi: {
        term: 'Valmisteluasiakirja',
        explanation: 'Ministeriön lainvalmistelun kuluessa tuottama tai vastaanottama asiakirja. Asiakirjan rooli prosessissa vaihtelee sen tyypin mukaan.',
        source_text: '',
        source_url: '',
      }
    },
    {
      fi: {
        term: 'Valtioneuvosto',
        explanation: 'Toimielin, joka koostuu pääministeristä, ministereistä ja valtioneuvoston oikeuskanslerista, ja joka käyttää toimeenpanovaltaa.',
        source_text: 'Tieteen termipankki: Valtioneuvosto',
        source_url: 'https://tieteentermipankki.fi/wiki/Oikeustiede:valtioneuvosto_(valtios%C3%A4%C3%A4nt%C3%B6oikeus)',
      }
    },
    {
      fi: {
        term: 'Valtioneuvoston asetus',
        explanation: 'Valtioneuvoston antama, lakia alemman asteinen säädös.',
        source_text: 'Valtioneuvoston kanslia, Lainsäädäntösanasto 2023. s. 4',
        source_url: '',
      }
    },
    {
      fi: {
        term: 'Valtioneuvoston yleisistunto',
        explanation: 'Valtioneuvoston yleisistunnossa hallitus tekee päätökset mm. siitä, mitkä hallituksen esitykset annetaan eduskunnalle.',
        source_text: 'Valtioneuvoston termipankki',
        source_url: 'https://valter.sanakirja.fi/all/all/Valtioneuvoston%20yleisistunto',
      }
    },
    {
      fi: {
        term: 'Voimaantulo',
        explanation: 'Ajankohta, jolloin säädös tulee voimaan ja sitovaksi. Ajankohta, jolloin säädöstä aletaan soveltaa. Määritellään yleensä säädöksessä taikka erillisellä voimaantulolailla. ',
        source_text: 'Tieteen termipankki: Säädöksen voimaantulo',
        source_url: 'https://tieteentermipankki.fi/wiki/Oikeustiede:s%C3%A4%C3%A4d%C3%B6ksen_voimaantulo',
      }
    },
    {
      fi: {
        term: 'Voimaantulosäännös',
        explanation: 'Jokaisessa säädöksessä oleva säännös, joka määrittelee miten ja milloin kyseinen säädös tulee voimaan.',
        source_text: 'Finlex: Lainkirjoittajan opas',
        source_url: 'http://lainkirjoittaja.finlex.fi/20-voimaantulo/20-2/',
      }
    },
];

export const getAllTerms = (lang) => {
    if (lang === 'fi')
    {
        return legislative_terms;
    }
    return [];
  };
