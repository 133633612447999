import { SET_SEARCH_FORM_FULL_SCREEN } from "../actions/actionTypes";

export default (state = false, action) => {
    switch (action.type) {
      case SET_SEARCH_FORM_FULL_SCREEN:
        return action.state;
  
      default:
        return state;
    }
  };