import React from 'react';
import PropTypes from 'prop-types';
import './Identifier.css';

const Identifier = ({ className, children }) => (
  <div
    className={
      className ? `identifier ${className}` : 'identifier'
    }
  >
    {children}
  </div>
);

Identifier.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Identifier.defaultProps = {
  className: null,
};
export default Identifier;
