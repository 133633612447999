import { SET_SEARCH_SUBMITED} from "../actions/actionTypes";

export default (state = false, action) => {
    switch (action.type) {
      case SET_SEARCH_SUBMITED:
        return action.state;
  
      default:
        return state;
    }
  };