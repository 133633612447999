import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Button,
  FormControlLabel,
  FormControl,
  Checkbox,
  CircularProgress,
  Box,
  Typography,
} from '@material-ui/core';
import './DownloadForm.css';
import { showInCurrentEnvironment } from 'utils/commonTools';
import config from 'config';
import { translation } from 'utils/translate';
import { useSelector } from 'react-redux';

const { apiUrl } = config;

const tabs = [
  'meta',
  'content'
];

const fileFormats = [
  { key: 'csv', label: 'CSV' },
  { key: 'xlsx', label: 'Excel' },
];

const downloadableFileFormats = [
  'orig',
  'text',
  'xml',
];

const defaultMetadataFormat = 'csv';

function CircularProgressWithLabel({ value, ...props }) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        variant="determinate"
        value={value}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
        >
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

const DownloadForm = ({
  loadingDataFromBackend,
  loadingPercent,
  handleDownload,
  fileDownloadLink,
  handleDownloadZippedFile,
}) => {
  const [activeTab, setActiveTab] = useState('meta');
  const [metaFormat, setMetaFormat] = useState(
    defaultMetadataFormat,
  );

  const lang = useSelector((store) => store.lang)

  const [
    selectedFileFormats,
    setSelectedFileFormats,
  ] = useState([]);

  const toggleMetaFormat = (event) => {
    setMetaFormat(event.target.value);
  };

  // toggle individual checkbox
  const toggleFileFormat = (event) => {
    const { checked, value } = event.target;
    setSelectedFileFormats(
      checked
        ? [...selectedFileFormats, value]
        : selectedFileFormats.filter(
          (item) => item !== value,
        ),
    );
  };

  const downloadUrl = `${apiUrl}download/zip/${fileDownloadLink}`;

  const renderMetadataTools = () => (
    <>
      {loadingDataFromBackend && (
        <CircularProgress
          size={68}
          className="circular__progress__centered"
        />
      )}
      <FormControl
        component="fieldset"
        className="download-form__fieldset"
      >
        <h4 className="download-form__subtitle">
          {translation("download", "format", lang)}
        </h4>
        <RadioGroup
          onChange={toggleMetaFormat}
          className="radio-group-format"
        >
          {fileFormats.map(({ key, label }) => (
            <FormControlLabel
              key={`form-control-label-format-${key}`}
              control={
                <Radio
                  checked={metaFormat === key}
                  value={key}
                  name="file-format"
                />
              }
              label={label}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <div className="download-form__button-wrapper">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={loadingDataFromBackend}
          onClick={() => handleDownload({ metaFormat })}
        >
          {translation("download", "meta", lang)}
        </Button>
      </div>
    </>
  );

  const renderContentTools = () => (
    <>
      <FormControl
        component="fieldset"
        className="download-form__fieldset"
      >
        <h4 className="download-form__subtitle">
          {translation("download", "formats", lang)}
        </h4>
        {downloadableFileFormats
          .filter(showInCurrentEnvironment)
          .map(key => (
            <>
              <FormControlLabel
                key={`form-control-label-file-format-${key}`}
                control={
                  <Checkbox
                    className="download-form__checkbox"
                    value={key}
                    checked={selectedFileFormats.includes(
                      key,
                    )}
                    onChange={toggleFileFormat}
                    name="document-meta-checkbox"
                    color="primary"
                  />
                }
                label={translation("download", "file_formats", key, "label", lang)}
              />
              <div className="checkbox-info">
                {translation("download", "file_formats", key, "info", lang)}
              </div>
            </>
          ))}
      </FormControl>
      <div>
        {fileDownloadLink ? (
          <a
            className="download-form__button-link"
            href={downloadUrl}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => handleDownloadZippedFile()}
          >
            {translation("download", "content", lang)}
          </a>
        ) : loadingDataFromBackend ? (
          <div>
            {translation("download", "gathering", lang)}
            <CircularProgressWithLabel
              value={loadingPercent}
            />
          </div>
        ) : (
          <div className="download-form__button-wrapper">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!selectedFileFormats.length}
              onClick={() =>
                handleDownload({
                  fileFormats: selectedFileFormats,
                })
              }
            >
              {translation("download", "prepare", lang)}
            </Button>
          </div>
        )}
      </div>
    </>
  );

  return (
    <Paper elevation={2} className="download-form">
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <h4 className="download-form__title">
            {translation("download", "title", lang)}
          </h4>
        </Grid>

        <Grid item xs={12}>
          {tabs.map(key => (
            <button
              key={key}
              type="button"
              className={
                activeTab === key
                  ? 'document-type-select document-type-select--active'
                  : 'document-type-select'
              }
              onClick={() => setActiveTab(key)}
              disabled={loadingDataFromBackend}
            >
              {translation("download", "tabs", key, lang)}
            </button>
          ))}
          <div className="download-form__inner">
            {activeTab === 'meta' && renderMetadataTools()}
            {activeTab === 'content' &&
              renderContentTools()}
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

DownloadForm.propTypes = {
  loadingDataFromBackend: PropTypes.bool.isRequired,
  loadingPercent: PropTypes.number.isRequired,
  handleDownload: PropTypes.func.isRequired,
  fileDownloadLink: PropTypes.string.isRequired,
  handleDownloadZippedFile: PropTypes.func.isRequired,
};

export default DownloadForm;
