import React from 'react';
import PropTypes from 'prop-types';
import './Tags.css';

/* COMPONENT FOR RENDERING TAGS */
const Tags = ({ className, title, tagList, highlights }) => {
  return (<div className={className ? `tags ${className}` : 'tags'}>
    <span className="doc-info--lower">
      {title}:&nbsp;
    </span>
    {Array.isArray(tagList) && tagList.map((tag) => (
      <span
        className="tag"
        key={tag}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: highlights && highlights.includes(tag) ? `<em>${tag}</em>` : `${tag}`,
        }}
      />
    ))}
  </div>
  )
}
Tags.propTypes = {
  className: PropTypes.string,
  tagList: PropTypes.arrayOf(PropTypes.string).isRequired,
};

Tags.defaultProps = {
  className: null,
};

export default Tags;
