/* eslint-disable no-shadow */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import queryString from 'query-string';
import {
  Grid,
  Paper,
  Chip,
  TextField,
  Button,
  // Hidden,
  Select,
  MenuItem,
  CircularProgress,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import ListIcon from '@material-ui/icons/List';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import DownloadIcon from '@material-ui/icons/GetApp';
import Pagination from 'components/Pagination';
import DownloadForm from 'components/DownloadForm';
import Tip from 'components/Tip';
import config from 'config';
import {
  initializeFilters,
  randomId
} from 'utils/commonTools';
import { streamFileToUser } from 'utils/fileTools';
import apiService from 'services/apiService';
import {
  clearError,
  reportError,
} from 'actions/errorActions';
import { changeDocGroupState } from 'actions/docGroupStateAction';
import { changeSearchState } from 'actions/searchStateActions';
import { setSearchFormFullScreen } from 'actions/searchFormFullScreenActions';
import {setSearchSubmited} from 'actions/searchSubmitedActions';
import { changeLanguage } from 'actions/langActions';
import texts from 'texts';
import { translateKeyword, translation } from 'utils/translate';
import FacetBlock from './FacetBlock';
import SearchResults from './SearchResults';
import PreFilters from './PreFilters';
import ResultCharts from './ResultCharts';
import './SearchForm.css';
import EditIcon from '@material-ui/icons/Edit';

const sortOptions = [
  'score',
  'date_asc',
  'date_desc',
  'alpha_asc',
  'alpha_desc',
];
const sortDefault = 'alpha_desc';
const sizeOptions = [5, 10, 25, 50, 100, 250, 500];
const sizeDefault = 10;

// const formTypes = isProductionSite ? [
//   { key: 'basic', name: 'Perushaku' },
//   { key: 'proximity', name: 'Proksimiteettihaku' },
//   { key: 'prefilters', name: 'Esirajaa haku' },
// ] : [
//   { key: 'basic', name: 'Perushaku' },
//   { key: 'semantic', name: 'Semanttinen' },
//   { key: 'proximity', name: 'Proksimiteettihaku' },
//   { key: 'prefilters', name: 'Esirajaa haku' }
// ];
//const formTypes = isProductionSite ? ['basic', 'proximity'] : ['basic', 'proximity', 'prefilter',];
const formTypes = ['basic', 'proximity', 'prefilter',];

const initialProximityValue = {
  term1: '',
  term2: '',
  slop: 1,
};

const urlifyJson = (value) =>
  encodeURIComponent(JSON.stringify(value));

// returns true if given item has a value and it's not an empty array
const hasContent = (item) =>
  item && !(Array.isArray(item) && !item.length);

const setDefaultSort = (query, docGroup) => {
  if (query.sort) {
    return query.sort
  }

  if (docGroup === 'all') {
    return 'score'
  }

  if (query.term) {
    return 'score'
  }

  return sortDefault
}
const StyledButton = withStyles({
  root: {
    backgroundColor: 'var(--primary)',
    borderRadius: 5,
    border: 0,
    height: 40,
    '&:hover': {
      backgroundColor: 'var(--primary-dark)',
    },
    marginLeft: 10
  },
  
  label: {
    textTransform: 'none',
    color: 'white',
    fontSize: '16px',
    lineHeight: '18px',
  },
})(Button);

const LinkLookButton = withStyles({
  root: {
    backgroundColor: 'white',
    borderRadius: 0,
    border: "white",
    height: 48,
    color: 'var(--primary)',
    textDecoration: "underline",
    textDecorationThickness: 2,
    '&:hover': {
      backgroundColor: 'white',
      textDecoration: "underline",
      textDecorationThickness: 1,
    },
  },
  
  label: {
    textTransform: 'none',
    color: 'var(--primary)',
    fontSize: '16px',
  },
})(Button);

const WhiteButton = withStyles({
  root: {
    backgroundColor: 'white',
    borderRadius: 5,
    border: '3px solid var(--primary)',
    height: 40,
    '&:hover': {
      backgroundColor: 'var(--blue-verylight)',
    },
    marginLeft: 10
  },
  
  label: {
    textTransform: 'none',
    color: 'var(--primary)',
    fontSize: '14px',
    lineHeight: '18px',
  },
})(Button);


/* -------------------- *\
  SEARCH FORM -COMPONENT
\* -------------------- */
class SearchForm extends Component {
  constructor(props) {
    super(props);
    const { location, docGroup, facetConstants, lang} =
      this.props;
    const values = queryString.parse(location.search);
    this.componentIsMounted = false;
    this.searchParams = '';
    this.facetFields =
      config.docGroups[docGroup].facetFields;
    const proximityValue = values.proximity
      ? JSON.parse(values.proximity)
      : null;
    this.state = {
      term: values.term || '',
      submittedTerm: values.term || '',
      activeFormType: proximityValue
        ? 'proximity'
        : 'basic',
      sort: setDefaultSort(values, docGroup),
      manualSort: false,
      size: parseInt(values.size, 10) || sizeDefault,
      page: parseInt(values.page, 10) || 1,
      pagesTotal: 0,
      preFilters: {},
      filters: initializeFilters(this.facetFields),
      proximityValue:
        proximityValue || initialProximityValue,
      submittedProximityValue: proximityValue || {},
      totalHits: facetConstants[docGroup]?.total,
      isLoaded: false,
      result: {},
      facetCacheForBlocks: {},
      facetCacheForPrefilters: {},
      showDownloadForm: false,
      visualize: false,
      loadingDataFromBackend: false,
      loadingPercent: 0,
      fileDownloadLink: '',
      searchSubDocuments: false,
      lang: lang,
      selectedDocGroup: config.docGroups["all"],
      isPrefilterButton: false,
      loadingStatus: 'initial'
    };
    // Create ref-objects
    this.searchFormRef = React.createRef();
    this.searchInfoRef = React.createRef();
  }

  resetSearch = async () => {
    this.clearFilters();
    this.clearPreFilters();
    this.searchParams = '';
    await this.setState({
      term: '',
      submittedTerm: '',
      page: 1,
      isLoaded: false,
      result: {},
      facetCacheForBlocks: {},
      facetCacheForPrefilters: {},
      visualize: false,
      sort: sortDefault,
      manualSort: false,
      loadingStatus: "initial",
    });
    this.pushHistory();
  };

  async componentDidMount() {
    const { location, facetConstants, docGroup,setSearchFormFullScreen, changeLanguage } = this.props;
    this.componentIsMounted = true;
    this.clearPreFilters();

    // if search parameters in url, handle them and fetch needed searches
    if (location.search) {
      const values = queryString.parse(location.search);
      setSearchFormFullScreen(true)
      changeLanguage(values.lang || "fi")
      if (values.prefilters) {
        try {        
          const parsedPreFilters = JSON.parse(
            values.prefilters
          );
          await this.setState({
            preFilters: parsedPreFilters,
          });
          await this.fetchSearch('prefilter');
        } catch (error) {
          // eslint-disable-next-line no-console
          console.warn(error);
        }
      }
      if (values.sort || values.term){
        await this.fetchSearch();
      }
      this.setState({
        // populate filters from url values
        filters: this.facetFields.reduce(
          (soFar, field) => ({
            ...soFar,
            [field]: !values[field]
              ? []
              : Array.isArray(values[field])
                ? values[field]
                : [values[field]],
          }),
          [],
        ),
        searchSubDocuments: values["asiakirjat.tyyppi"] ? true : false,
        totalHits: values["asiakirjat.tyyppi"] ? facetConstants[docGroup + '_asiakirjat']?.total : facetConstants[docGroup]?.total,
      });
      if (this.hasFilters()) {
        await this.fetchSearch('update');
      }
    }

    // Set charts ON if visualize in query params
    const searchParams = new URLSearchParams(
      window.location.search,
    );
    if (searchParams.get('visualize')) {
      this.setState({ visualize: true });
    }
  }

  async componentDidUpdate(prevProps) {
    const { history, docGroup, facetConstants, location, lang, setSearchFormFullScreen, changeDocGroupState } =
      this.props;
    const { submittedTerm } = this.state;
    if (lang !== this.state.lang) {
      this.setState({
        lang: lang
      })
      if (location.search !== "?lang=fi" && location.search !== "?lang=se"){
        await this.fetchSearch();
      }
    }

    if (location.search !== prevProps.location.search){
      if (queryString.parse(prevProps.location.search).term === submittedTerm ){
     
        this.setState({
          term: queryString.parse(location.search).term,
          submittedTerm: queryString.parse(location.search).term,
        })
      }
    }
    if (location.pathname !== prevProps.location.pathname){
      if(location.pathname.split("/")[1]==="haku"){
        if(location.pathname.split("/")[2] !== docGroup){
          const previousDocGroup = location.pathname.split("/")[2]
          changeDocGroupState(previousDocGroup)
        }
      }
    }

    // if (location.pathname !== prevProps.location.pathname) {
      if (location.hash === '#basic-search-term' && location.pathname !== prevProps.location.pathname) {
        setSearchFormFullScreen(false)
        // changeSearchState("yleinen")
        this.resetSearch();
      }
    // }

    if (docGroup !== prevProps.docGroup) {
      //const { submittedTerm } = this.state;
      this.facetFields =
        config.docGroups[docGroup]?.facetFields;
      // eslint-disable-next-line react/no-did-update-set-state
      await this.setState({
        isLoaded: false,
        page: 1,
        pagesTotal: facetConstants[docGroup]?.total,
        facetCacheForBlocks: {},
        facetCacheForPrefilters: {},
        result: {},
        showDownloadForm: false,
      });
      this.clearFilters();
      this.clearPreFilters();
      if (submittedTerm) {
        await this.fetchSearch();
        this.pushHistory();
      } else {
        history.push({
          pathname: `/haku/${docGroup}`,
        });
      }
    }
  }

  componentWillUnmount() {
    this.componentIsMounted = false;
  }

  pushHistory = () => {
    const { history, docGroup } = this.props;
    history.push({
      pathname: `/haku/${docGroup}`,
      search: this.searchParams,
    });
  };

  scrollToSearchFormTop = () => {
    window.scrollTo({
      top: this.searchFormRef?.current?.offsetTop - 20,
      behavior: 'smooth',
    });
  };

  scrollToResultsTop = () => {
    window.scrollTo({
      top: this.searchInfoRef.current.offsetTop - 20,
      behavior: 'smooth',
    });
  };

  gotoPage = async (newPage) => {
    await this.setState({
      page: newPage,
    });
    await this.fetchSearch('update');
    this.pushHistory();
    this.scrollToResultsTop();
  };

  // return search parameter string for API call and browser history push
  buildSearchParams = () => {
    const {
      submittedTerm,
      filters,
      page,
      preFilters,
      submittedProximityValue,
      size,
      visualize,
    } = this.state;

    const {
      facetConstants,
      docGroup,
      lang
    } = this.props;

    const params = {
      term: submittedTerm.replace('&', ' '), // TODO: better sanitation
      ...filters,
      page: page > 1 && page,
      size: size !== sizeDefault && size,
      sort: this.setSort(),
      prefilters:
        Object.keys(preFilters).length > 0 &&
        urlifyJson(preFilters),
      proximity:
        submittedProximityValue.term1 &&
        submittedProximityValue.term2 &&
        urlifyJson(submittedProximityValue),
      visualize: visualize ? 1 : null,
      lang: lang
    };

    try {
      this.setState({
        searchSubDocuments: (filters["asiakirjat.tyyppi"] && filters["asiakirjat.tyyppi"].length > 0) ? true : false,
        totalHits: (filters["asiakirjat.tyyppi"] && filters["asiakirjat.tyyppi"].length > 0) ? facetConstants[docGroup + '_asiakirjat']?.total : facetConstants[docGroup]?.total,
      })
    } catch (error) {
      this.setState({ searchSubDocuments: false})
    }
    return `?${Object.entries(params)
      .filter((entry) => hasContent(entry[1]))
      .flatMap(([key, value]) =>
        Array.isArray(value)
          ? value.map((item) => `${key}=${item}`)
          : `${key}=${value}`,
      )
      .join('&')}`;
  };

  // return prefilter search parameter string for API call and browser history push
  buildPrefilterParams = () => {
    const { preFilters, size } = this.state;
    const params = {
      size: size !== sizeDefault && size,
      sort: this.setSort(),
      prefilters:
        Object.keys(preFilters).length > 0 &&
        urlifyJson(preFilters),
    };
    return `?${Object.entries(params)
      .filter((entry) => hasContent(entry[1]))
      .flatMap(([key, value]) => `${key}=${value}`)
      .join('&')}`;
  };

  // fetch search from api and put result in state
  async fetchSearch(fetchType = 'normal') {
    const { size, activeFormType,loadingStatus } = this.state;
    const { docGroup, clearError, reportError, } =
      this.props;

    try {
      this.setState({
        loadingStatus: loadingStatus === 'initial' ? 'loading' : 'loadingMore',
      })
      this.searchParams =
        fetchType !== 'prefilter'
          ? this.buildSearchParams()
          : this.buildPrefilterParams();

      let result = null
      
      try {

        let searchIndex = docGroup

        if (this.state.searchSubDocuments) {
          searchIndex = docGroup + "_asiakirjat"
        }

        if (activeFormType === 'semantic') {
          result = await apiService.fetchData(
            `semsearch/${searchIndex}${this.searchParams}`,
          );
        } else {
          result = await apiService.fetchData(
            `search/${searchIndex}${this.searchParams}`,
          );
        }
      } catch (error) {
        reportError(error)
        return
      }

      // ES response changed in major version update from v6 to v7
      if (
        typeof result.hits.total === 'object' &&
        'value' in result.hits.total
      ) {
        result.hits.total = result.hits.total.value;
      }

      if (this.componentIsMounted) {
        switch (fetchType) {
          // don't overwrite aggregations (ie. facets)
          case 'update':
            this.setState({
              pagesTotal: Math.ceil(
                result.hits.total / size,
              ),
              result,
            });
            break;

          case 'prefilter':
            this.setState({
              isLoaded: true,
              pagesTotal: Math.ceil(
                result.hits.total / size,
              ),
              facetCacheForPrefilters: result.aggregations,
              facetCacheForBlocks: result.aggregations,
              totalHits: result.hits.total,
              result,
            });
            break;

          case 'normal':
          default:
            this.setState({
              isLoaded: true,
              pagesTotal: Math.ceil(
                result.hits.total / size,
              ),
              facetCacheForBlocks: result.aggregations,
              result,
            });
            break;
        }
        clearError();
      }
      this.setState({
        loadingStatus: "ready",
      })
    } catch (error) {
      if (this.componentIsMounted) {
        reportError(error);
      }
    }
  }

  // functions for basic search form
  handleTermChange = (event) =>
    this.setState({
      term: event.target.value,
      proximityValue: initialProximityValue,
    });

  handleBasicSearchSubmit = async (event) => {
    event.preventDefault();
    const { term } = this.state;
    const { setSearchFormFullScreen, setSearchSubmited, docGroup } = this.props;
    this.clearFilters();
    if (docGroup === 'all'){
      await this.setState({
        sort: 'date_desc'
      })
    }
    else {await this.setState({
      sort: 'score'
    })}
    await this.setState({
      submittedTerm: term,
      submittedProximityValue: {},
      page: 1,
    });
    setSearchFormFullScreen(true)
    setSearchSubmited(true)
    await this.fetchSearch();
    //this.scrollToSearchFormTop();
    this.pushHistory();
    
  };

  handleSelectOnClick = async (event) => {
    const { term } = this.state
    const { changeDocGroupState } = this.props
    this.setState({selectedDocGroup: config.docGroups[event.target.value],
                  docGroup: event.target.value,
                })
    changeDocGroupState(event.target.value)
    if (term !== ''){
      await this.fetchSearch("normal");
      this.scrollToSearchFormTop();
      this.pushHistory();
    }
  }
  handlePrefilterDockGroupChange = async (event) =>{
    const { changeDocGroupState } = this.props
    this.setState({selectedDocGroup: config.docGroups[event.target.value],
                  docGroup: event.target.value,
                })
    changeDocGroupState(event.target.value)
  }

  // functions for proximity search form
  handleProximityValueChange = (event) => {
    const { proximityValue } = this.state;
    const { target } = event;
    this.setState({
      term: '',
      proximityValue: {
        ...proximityValue,
        [target.name]: target.value,
      },
    });
  };

  handleProximitySearchSubmit = async (event) => {
    const { proximityValue } = this.state;
    event.preventDefault();
    this.clearFilters();
    await this.setState({
      submittedTerm: '',
      submittedProximityValue: proximityValue,
      page: 1,
    });
    await this.fetchSearch();
    this.scrollToSearchFormTop();
    this.pushHistory();
  };

  handleResultSizeChange = async (event) => {
    const { size } = this.state;
    if (size !== event.target.value) {
      await this.setState({
        page: 1,
        size: event.target.value,
      });
      await this.fetchSearch();
      this.pushHistory();
      this.scrollToResultsTop();
    }
  };

  handleResultSortChange = async (event) => {
    const { sort } = this.state;
    if (sort !== event.target.value) {
      await this.setState({
        page: 1,
        sort: event.target.value,
        manualSort: true
      });
      await this.fetchSearch();
      this.pushHistory();
      this.scrollToResultsTop();
    }
  };

  setSort = () => {
    const { sort, submittedTerm, manualSort } = this.state;

    if (manualSort) {
      return sort
    }

    // if (!submittedTerm) {
    //   this.setState({
    //     sort: sortDefault
    //   })
    //   return sortDefault
    // }

    if (sort === sortDefault && submittedTerm) {
      this.setState({
        sort: 'score'
      })
      return 'score'
    }
    return sort
  }

  // functions for search filters
  clearFilters = () => {
    this.setState({
      filters: initializeFilters(this.facetFields),
    });
  };

  clearPreFilters = () => {
    const { docGroup, facetConstants } = this.props;
    this.setState({
      totalHits: facetConstants[docGroup]?.total,
      preFilters: {},
      facetCacheForPrefilters: {},
    });
  };

  hasFilters = () => {
    const { filters } = this.state;
    return this.facetFields.some(
      (field) => filters[field]?.length > 0,
    );
  };

  activeSearchParameters = () => {
    return !(
      this.state.result &&
      Object.keys(this.state.result).length === 0 &&
      Object.getPrototypeOf(this.state.result) ===
      Object.prototype
    );
  };

  // functions for facets
  handleFacetChange = async (group, value) => {
    const { filters } = this.state;
    const strValue = `${value}`;

    const newFilters = {
      ...filters,
      [group]: filters[group].includes(strValue)
        ? [
          ...filters[group].filter(
            (item) => item !== strValue,
          ),
        ]
        : [...filters[group], strValue],
    };
    await this.setState({
      page: 1,
      filters: newFilters,
    });
    await this.fetchSearch('update');
    this.pushHistory();
  };

  removeAllFacets = async () => {
    await this.setState({
      page: 1,
    });
    this.clearFilters();
    await this.fetchSearch();
    this.pushHistory();
  };

  // functions for preFilters
  handlePreFiltersSubmit = async (
    event,
    preFiltersInput,
    docGroup,
  ) => {
    event.preventDefault();
    this.clearFilters();
    const {setSearchFormFullScreen} = this.props
    // filter out empty strings and arrays from preFiltersInput
    setSearchFormFullScreen(true)
    const filteredInput = Object.entries(
      preFiltersInput,
    ).reduce(
      (soFar, [key, value]) =>
        hasContent(value)
          ? {
            ...soFar,
            [key]: value,
          }
          : soFar,
      {},
    );
    await this.setState({
      submittedTerm: '',
      submittedProximityValue: {},
      page: 1,
      preFilters: filteredInput,
      selectedDocGroup: docGroup,
      activeFormType: "basic",
      isPrefilterButton: true,
    });
    await this.fetchSearch('prefilter');
    this.scrollToSearchFormTop();
    this.pushHistory();
  };

  toggleDownloadForm = () => {
    const { showDownloadForm } = this.state;
    this.setState({
      showDownloadForm: !showDownloadForm,
      fileDownloadLink: '',
      loadingDataFromBackend: false,
    });
  };

  toggleVisualize = () => {
    const { visualize } = this.state;
    this.setState({
      visualize: !visualize,
      showDownloadForm: false,
    });

    if (!visualize) {
      const params = new URLSearchParams(
        window.location.search,
      );
      params.set('visualize', 1);
      const newRelativePathQuery = `${window.location.pathname
        }?${params.toString()}`;
      window.history.pushState(
        null,
        '',
        newRelativePathQuery,
      );
    } else {
      const params = new URLSearchParams(
        window.location.search,
      );
      params.delete('visualize');
      const newRelativePathQuery = `${window.location.pathname
        }?${params.toString()}`;
      window.history.pushState(
        null,
        '',
        newRelativePathQuery,
      );
    }
  };

  downloadSearchResults = async ({
    metaFormat,
    fileFormats,
  }) => {
    const { docGroup, reportError } = this.props;
    // show spinner
    this.setState({
      loadingDataFromBackend: true,
    });

    let jobId = '';
    if (fileFormats) {
      jobId = randomId(8);
    }

    try {
      const result = await apiService.fetchData(
        `download/${docGroup}${this.searchParams}&format=${metaFormat}&content=${fileFormats}&job_id=${jobId}`,
        'text',
      );

      if (result) {
        streamFileToUser({
          data: result,
          format: metaFormat,
          fileName: `${docGroup}_exported`,
        });
      }

      if (fileFormats) {
        const interval = setInterval(async () => {
          try {
            const { status } =
              await apiService.checkFileStatus(jobId);

            if (status === 'DONE') {
              clearInterval(interval);
              this.setState({
                fileDownloadLink: jobId,
                loadingDataFromBackend: false,
              });
              // eslint-disable-next-line no-restricted-globals
            } else if (!isNaN(status)) {
              this.setState({
                loadingPercent: parseFloat(status),
              });
            } else {
              clearInterval(interval);
              this.setState({
                loadingDataFromBackend: false,
                showDownloadForm: false,
              });
              reportError(status);
            }
          } catch (err) {
            console.log(err);
            // pass (this error will be handled by parent)
          }
        }, 3000);
      } else {
        this.setState({
          loadingDataFromBackend: false,
          showDownloadForm: false,
        });
      }
    } catch (error) {
      this.setState({
        loadingDataFromBackend: false,
        showDownloadForm: false,
      });
      reportError(error);
    }
  };

  handleDownloadZippedFile = () => {
    this.setState({
      loadingDataFromBackend: false,
    });
  };

  handlePrefilterButtonSubmit = () => {
    const {preFilters} = this.state
    const {docGroup} = this.props
    this.setState({activeFormType: 'prefilter'})
    return (
      <PreFilters
        key={docGroup}
        startValues={preFilters}
        docGroup={docGroup}
        handlePreFiltersSubmit={
          this.handlePreFiltersSubmit
        }
        handlePrefilterDockGroupChange={this.handlePrefilterDockGroupChange}
        handlePreFiltersClear={this.resetSearch}
        isPrefilterOpen={true}
      />
    )
  }

  // prefilter and search forms for rendering
  renderSearchForm() {
    const {
      activeFormType,
      term,
      preFilters,
      proximityValue,
      totalHits,
      isPrefilterButton,
      loadingStatus,
    } = this.state;

    const { docGroup, lang, searchState, searchFormFullScreen } = this.props;

    return totalHits ? (
      <>
        {searchState === "tutkija" &&
          <>
          {searchFormFullScreen === false &&
            <h2 className="section--info-area-h2">{translation("valitse_hakutapa",lang)}</h2>
          }
          </>
        }
          <div className={searchState === "yleinen"? "search-form-wrapper-for-everyone": "search-form-wrapper"}>
          {searchState === "tutkija" && searchFormFullScreen === false &&
            <div
              className="form-type-select-area"
              ref={this.searchFormRef}
            >
              {formTypes.map((key) => (
                <Tip title={translation("search", key, "tooltip", lang)} key={key}>
                  <button
                    type="button"
                    className={
                      activeFormType === key
                        ? 'form-type-select form-type-select--active'
                        : 'form-type-select'
                    }
                    onClick={() =>
                      this.setState({
                        activeFormType: key,
                      })
                    }
                  >
                    {translation("search", key, "name", lang)}
                  </button>
                </Tip>
              ))}
            </div>
          }
          {activeFormType === 'basic' || activeFormType === 'semantic' ? (
            <>
            {/*Basic search form*/}
            <form
              className="search-form search-form--basic"
              onSubmit={this.handleBasicSearchSubmit}
              ref={this.searchFormRef}
            >
              <p className="search-form--prompt">
                {translation("hae_lakitutkasta", lang)}
                <Tip title={translation("texts_tooltip_basic", lang)}>
                  <span alt={texts.info.help} className="tooltip-help tippable"></span>
                </Tip>
              </p>
              <div className={searchState === "yleinen" ? "search-form--select-docgroup-for-everyone": searchFormFullScreen? 'search-form--select-docgroup-fullscreen':"search-form--select-docgroup"}>
                {searchState === "tutkija" &&
                <Grid container className='search-form-container'>
                    <Grid item xs={12} sm={12} md={3} >
                      <Select
                        className="search-form--select"
                        value={docGroup || "all"}
                        onChange={this.handleSelectOnClick}
                      >
                        {Object.entries(config.docGroups).map(
                          ([key, value]) =>
                            value.searchable && (
                              <MenuItem value={key} key={key}>
                                {/* {value.name} */}
                                {translation(value.name, lang)}
                              </MenuItem>
                            ),
                        )}
                      </Select>
                    </Grid>
                    <Grid item className='search-term-grid' xs={12} sm={12} md={9}>
                      <TextField
                        id="basic-search-term"
                        className="basic-search-term"
                        onChange={this.handleTermChange}
                        key={
                          translation("doc_groups", docGroup, "search", lang)
                        }
                        label={
                          translation("doc_groups", docGroup, "search", lang)
                        }
                        variant="outlined"
                        value={term? term : ''}
                      />
                      <Button
                        aria-label="hae"
                        variant="outlined"
                        type="submit"
                        className="submit-basic-search"
                        disableTouchRipple
                      >
                        <SearchIcon />
                      </Button>
                    </Grid>
                  </Grid>
                  }
                  {searchState === "yleinen" &&
                    <>
                      <TextField
                        id="basic-search-term"
                        className={searchState === "yleinen" ?"basic-search-term-for-everyone":"basic-search-term"}
                        onChange={this.handleTermChange}
                        key={
                          translation("doc_groups", docGroup, "search", lang)
                        }
                        label={
                          translation("doc_groups", docGroup, "search", lang)
                        }
                        variant="outlined"
                        value={term? term : ''}
                      />
                      <Button
                        aria-label="hae"
                        variant="outlined"
                        type="submit"
                        className="submit-basic-search"
                        disableTouchRipple
                      >
                        <SearchIcon />
                      </Button>
                    </>
                  }
              </div>
              {/* <div className="fetch-all-clear-all-button">
                {this.activeSearchParameters() ? (
                  <Button
                    aria-label="hae kaikki"
                    variant="outlined"
                    disableTouchRipple
                    onClick={this.resetSearch}
                  >
                    {translation("search", "empty", lang)}
                  </Button>
                ) : (
                  <Button
                    aria-label="tyhjennä"
                    variant="outlined"
                    disableTouchRipple
                    onClick={this.handleBasicSearchSubmit}
                  >
                    {translation("search", "all", lang)}
                  </Button>
                )}
              </div> */}
            </form>
            {isPrefilterButton &&
              <LinkLookButton
                id="prefilters-toggler"
                className="prefilters-toggler"
                disableTouchRipple
                onClick={() => this.handlePrefilterButtonSubmit()}
              >
                <EditIcon className="button-icon" />
                {translation("prefilter", "button", lang)}
              </LinkLookButton>
            }
            </>
          ) : (activeFormType === 'proximity' ? (
            <Grid container>
              <Grid item xs={12} sm={12} md={8}>
                <div className='proximity-doctype-div'>
                  <h4 className='proximity-doctype-h4'>
                    {translateKeyword("type", lang)}
                    <Tip title={translation("asiakirjatyyppi_tooltip", lang)}>
                      <span alt={translation("asiakirjatyyppi_tooltip", lang)} className="tooltip-help tippable"></span>
                    </Tip>
                  </h4>
                  <Select
                    className="docgroup-select"
                    value={docGroup || "all"}
                    onChange={this.handleSelectOnClick}
                  >
                    {Object.entries(config.docGroups).map(
                      ([key, value]) =>
                        value.searchable && (
                          <MenuItem value={key} key={key}>
                            {translation(value.name, lang)}
                          </MenuItem>
                        ),
                    )}
                    </Select>
                </div>
                {/*Proximity search form*/}
                <div className = 'proximity-form-div'>
                  <form
                    className="search-form search-form--proximity"
                    onSubmit={this.handleProximitySearchSubmit}
                  >
                    <Grid container>
                      <Grid item xs={12} sm={12} md={4} >
                    <h4 className='proximity-labels'>
                      {translation("search", "proximity", "first_term", lang)}
                      <TextField
                        id="proximity-input-first"
                        className="proximity-search-input search-input--left"
                        // label={translation("search", "proximity", "first_term", lang)}
                        name="term1"
                        value={proximityValue.term1}
                        onChange={this.handleProximityValueChange}
                        variant="outlined"
                      />
                    </h4>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} >
                    <h4 className='proximity-labels'>
                      {translation("search", "proximity", "distance", lang)}
                      <TextField
                        id="proximity-input-max"
                        className="proximity-search-input search-input--left"
                        name="slop"
                        // label={translation("search", "proximity", "distance", lang)}
                        value={proximityValue.slop}
                        onChange={this.handleProximityValueChange}
                        type="number"
                        inputProps={{
                          min: '0',
                          max: '1000',
                          step: '1',
                        }}
                        variant="outlined"
                      />
                    </h4>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} >
                    <h4 className='proximity-labels'>
                      {translation("search", "proximity", "second_term", lang)}
                    
                      <TextField
                        id="proximity-input-second"
                        className="proximity-search-input search-input--right"
                        name="term2"
                        // label={translation("search", "proximity", "second_term", lang)}
                        value={proximityValue.term2}
                        onChange={this.handleProximityValueChange}
                        variant="outlined"
                      />
                     </h4>
                     </Grid>
                     <Grid item className='proximity-submit-grid'xs={12} sm={12} md={1}>
                      <Button
                        aria-label="hae"
                        variant="outlined"
                        type="submit"
                        className="submit-basic-search"
                        disableTouchRipple
                      >
                        <SearchIcon />
                      </Button>
                    </Grid>
                    </Grid>
                  </form>
                </div>
              </Grid>
            </Grid>
          ) : 
            // Prefilters
            <PreFilters
              key={docGroup}
              startValues={preFilters}
              docGroup={docGroup}
              term={term}
              handlePreFiltersSubmit={
                this.handlePreFiltersSubmit
              }
              handlePrefilterDockGroupChange={this.handlePrefilterDockGroupChange}
              handlePreFiltersClear={this.resetSearch}
              isPrefilterOpen={true}
            />
          )}
        </div>
        {(loadingStatus === "loadingMore" || loadingStatus === "loading")?(
          <div className="circular-progress">
            <CircularProgress size={68} />
          </div>
        ):<></>
        }
      </>
     
    ) : <div>Hakukone poissa käytöstä hetkellisesti. Pahoittelut häiriöstä.</div>
    
  }

  renderResultChooserArea(){
    const {
      result,
      isLoaded,
      pagesTotal,
      sort,
      visualize,
    } = this.state
    const { lang } = this.props

    if (!isLoaded || pagesTotal <= 0) {
      return null;
    }

    return(
        <Grid container className="result-chooser-area">
          <Grid item xs={12} sm={12} md={4} className="result-chooser-wrapper-totalhits">
            {/* hit stats */}
            {translation("results", "hits", lang)} {result.hits.total} kpl
          </Grid>
          <Grid item xs={12} sm={12} md={8} className = "result-chooser-grid" >
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={1}></Grid>
              <Grid item xs={12} sm={12} md={7} className = "select">
                {!visualize &&
                <>
                  <div className='select-order'>
                    {translation("results", "order", lang)}:
                    <Select
                        className="search-form-result-chooser"
                        value={sort}
                        onChange={this.handleResultSortChange}
                        disableUnderline={true}
                      >
                        {sortOptions.map((value) => (
                            <MenuItem value={value} key={value}>
                              {translateKeyword(value, lang)}
                            </MenuItem>
                          ),
                        )}
                    </Select>
                  </div>
                  <div className= "select-number">
                    {translation("results", "on_page", lang)}:
                    <Select
                        className="search-form-result-chooser"
                        defaultValue="10"
                        onChange={this.handleResultSizeChange}
                        disableUnderline={true}
                      >
                        {sizeOptions.map((value) => (
                            <MenuItem value={value} key={value}>
                              {translateKeyword(value, lang)}
                            </MenuItem>
                          ),
                        )}
                    </Select>
                  </div>
                </>
                }
              </Grid>
              <Grid item xs={12} sm={12} md={4} >
                {this.renderResultTools()}
              </Grid>
            </Grid>
          </Grid>    
        </Grid>
    )
  }

  // search result list, pagination and sorting etc. tools for rendering
  renderSearchResultsArea() {
    const {
      submittedTerm,
      submittedProximityValue,
      activeFormType,
      isLoaded,
      result,
      page,
      pagesTotal,
      size,
    } = this.state;

    const { docGroup } = this.props;


    if (!isLoaded || pagesTotal <= 0) {
      return null;
    }
    const { hits } = result.hits;
    let pagination = null;
    if (pagesTotal > 1) {
      pagination = (
        <Paper elevation={0}>
          <Pagination
            page={page}
            pagesTotal={pagesTotal}
            gotoPage={this.gotoPage}
          />
        </Paper>
      );
    }
    return (
      <div id="results" className="results-area">
        {/* <div className="result-chooser-area">
          <div className="result-chooser-wrapper-totalhits">
          
          {translation("results", "hits", lang)} {result.hits.total} kpl
          </div>
          <div className="result-chooser-wrapper">
            {translation("results", "order", lang)}:
            <ResultChooser
              value={sort}
              options={sortOptions}
              handleChange={this.handleResultSortChange}
            />
          </div>
          <div className="result-chooser-wrapper">
            {translation("results", "on_page", lang)}:
            <ResultChooser
              value={size}
              options={sizeOptions}
              handleChange={this.handleResultSizeChange}
            />
          </div>          
          {this.renderResultTools()}
        </div> */}
        {/* {pagination} */}
        <Paper elevation={0}>
          <SearchResults
            hits={hits}
            docGroup={docGroup}
            highlightTerm={
              activeFormType === 'proximity'
                ? `${submittedProximityValue.term1}%20OR%20${submittedProximityValue.term2}`
                : submittedTerm
            }
            page={page}
            size={size}
            searchTerm={submittedTerm? submittedTerm : ''}
          />
        </Paper>
        {pagination}
      </div>
    );
  }

  // info text area from current search and preFilters for rendering
  renderSearchInfo() {
    const {
      submittedTerm,
      isLoaded,
      preFilters,
      submittedProximityValue,
      result
    } = this.state;

    const { lang } = this.props

    if (!result.hits || !isLoaded) {
      return null;
    }

    const activePreFilterNames = Object.entries(preFilters)
      .filter((keyValuePair) => hasContent(keyValuePair[1]))
      .map(([key, value]) => {
        const label = translateKeyword(key, lang);
        const selection = Array.isArray(value)
          ? value.join(', ')
          : value;
        return `${label} (${selection})`;
      })
      .join(', ');

    return (
      <>
        {/* if prefilters */}
        {activePreFilterNames && (
          <div className="search-info">
            <strong>{translation("results", "prefilters", lang)}:</strong>{' '}
            <i>{activePreFilterNames}</i>
          </div>
        )}
        <div className="search-info">
          {/* if basic search with term */}
          {submittedTerm && (
            <>
              {/* {translation("results", "term", lang)}: <strong>{submittedTerm}</strong>,{' '} */}
              <span className='search-info-black'>{translation("Näytetään_tulokset_haulla", lang)} </span>
              <span className="search-info-blue">"{submittedTerm}"</span>
            </>
          )}
          {/* if proximity search */}
          {submittedProximityValue.term1 &&
            submittedProximityValue.term2 && (
              <>
                {translation("search", "proximity", "name", lang)}:{' '}
                <strong>
                  {submittedProximityValue.term1}
                </strong>{' '}
                {'<max '} {submittedProximityValue.slop}{' '}
                {' ' + translation("search", "proximity", "words", lang) + '>'}{' '}
                <strong>
                  {submittedProximityValue.term2}
                </strong>
                ,{' '}
              </>
            )}
          {/* hit stats
          {translation("results", "hits", lang)}: {result.hits.total}/{totalHits} (
          {((result.hits.total / totalHits) * 100).toFixed(
            1,
          )}
          %) */}
        </div>
      </>
    );
  }

renderResultTools() {
    const { result, visualize } = this.state;
    const { lang } = this.props;

    return (
      result &&
      result.hits &&
      result.hits.total > 0 && (
        <div className="button-area">
          {!visualize &&
          <StyledButton
            disableTouchRipple
            type="submit"
            onClick={this.toggleDownloadForm}
            variant="contained"
          >
            <DownloadIcon className="button-icon" />
            {translation("results", "download", lang)}
          </StyledButton>
          }
          <StyledButton
            disableTouchRipple
            variant="contained"
            onClick={this.toggleVisualize}
          >
            {visualize ? (
              <>
                <ListIcon className="button-icon" />
                  {translation("results", "show_results", lang)}
              </>
            ) : (
              <>
                <InsertChartIcon className="button-icon" />
                  {translation("results", "show_visual", lang)}
              </>
            )}
          </StyledButton>
          
        </div>
      )
    );
  }

  // active facet chips (if any) for rendering
  renderActiveFacets() {
    const { filters, isLoaded } = this.state;
    const { lang } = this.props;
    if (!isLoaded || !this.hasFilters()) {
      return null;
    }
    return (
      <div className="facet-active-wrapper">
        {this.facetFields.map(
          (field) =>
            filters[field] &&
            filters[field].map((item) => (
              <Chip
                key={item}
                label={item}
                onDelete={() =>
                  this.handleFacetChange(field, item)
                }
                className="facet-chip"
              />
            )),
        )}
        <Chip
          label={translation("results", "remove_active_facets", lang)}
          onClick={() => this.removeAllFacets()}
          className="facet-chip remove-all"
        />
      </div>
    );
  }

  // facet blocks for rendering
  renderFacetBlocks() {
    const {
      facetCacheForBlocks,
      filters,
      result,
      isLoaded,
      pagesTotal,
    } = this.state;
    const { lang } = this.props;
    if (
      !isLoaded ||
      (pagesTotal <= 0 && !this.hasFilters())
    ) {
      return null;
    }

    return (
      <div className="facets">
        <h4>{translation("Rajaa_hakua", lang)}</h4>
        {this.facetFields.map(
          (field) => (
            result.aggregations[field] && (
              <FacetBlock
                key={field}
                field={field}
                onChange={(event) =>
                  this.handleFacetChange(
                    event.target.getAttribute('group'),
                    event.target.value,
                  )
                }
                facets={result.aggregations[field].buckets}
                cachedFacets={field in facetCacheForBlocks ? facetCacheForBlocks[field].buckets : []}
                filters={filters[field]}
              />
            )
          )
        )}
        <div className="white-button">
          <WhiteButton
            type="submit"
            onClick={() => this.removeAllFacets()}
          >
            {translation("Tyhjennä valinnat", lang)}
          </WhiteButton>
        </div>
      </div>
    );
  }

  // Render it all
  render() {
    const {
      facetCacheForPrefilters,
      result,
      showDownloadForm,
      visualize,
      loadingDataFromBackend,
      loadingPercent,
      fileDownloadLink,
    } = this.state;
    const { docGroup } = this.props;

    return (
      <div className= "search" role="main">
        {/* <Hidden smUp>
          <div className="search-docgroup-info">
            <Paper
              elevation={2}
              className="search-docgroup-info__inner"
            >
              <p>
                {translation("doc_groups", docGroup, "text", lang)}
              </p>
              <p>
                {translation("doc_groups", docGroup, "source", lang)}
              </p>
            </Paper>
          </div>
        </Hidden> */}
        {this.renderSearchForm()}

        <div
          className="search-info-area"
          ref={this.searchInfoRef}
        >
          {this.renderSearchInfo()}
          {this.renderActiveFacets()}
        </div>

        {showDownloadForm ? (
          <div className="download-form-wrapper download-form-wrapper--open">
            <DownloadForm
              loadingDataFromBackend={
                loadingDataFromBackend
              }
              loadingPercent={loadingPercent}
              fileDownloadLink={fileDownloadLink}
              handleDownload={this.downloadSearchResults}
              handleDownloadZippedFile={
                this.handleDownloadZippedFile
              }
            />
          </div>
        ) : (
          <div className="download-form-wrapper" />
        )}

        {visualize ? (
          // Show either search data visualization...
          result &&
          result.hits &&
          result.hits.total > 0 && (
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={12}>
                {this.renderResultChooserArea()}
                <ResultCharts
                  docGroup={docGroup}
                  dataset={result.aggregations || {}}
                  cache={facetCacheForPrefilters}
                  handleFacetChange={this.handleFacetChange}
                />
              </Grid>
            </Grid>
          )
        ) : (
          // ...or search results and facets
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12}>
              {this.renderResultChooserArea()}
            </Grid>
            <Grid item xs={12} sm={12} md={3} className='facet-grid'>
              {this.renderFacetBlocks()}
            </Grid>
            <Grid item xs={12} sm={12} md={9}>
              {this.renderSearchResultsArea()}
            </Grid>
          </Grid>
        )}
      </div>
    );
  }
}

SearchForm.propTypes = {
  docGroup: PropTypes.string.isRequired,
  facetConstants: PropTypes.shape({}).isRequired,
  lang: PropTypes.string,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  clearError: PropTypes.func.isRequired,
  reportError: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  facetConstants: state.facetConstants,
  lang: state.lang,
  docGroup: state.docGroup,
  searchState: state.searchState,
  searchFormFullScreen: state.searchFormFullScreen
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ reportError, clearError, changeDocGroupState, changeSearchState, setSearchFormFullScreen, changeLanguage, setSearchSubmited}, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SearchForm),
);
