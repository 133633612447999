import { REPORT_ERROR, CLEAR_ERROR } from './actionTypes';

export const reportError = (error) => ({
  type: REPORT_ERROR,
  error,
});

export const clearError = () => ({
  type: CLEAR_ERROR,
});
