import React, { useState, useEffect } from 'react';
import { Paper, Grid, Box } from '@material-ui/core';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import TocIcon from '@material-ui/icons/Toc';
import './TableOfContent.css';
import { translation } from 'utils/translate';
import { useSelector } from 'react-redux';

function isUpperCase(str) {
  return str.toUpperCase() === str;
}

const TableOfContent = ({ toc }) => {
  const [tocShow, setTocShow] = useState(true);
  const [anchor, setAnchor] = useState(false);
  //const [sizeOfToc, setSizeofToc] = useState([12, 4, 3]);
  const [minimized, setMinimized] = useState(false);
  const lang = useSelector((store) => store.lang)

  const minimizeToc = () => {
    setTocShow(false);
    //setSizeofToc([12, 1, 1]);
    setMinimized(true);
  };
  const maximizeToc = () => {
    setTocShow(true);
    //setSizeofToc([12, 4, 3]);
    setMinimized(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (
        window.innerWidth > 959 &&
        window.scrollY > 2000
      ) {
        setAnchor(true);
      } else {
        setAnchor(false);
      }
    });
  }, [anchor, setAnchor]);

  return (
    // <Grid
    //   item
    //   xs={sizeOfToc[0]}
    //   sm={sizeOfToc[0]}
    //   md={sizeOfToc[1]}
    //   lg={sizeOfToc[2]}
    //   role="complementary"
    // >
      <Paper
        elevation={0}
        className={anchor && minimized ? 'anchored-minimized-toc' : (anchor ? 'anchored-toc' : null)}
      >
        <Grid
          container
          className="toc-title"
          justifyContent="flex-end"
        >
          {tocShow ? (
            <Grid item xs={10}>
              <h3> {translation("document", "toc", lang)} </h3>
            </Grid>
          ) : (
            <Grid item xs={6}>
              <TocIcon className="toc-icon" />
            </Grid>
          )}
          <Grid item xs={tocShow ? 2 : 6}>
            <Box display="flex" justifyContent="flex-end">
              {tocShow ? (
                <ArrowLeftIcon
                  fontSize="large"
                  className={`min-max-toc ${
                    !tocShow ? 'hidden' : null
                  }`}
                  onClick={minimizeToc}
                />
              ) : (
                <ArrowRightIcon
                  fontSize="large"
                  className="min-max-toc"
                  onClick={maximizeToc}
                />
              )}
            </Box>
          </Grid>
        </Grid>

        <div className="toc">
          {tocShow &&
            toc.map((sub) =>
              sub.map((el) => (
                <a
                  href={`#${el[1]}`}
                  onClick={(e) => {
                    e.preventDefault()
                    document.querySelector(`#${el[1]}`).scrollIntoView({
                      behavior: "smooth"
                    })}}
                  className={`toc-link
                    ${
                      isUpperCase(el[0])
                        ? 'toc-root'
                        : el[0].startsWith('Laki')
                        ? 'toc-root toc-laki'
                        : el[0].includes('.') ||
                          el[0].includes('§')
                        ? 'toc-sub-sub'
                        : !Number.isNaN(Number(el[0][0]))
                        ? 'toc-sub'
                        : null
                    }
              `}
                >
                  {el[0]}
                </a>
              )),
            )}
        </div>
      </Paper>
    // </Grid>
  );
};

export default TableOfContent;
