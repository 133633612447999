import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { translation, translateKeyword } from 'utils/translate';
import { sortByKey } from 'utils/commonTools';
import Block from 'components/Block';
import ShowMore from 'components/ShowMore';
import './FacetBlock.css';
import { Paper } from '@material-ui/core';
import Tip from 'components/Tip';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import IconButton from '@material-ui/core/IconButton';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import AddBoxIcon from '@material-ui/icons/AddBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CancelIcon from '@material-ui/icons/Cancel';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import texts from 'texts';

import { useSelector } from 'react-redux';

// make string html attribute compiliant
const safe = (input) =>
  input.toLowerCase().replace(/[^a-z0-9åäö]/g, '_');

/* facet sorting label and arrows indicating sorting order */
function FacetOrderingLabel({
  correctKey,
  label,
  labelStyling = 'p',
  direction,
  tip
}) {
  const customLabel = React.createElement(
    labelStyling,
    null,
    label,
  );

  return (
     <div className = "ordering-div">
      <div>
            {tip && tip.length > 0 ?
              <Tip title={tip}>
                {customLabel}
              </Tip>
            :
              <>
                {customLabel}
              </>
            }
        </div>
        <div className="arrow-icon-div">
            <ArrowDropUpIcon
              style={{
                color:
                  direction === 'down' && correctKey
                    ? ' blue'
                    : 'black',
              }}
              className="facet-arrow-up"
            />
            <ArrowDropDownIcon
              style={{
                color:
                  direction === 'up' && correctKey
                    ? ' blue'
                    : 'black',
              }}
              className="facet-arrow-down"
            />

        </div>
      </div>
  )
}

/* FACET BLOCK COMPONENT */

// PROPS:
// facets: aggreration buckets from Elasticsearch
// cachedFacets: aggreration buckets from Elasticsearch before filters
// field: name of the facet field
// filters: list of active filters on the field
// onChange: onChange -function to toggle facet
// sorting: initial sorting order (count/alpha), default is 'count'
function FacetBlock({
  facets,
  cachedFacets,
  field,
  filters,
  onChange,
  sorting: initialSorting,
}) {
  const defDirections = { alpha: 'asc', count: 'desc' };
  const topRef = useRef();
  const checkBoxes = useRef([]);
  const [sorting, setSorting] = useState(initialSorting);
  const [sortDirection, setSortDirection] = useState(
    defDirections[initialSorting],
  );
  const [isOpen, setIsOpen] = useState(false);
  const [facetRenderList, setFacetRenderList] =
    useState(false);

  const lang = useSelector((store) => store.lang)

  const [isClosed, setIsClosed] = useState(false)

  // scroll to top of the facet block
  const scrollToTop = () => {
    window.scrollTo({
      top: topRef.current.offsetTop - 20,
      behavior: 'smooth',
    });
  };

  // toggle 'show more/less'
  const toggleOpen = () => {
    setIsOpen(!isOpen);
    scrollToTop();
  };

  const handleSortingChange = (event) => {
    const newType =
      event.currentTarget.getAttribute('sorttype');
    if (sorting !== newType) {
      setSorting(newType);
      setSortDirection(defDirections[newType]);
    } else {
      setSortDirection(
        sortDirection === 'asc' ? 'desc' : 'asc',
      );
    }
  };

  useEffect(() => {
    let facetList = []

    if (facets.length < cachedFacets.length) {
      facetList = cachedFacets.map((item) => {
        const referenceItem = facets.find(
          (subItem) => subItem.key === item.key,
        );

        return {
          ...item,
          displayedDocCount: referenceItem
            ? referenceItem.doc_count
            : 0,
        };
      })
    } else {
      facetList = facets.map((item) => {
        Object.assign(item, { "displayedDocCount": item["doc_count"] });
        return item
      })
    }

    setFacetRenderList(
      isOpen
        ? sortByKey(
          facetList,
          //sorting === 'alpha' ? 'key' : 'displayedDocCount',
          sorting === 'alpha' ? 'key' : 'doc_count',
          sortDirection,
        )
        : sortByKey(
          facetList,
          //sorting === 'alpha' ? 'key' : 'displayedDocCount',
          sorting === 'alpha' ? 'key' : 'doc_count',
          sortDirection,
        )
          .slice(0, 5)
    );
  }, [
    cachedFacets,
    filters,
    facets,
    isOpen,
    sortDirection,
    sorting,
    field
  ]);

  function getReactProps(el) {
    const keys = Object.keys(el);
    const propKey = keys.find((key) =>
      key.includes('__reactEventHandlers'),
    );
    return el[propKey];
  }

  const selectAllButTHis = (checkBoxIndex) => {
    const el = checkBoxes.current[checkBoxIndex];
    const ev = new Event('input', { bubbles: true });
    el.value = `EI ${el.value}`;
    el.dispatchEvent(ev);
    getReactProps(el).onChange(ev);
  };
  const selectFacet = (checkBoxIndex) => {
    const el = checkBoxes.current[checkBoxIndex];
    const ev = new Event('input', { bubbles: true });
    el.value = `${el.value}`;
    el.dispatchEvent(ev);
    getReactProps(el).onChange(ev);
  }
  const handleOpen = () => {
    setIsClosed(false)
  }
  const handleClose = () => {
    setIsClosed(true)
  }
  return (
    <Paper elevation={0} className="paper">
      <Block ref={topRef} className = {isClosed ? "block-closed" : "block"}>
        <div className="facet-header-wrapper">
          <h4 className="h4">
            {isClosed === false ? 
              <button
                type="button"
                onClick={handleClose}
                className='facet-header facet-header--secondary'
              >
                <IndeterminateCheckBoxIcon fontSize="medium" />
              </button>
            :
            <button
                type="button"
                onClick={handleOpen}
                className='facet-header facet-header--secondary'
              >
                <AddBoxIcon fontSize="medium" /> 
              </button>
            }
            &nbsp; 
              <button
                  type="button"
                  className="facet-header facet-header--secondary"
                  sorttype="alpha"
                  onClick={handleSortingChange}
                >
                    <FacetOrderingLabel
                        label={translateKeyword(field, lang)}
                        labelStyling="h4"
                        correctKey={
                          sorting === 'alpha' ? true : false
                        }
                        direction={
                          sortDirection === 'desc' ? 'down' : 'up'
                        }
                  />
              </button>
              <Tip title={translation("facets", "tooltip", field, lang)}>
                <span alt={texts.info.help} className="tooltip-help tippable" ></span>
              </Tip>
          </h4>
          
          {isClosed === false ?
            <div className='pieces-and-not-this'>
              <button
                type="button"
                className="facet-header facet-header--secondary"
                sorttype="count"
                onClick={handleSortingChange}
              >
                <FacetOrderingLabel
                  label={translation("search", "pieces", lang)}
                  correctKey={
                    sorting === 'count' ? true : false
                  }
                  direction={
                    sortDirection === 'desc' ? 'down' : 'up'
                  }
                />
              </button>
            </div>
          :
            <></>
          }
        </div>
        {isClosed === false?
        <>
        <div className="facet-checkboxes">
          {facetRenderList &&
            facetRenderList.map((item, idx) => {
              const label = safe(
                `facet_${field}_${item.key}`,
              );
              return (
                <div
                  className="facet-checkbox--container"
                  key={idx}
                  id={`facets-checkbox--container-${idx}`}
                >
                  <div className="facet-iconbutton">
                    {filters.includes(item.key.toString())?
                      <IconButton
                        onClick={() => (selectFacet(idx))}
                        >
                        <div className = "icon-div">
                          <CheckBoxIcon className="checkedCheckboxIcon" color="primary" fontSize="small" /> 
                        </div>
                        <div className="facet-name">
                          {filters.includes("EI " + item.key.toString()) ?
                            <span className="search-without">{translateKeyword(item.key, lang)}</span>
                            :
                            <span>{translateKeyword(item.key, lang)}</span>
                          }
                        </div>
                      </IconButton>
                    :
                      <IconButton
                          onClick={() => selectFacet(idx)}
                          >
                          <div className = "icon-div">
                            <CheckBoxOutlineBlankIcon className="checkedCheckboxIcon" color="secondary" fontSize="small" /> 
                          </div>
                          <div className="facet-name">
                            {filters.includes("EI " + item.key.toString()) ?
                              <span className="search-without">{translateKeyword(item.key, lang)}</span>
                              :
                              <span>{translateKeyword(item.key, lang)}</span>
                            }
                          </div>
                      </IconButton>
                    }
                  </div>
                  <div
                    className="facet-checkbox"
                    key={item.key}
                  >
                    <input
                      ref={(el) => {
                        checkBoxes.current[idx] = el;
                      }}
                      type="checkbox"
                      id={label}
                      checked={
                        filters.includes(item.key) ||
                        filters.includes(
                          item.key.toString(10),
                        )
                      }
                      group={field}
                      onChange={onChange}
                      key={item.key}
                      value={item.key}
                      name={label}
                    />
                    <label
                      htmlFor={label}
                      className="checkbox-label"
                    > 
                      <div className="facet-count">
                        ({item.doc_count})
                      </div>
                    </label>
                  </div>
                  {
                  // filters.includes(item.key) || 
                  filters.includes("EI " + item.key.toString())?
                    <IconButton
                      onClick={() => selectAllButTHis(idx)}
                      >
                        <CancelIcon className="checkboxIcon"  color="secondary"  fontSize="small"/> 
                     </IconButton>
                     :
                    <IconButton
                      onClick={() => selectAllButTHis(idx)}
                    >
                        <HighlightOffIcon className="checkboxIcon" color="primary"  fontSize="small"/> 
                    </IconButton>
                    
                  }
                </div>
              );
            })}
        </div>

        {cachedFacets.length > 5 && (
          <ShowMore
            isOpen={isOpen}
            toggleFunction={toggleOpen}
            moreLabel={translation("facets", "show_more", lang)}
            lessLabel={translation("facets", "show_less", lang)}
          />
        )}
        </>
        :
          <></>
        }
      </Block>
    </Paper>
  );
}

FacetBlock.propTypes = {
  facets: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  cachedFacets: PropTypes.arrayOf(PropTypes.shape({}))
    .isRequired,
  filters: PropTypes.arrayOf(PropTypes.string),
  field: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  sorting: PropTypes.string,
};

FacetBlock.defaultProps = {
  sorting: 'count',
  filters: null,
};

export default FacetBlock;
