import { SET_DOCGROUP_STATE } from "../actions/actionTypes";

export default (state = 'all', action) => {
    switch (action.type) {
      case SET_DOCGROUP_STATE:
        return action.state;
  
      default:
        return state;
    }
  };