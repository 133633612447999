import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import config from 'config';
import Section from 'components/Section';
import { Paper } from '@material-ui/core';
import { translation } from 'utils/translate';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';

const ForResearcher = () => {

  const lang = useSelector((store) => store.lang)

  // scroll to top when entering page view
  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <>
      <Helmet>
        <title>{translation("tutkijalle", lang)} | {config.site.name}</title>
      </Helmet>
      <Section className="section--page">
        <Paper elevation={0}>
          <div className={isMobile? "page-wrapper-mobile": "page-wrapper"}>
            <h1 className="page-title">
              {translation("tutkijalle", lang)}{' '}
            </h1>
            {translation("researcher", "first_paragraph", lang)}
            {translation("researcher", "second_paragraph", lang)}
            <h2 className="page-subtitle">
              {translation("researcher", "viittaus", lang)}
            </h2>
            {translation("researcher", "third_paragraph", lang)}
            <p>
            </p>{' '}
            <p>
              DOI:{' '}
              <a
                href="https://zenodo.org/record/6504432"
                target="_BLANK"
                rel="noopener noreferrer"
              >
                10.5281/zenodo.6504432
              </a>
            </p>{' '}
          </div>
        </Paper>
      </Section>
    </>
  );
};

export default ForResearcher;
