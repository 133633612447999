import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, makeStyles } from '@material-ui/core';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(() => ({
  tooltip: {
    fontSize: '0.8rem',
    padding: '0.5rem',
    //background: '#444',
    marginBottom: '1rem' 
  },
  tooltip_mobile:{
    fontSize: '0.8rem',
    paddingRight: '0.5rem',
    paddingBottom: '1rem' ,
  
    //background: '#444',
  }
}));

/* Mui Tooltip wrapper component */
const Tip = ({ children, title, ...props }) => {
  const classes = useStyles();
  return !title ? (
    children
  ) : (
      <Tooltip
        className={isMobile?classes.tooltip_mobile:classes.tooltip}
        enterDelay={300}
        enterTouchDelay={10}
        placement="top"
        arrow
        title={title}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        {children}
      </Tooltip>
  );
};

Tip.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node,
};

Tip.defaultProps = {
  title: null,
};

export default Tip;
